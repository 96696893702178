import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import DocView from "../../../components/DocView/DocView";
import { useTranslation } from "react-i18next";
import TabsLayout from "components/TabsLayout/TabsLayout";
import ComplianceTab from "./ComplianceTab/ComplianceTab";
import GeneralTab from "./GeneralTab/GeneralTab";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { COLLECTIONS } from "../../collections";
import { useMetaView } from "app/contexts/meta-view-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { useExternalRecipients } from "../../contexts/external-recipient-context";
import DataLocationOverview from "../datalocations/DataLocationOverview";
import ExternalRecipientProcessOverview from "./overview/processes/ExternalRecipientProcessOverview";
import ExternalRecipientAssetsOverview from "./overview/assets/ExternalRecipientAssetsOverview";
import { useResources } from "../../contexts/resource-context";

import { postCubeJsEvent } from "../../api/cubeJsHelperApi";
import { FEATURES } from "../../features";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";

export default function ExternalRecipientHelper({ renderOnlyDetailsView = false, documentId }) {
  return <ExternalRecipient renderOnlyDetailsView={renderOnlyDetailsView} documentId={documentId} />;
}

ExternalRecipientHelper.propTypes = {
  documentId: PropTypes.string.isRequired,

  renderOnlyDetailsView: PropTypes.bool
};

ExternalRecipientHelper.defaultProps = {
  renderOnlyDetailsView: false
};

function ExternalRecipient({ renderOnlyDetailsView, documentId }) {
  const { t } = useTranslation("service_providers_overview");
  const { page: pageParam } = useParams();

  const { setInfo, setMeta } = useMetaView();
  const navigate = useNavigate();
  const { loading: authLoading } = useAuthentication();
  const isShowRecentActivities = useIsFeaturePresent(FEATURES.DASHBOARD_RECENT_ACTIVITIES);
  const isERAssetFeaturePresent = useIsFeaturePresent(FEATURES.ASSET_ERS);
  const { refreshResources } = useResources();

  const [answers, setAnswers] = useState({});
  const [selectedTab, setSelectedTab] = useState();
  // can be deleted when default values are created with creation of Service Provider
  const [answersLoaded, setAnswersLoaded] = useState(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [lastAnsweredField, setLastAnsweredField] = useState({});

  const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

  const updateAnswers = useCallback(
    field => {
      setLastAnsweredField(field);
      setAnswers(currentAnswers => ({ ...currentAnswers, ...field }));
    },
    [setAnswers]
  );

  const { initialized, loadExternalRecipientHook, externalRecipient } = useExternalRecipients();
  // state to prevent multiple activity entries when switching between pages
  const [pageAccessPublished, setPageAccessPublished] = useState(false);
  const loadServiceProviderData = useCallback(
    async function () {
      if (!documentId) {
        return;
      }
      await loadExternalRecipientHook(documentId).catch(error => {
        setDocumentNotFound(true);
        throw error;
      });
      setAnswersLoaded(true);
    },
    [documentId, loadExternalRecipientHook]
  );

  useEffect(() => {
    if (!authLoading && initialized) {
      loadServiceProviderData();
      refreshResources();
    }
  }, [authLoading, initialized, loadServiceProviderData, refreshResources]);

  useEffect(() => {
    if (initialized && answersLoaded) {
      setAnswers({ ...externalRecipient, ...lastAnsweredField });
    }
  }, [answersLoaded, externalRecipient, initialized, lastAnsweredField]);

  useEffect(() => {
    // only publish once on first access
    const publishActivity = async function (activityType) {
      if (!isShowRecentActivities) {
        return;
      }
      const payload = {
        url: "/service-providers/" + documentId,
        docType: "serviceProvider",
        docTitle: answers.name,
        docId: documentId,
        activityType: activityType,
        tableId: "activities"
      };
      await postCubeJsEvent(payload);
    };
    if (answers.name && pageAccessPublished === false) {
      publishActivity("pageAccess");
      setPageAccessPublished(true);
    }
  }, [answers.name, documentId, isShowRecentActivities, pageAccessPublished]);

  const tabContents = useMemo(() => {
    if (!answersLoaded) return [];
    return [
      {
        tabId: "general",
        number: "1",
        title: t("overview_tab_title"),
        content: (
          <div>
            <GeneralTab
              answers={answers}
              updateAnswers={updateAnswers}
              answersLoaded={answersLoaded}
              documentId={documentId}
              disableComment={renderOnlyDetailsView}
            />
          </div>
        )
      },
      {
        tabId: "products",
        number: "2",
        title: t("product_tab_title"),
        content: <DataLocationOverview externalRecipientId={documentId} hideTitle />
      },
      {
        tabId: "compliance",
        number: "3",
        title: t("compliance_tab_title"),
        content: (
          <ComplianceTab
            setMeta={setMeta}
            setInfo={setInfo}
            docId={documentId}
            disableComment={renderOnlyDetailsView}
            answers={answers}
            updateAnswers={updateAnswers}
          />
        )
      },
      // hide process tab if renderOnlyDetailsView is true
      !renderOnlyDetailsView && {
        tabId: "processes",
        title: t("processes_tab_title"),
        content: <ExternalRecipientProcessOverview />
      },
      isERAssetFeaturePresent && {
        tabId: "assets",
        number: "4",
        title: t("assets_tab_title"),
        content: <ExternalRecipientAssetsOverview />
      }
    ].filter(Boolean);
  }, [
    answersLoaded,
    t,
    answers,
    updateAnswers,
    documentId,
    renderOnlyDetailsView,
    setMeta,
    setInfo,
    isERAssetFeaturePresent
  ]);

  /* useEffect */
  useEffect(() => {
    if (!pageParam) {
      setSelectedTab(0);
    } else {
      const field = isNaN(pageParam) ? "tabId" : "number";
      setSelectedTab(tabContents.map(t => t[field]).indexOf(pageParam));
    }
  }, [pageParam, tabContents]);

  const onTabChange = useCallback(
    tab => {
      const tabName = tabContents[tab].tabId || "general";
      navigate(`/external-recipients/${tabName}/${documentId}`);
    },
    [documentId, navigate, tabContents]
  );

  // content for doc view
  const docViewContent = answersLoaded === true && (
    <DocView header={answers.name}>
      <TabsLayout tabContents={tabContents} selectedTab={selectedTab} setSelectedTab={onTabChange} />
    </DocView>
  );

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.EXTERNAL_RECIPIENTS} />;
  }

  // if renderOnlyDetailsView is true, then only display the tabs and its individual contents else display the stand docview
  if (renderOnlyDetailsView) {
    return <TabsLayout tabContents={tabContents} />;
  }

  return (
    <DocMetaView
      docViewContent={docViewContent}
      metaViewContent={
        <MetaView
          tabs={metaViewTabIds}
          docName={answers.name}
          pageId={pageParam}
          docId={documentId}
          collection={COLLECTIONS.EXTERNAL_RECIPIENTS}
        />
      }
    />
  );
}
