import { useUserDepartments } from "app/contexts/department-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../Question/Question";
import OrgUnitPickerModal from "../../app/pages/shared/OrgUnitPickerModal/OrgUnitPickerModal";
import { Chip, TextField } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/system/styleFunctionSx";

export function OrgUnitsTreePicker({ value, onChange, questionName, disabled, required }: QuestionProps) {
  const { t } = useTranslation();
  const { getDepartmentWithParentName, departments, authUserSelectableDepartmentIds } = useUserDepartments();
  const allDepartmentIdsSet = useMemo(() => new Set(departments.map(it => it.id)), [departments]);
  const normalizedValue = useMemo(() => {
    return (Array.isArray(value) ? value : typeof value === "string" ? [value] : []).filter(it =>
      allDepartmentIdsSet.has(it)
    );
  }, [allDepartmentIdsSet, value]);
  const normalizedValueSet = useMemo(() => new Set(normalizedValue), [normalizedValue]);
  const allValuesSelected = useMemo(() => {
    if (normalizedValueSet.has("*")) {
      return true;
    }
    if (normalizedValueSet.size >= allDepartmentIdsSet.size) {
      if ([...allDepartmentIdsSet].every(it => normalizedValueSet.has(it))) {
        return true;
      }
    }
    return false;
  }, [allDepartmentIdsSet, normalizedValueSet]);

  const [orgPickerOpen, setOrgPickerOpen] = useState(false);
  const closeOrgUnitPicker = useCallback(() => {
    setOrgPickerOpen(false);
  }, []);
  const openOrgUnitPicker = useCallback(() => {
    if (disabled) {
      return;
    }
    setOrgPickerOpen(true);
  }, [disabled]);

  useEffect(() => {
    // on unmount, then close picker
    return () => setOrgPickerOpen(false);
  }, []);

  const onOrgUnitPickerModalChanged = useCallback(
    (selectedDepartmentIds: string[]) => {
      onChange?.(selectedDepartmentIds);
    },
    [onChange]
  );

  const onTextFieldFocus = useCallback<React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>(event => {
    event.preventDefault();
    event.currentTarget.blur();
  }, []);

  const inputProps = useMemo<Partial<StandardInputProps>>(() => {
    if (normalizedValue.length === 0) {
      return {};
    }
    return {
      sx: {
        flexWrap: "wrap",
        padding: "9px 12px",
        "& .MuiInputBase-inputMultiline": {
          display: "none"
        },
        minHeight: 56
      },
      startAdornment: (
        <>
          {allValuesSelected ? (
            <OrgUnitChip
              key="all"
              label={t("common:allDepartmentsSelected", {
                count: departments.length
              })}
              disabled={!!disabled}
            />
          ) : (
            <>
              {normalizedValue.slice(0, limitTags).map(it => (
                <OrgUnitChip key={it} label={getDepartmentWithParentName(it)} disabled={!!disabled} />
              ))}
              {normalizedValue.length > limitTags ? (
                <span style={disabled ? { opacity: 0.38 } : undefined}>
                  {t("common:more", {
                    count: normalizedValue.length - limitTags
                  })}
                </span>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )
    } satisfies Partial<StandardInputProps>;
  }, [allValuesSelected, t, departments.length, disabled, normalizedValue, getDepartmentWithParentName]);

  return (
    <>
      <TextField
        label={questionName}
        fullWidth={true}
        onClick={openOrgUnitPicker}
        InputProps={inputProps}
        onFocus={onTextFieldFocus}
        multiline={true}
        disabled={disabled}
      />
      <OrgUnitPickerModal
        open={orgPickerOpen}
        selectedDepartmentIds={normalizedValue}
        allowedOrgUnitIds={authUserSelectableDepartmentIds}
        onClose={closeOrgUnitPicker}
        onConfirm={onOrgUnitPickerModalChanged}
        required={!!required}
      />
    </>
  );
}

const OrgUnitChip = ({ label, disabled }: { label: string; readonly disabled: boolean }) => {
  return <Chip color="primary" label={label} sx={disabled ? orgUnitChipStyleDisabled : orgUnitChipStyle} />;
};

const orgUnitChipStyle: SxProps = { margin: "3px" };
const orgUnitChipStyleDisabled: SxProps = { ...orgUnitChipStyle, opacity: 0.5 };

const limitTags = 6;

export default OrgUnitsTreePicker;
