import useSWR from "swr";
import { useCallback } from "react";
import { apiEndpoints } from "../apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";
import { DefaultApi, SetAiRiskLevelRequest } from "../generated/process-service";
import { AiRiskLevel } from "app/pages/questionnaires/assessment-page/AiRiskAssessment";
import { useUpdateQueues } from "../../../hook/useUpdateQueues";
import { useTranslation } from "react-i18next";

const processClient = new DefaultApi(undefined, apiEndpoints.paUrl, defaultOTCAuthenticatedAxios());

export const useAiPrecheck = (args: { documentId: string }) => {
  const { i18n } = useTranslation();
  const { mutate, data } = useSWR(
    args.documentId ? [`ai_precheck_${args.documentId}`, i18n.language] : null,
    async () => {
      return processClient
        .getAiPrecheckStatus(args.documentId, {
          headers: {
            "Accept-Language": i18n?.language || "en"
          }
        })
        .then(resp => resp.data);
    }
  );

  const generalUpdateProcessor = useCallback(
    async (payload: SetAiRiskLevelRequest) => {
      await mutate(async data => {
        await processClient.setAiRiskLevel(args.documentId, payload);
        return data;
      });
    },
    [args.documentId, mutate]
  );

  const { queueUpdates } = useUpdateQueues<SetAiRiskLevelRequest, SetAiRiskLevelRequest>({
    triggerUpdate: generalUpdateProcessor
  });

  const updateAiRiskLevel = useCallback(
    async (processID: string, aiRiskLevel: AiRiskLevel) => {
      await mutate(async data => {
        await processClient.setAiRiskLevel(args.documentId, { aiRiskLevel });
        return data;
      });
    },
    [args.documentId, mutate]
  );

  return {
    aiCheckRequired: !!data?.processPage?.aiCheckRequired,
    aiRiskLevel: data?.processPage?.aiRiskLevel || null,
    aiRiskLevelExplanation: data?.processPage?.aiRiskLevelExplanation || null,
    isAiRiskLevelOverriddenByUser: !!data?.processPage?.isAiRiskLevelOverriddenByUser,
    isAiRiskLevelExplanationOverriddenByUser: !!data?.processPage?.isAiRiskLevelExplanationOverriddenByUser,
    actions: {
      updateAiRiskLevel,
      queueAiRiskLevelUpdate: queueUpdates,
      mutate
    }
  };
};
