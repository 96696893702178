import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { AutocompleteProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  FilterOptionsState,
  Value
} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { Box, Chip, IconButton, LinearProgress, makeStyles, Tooltip } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { naturalSortBy } from "../../app/utils/naturalSort";
import { ArrowDropDown } from "@material-ui/icons";
import colors from "theme/palette/colors";

const useStyles = makeStyles(theme => ({
  root: {},
  "@global": {
    ".multi-autocomplete": {
      "& .MuiAutocomplete-tag": {
        backgroundColor: `${theme.palette.blue[100]} !important`,
        "& .MuiChip-deleteIcon": {
          color: theme.palette.blue[300],
          "&:hover": {
            color: colors.blue.blue400
          }
        }
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "9px 12px !important",
        "& .MuiAutocomplete-input": {
          padding: "11px 4px !important"
        }
      }
    },
    ".custom-icon": {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1
    }
  }
}));

export interface MultiAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Pick<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    | "disabled"
    | "renderTags"
    | "renderOption"
    | "freeSolo"
    | "groupBy"
    | "options"
    | "onInputChange"
    | "inputValue"
    | "size"
    | "fullWidth"
    | "getOptionLabel"
    | "getOptionSelected"
    | "disableClearable"
    | "forcePopupIcon"
    | "onFocus"
    | "onBlur"
  > {
  readonly hasMultiSelect?: Multiple;
  readonly isOptionDisabled?: (option: T) => boolean;
  readonly selected?: Value<T, Multiple, DisableClearable, FreeSolo>;
  readonly updateSelected?: (value: Value<T, Multiple, DisableClearable, FreeSolo>) => void;
  readonly updateOptions?: (options: T[]) => void;
  readonly addText?: string;
  readonly placeholder?: string;
  readonly onChange?: (value: Value<T, Multiple, DisableClearable, FreeSolo>) => void;
  readonly label?: string;
  readonly getStyle?: string;
  readonly icon?: React.ReactNode;
  readonly id?: string;
  readonly newOptionEntryAlreadyExists?: (input: string) => boolean;
  readonly disableDefaultOptionsSorting?: boolean;
  readonly error?: boolean;
  readonly helperText?: string;
  readonly isLoading?: boolean;
  readonly limitTags?: number;
  readonly onScrollEndReached?: () => void;
  readonly tooltipText?: string;
}

const FILTER_LIMIT = 500;

// eslint-disable-next-line react/display-name
const ListboxComponent = React.forwardRef<HTMLUListElement, { isLoading?: boolean; onScrollEndReached?: () => void }>(
  (props, ref) => {
    const { children, isLoading, onScrollEndReached, ...other } = props;
    const onScroll = useCallback(
      (e: React.UIEvent<HTMLUListElement>) => {
        const bottom = e.currentTarget.clientHeight >= e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
        if (bottom) {
          onScrollEndReached?.();
        }
      },
      [onScrollEndReached]
    );
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ul ref={ref} {...other} style={listBoxUlStyle} onScroll={onScroll}>
        {children}
        {isLoading ? (
          <li style={listBoxIlStyle}>
            <LinearProgress />
          </li>
        ) : null}
      </ul>
    );
  }
);
const listBoxUlStyle: CSSProperties = { padding: 0, margin: 0, listStyle: "none" };
const listBoxIlStyle: CSSProperties = { padding: 0, margin: 0, listStyle: "none" };

/**
 * MultiAutocomplete component, which is a wrapper around the Material-UI Autocomplete component.
 * It provides additional functionality like adding new options, sorting options, and more.
 * @param addText string added to "new item recommendation", example: const { t } = useTranslation("autocomplete"); addText={t("addText")}
 * @param disableClearable Whether the clear button is disabled.
 * @param disableDefaultOptionsSorting Whether the default options sorting is disabled.
 * @param disabled Whether the component is disabled.
 * @param error Whether the component has an error.
 * @param freeSolo Whether the component is free solo.
 * @param fullWidth Whether the component is full width.
 * @param getOptionLabel The function to get the option label.
 * @param getOptionSelected The function to get the selected option.
 * @param groupBy The function to group the options.
 * @param hasMultiSelect Whether the component has multi select.
 * @param helperText The helper text.
 * @param icon The icon to display.
 * @param id The id of the component.
 * @param inputValue The input value.
 * @param isLoading Whether the component is loading.
 * @param isOptionDisabled The function to check if the option is disabled.
 * @param label The label of the component.
 * @param limitTags The limit of tags.
 * @param newOptionEntryAlreadyExists The function to check if the new option already exists.
 * @param onBlur The function to call when the component is unfocused.
 * @param options options
 * @param placeholder The placeholder of the component.
 * @param renderOption The function to render the option.
 * @param renderTags The function to render the tags.
 * @param selected The selected value.
 * @param size The size of the component.
 * @param updateOptions The function to update the options.
 * @param updateSelected The function to update the selected options.
 * @param onChange The function to call when the value changes.
 * @param onFocus The function to call when the component is focused.
 * @param onInputChange The function to call when the input value changes.
 * @param onScrollEndReached The function to call when the scroll end is reached.
 * @returns The MultiAutocomplete component.
 */
export const MultiAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  addText, // string added to "new item recommendation", example: const { t } = useTranslation("autocomplete"); addText={t("addText")}
  disableClearable,
  disableDefaultOptionsSorting,
  disabled,
  error,
  freeSolo,
  fullWidth,
  getOptionLabel,
  getOptionSelected,
  groupBy,
  hasMultiSelect,
  helperText,
  icon,
  id,
  inputValue,
  isLoading,
  isOptionDisabled,
  label,
  limitTags,
  newOptionEntryAlreadyExists,
  options,
  placeholder,
  renderOption,
  renderTags,
  selected,
  size,
  updateOptions, // function to update Options in parent
  updateSelected, // function to update selected Options in parent
  onBlur,
  onChange,
  onFocus,
  onInputChange,
  onScrollEndReached,
  tooltipText
}: MultiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  useStyles();
  const { t } = useTranslation();
  const blurOnSelect = !hasMultiSelect;

  const removeAddText = useCallback(
    input => {
      if (!(typeof input === "string" || input instanceof String) || !input) {
        return input;
      }
      if (!addText) {
        return input;
      }
      if (input.startsWith(addText)) {
        return input.replace(addText, "").trim();
      }

      return input;
    },
    [addText]
  );

  const applyChanges = useCallback(
    (newValue, changedOption) => {
      const isChangedOptionDisabled = () => {
        if (!isOptionDisabled) {
          return false;
        }
        if (!changedOption) {
          return false;
        }
        return isOptionDisabled(changedOption);
      };

      if (isChangedOptionDisabled()) {
        return;
      }

      const cleanedValue = Array.isArray(newValue) ? newValue.map(removeAddText) : removeAddText(newValue);
      updateSelected?.(cleanedValue);
      onChange?.(cleanedValue);
    },
    [isOptionDisabled, updateSelected, onChange, removeAddText]
  );

  const autoCompleteOnChange = useCallback(
    (
      event: React.ChangeEvent<any>,
      newValue: Value<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T>
    ): void => {
      const applyChangesWithParams = () => {
        return applyChanges(newValue, details?.option);
      };
      // what happens when all selected are cleared to prevent error behavior
      if (hasMultiSelect && Array.isArray(newValue) && newValue[0] === undefined) {
        applyChangesWithParams();
        return;
      }
      if (!hasMultiSelect && newValue === null) {
        return;
      }

      // if it has multi select, and a user deselects, do not do anything, just update selected
      if (hasMultiSelect && reason === "remove-option") {
        applyChangesWithParams();
        return;
      }

      // new Option is added via add Button
      const rawNewOption = hasMultiSelect && Array.isArray(newValue) ? newValue[newValue.length - 1] : newValue;

      // subtracts the add/hinzufügen strings from new created option
      const newOption = removeAddText(rawNewOption);

      // makes sure option doesn't already exist
      const newOptionAlreadyExists = options.some(option => option === newOption);
      if (newOptionAlreadyExists || typeof updateOptions !== "function") {
        // if new value already exists (selected via filter/search) it's going to be selected
        applyChangesWithParams();
        return;
      }

      updateOptions([...options, newOption] as unknown as T[]);
      if (!hasMultiSelect) {
        // if it has multi select, we do not want to auto select
        applyChangesWithParams();
      }
    },
    [hasMultiSelect, options, updateOptions, applyChanges, removeAddText]
  );

  const renderOptionCallback = useCallback(
    (option, { selected }) => {
      let label = getOptionLabel?.(option);
      if (typeof label !== "string" && typeof option === "string") {
        label = option;
      }
      return (
        <>
          {hasMultiSelect && (
            <Checkbox
              data-qa="multi-auto-complete-check-box"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={selected}
              disabled={isOptionDisabled ? isOptionDisabled(option) : false}
              color={"primary"}
            />
          )}
          <Typography>{label}</Typography>
        </>
      );
    },
    [hasMultiSelect, getOptionLabel, isOptionDisabled]
  );

  const clearCallback = useCallback(() => {
    applyChanges([], true);
  }, [applyChanges]);
  const renderInput = useCallback(
    params => {
      const inputProps = icon
        ? {
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" className="custom-icon">
                <Box display="flex" pr={1.75}>
                  {disableClearable === false && (
                    <IconButton aria-label="delete" onClick={clearCallback}>
                      <Close />
                    </IconButton>
                  )}
                  <IconButton style={{ width: "auto" }}>{icon}</IconButton>
                </Box>
              </InputAdornment>
            )
          }
        : params.InputProps;
      return (
        <TextField
          {...params}
          fullWidth={true}
          InputProps={inputProps}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      );
    },
    [clearCallback, disableClearable, error, helperText, icon, label, placeholder]
  );

  const [initialFilterOptions] = useState<(options: T[], state: FilterOptionsState<T>) => T[]>(() =>
    createFilterOptions<T>({ limit: FILTER_LIMIT })
  );
  const filterOptions = useCallback(
    (options: T[], params: FilterOptionsState<T>): T[] => {
      const paramsWithTrimmedInputValue = { ...params, inputValue: params.inputValue.trim() };
      const filtered = initialFilterOptions(options, paramsWithTrimmedInputValue);

      // suggests the creation of a new value
      if (
        paramsWithTrimmedInputValue.inputValue !== "" &&
        addText &&
        !newOptionEntryAlreadyExists?.(paramsWithTrimmedInputValue.inputValue)
      ) {
        // this is a potential bug, as T is not always string, but we always push a string for the add text
        // well, but it will work ok in javascript, just all caller need to be aware of a rogue option...
        filtered.push(`${addText} ${paramsWithTrimmedInputValue.inputValue}` as unknown as T);
      }
      return filtered;
    },
    [newOptionEntryAlreadyExists, addText, initialFilterOptions]
  );

  const [sortedOptions, setSortedOptions] = useState<T[]>([]);

  useEffect(() => {
    if (disableDefaultOptionsSorting) {
      setSortedOptions(options);
      return;
    }

    const sortedOptions = naturalSortBy(options, [
      it => (groupBy ? groupBy(it) : ""),
      it => (getOptionLabel ? getOptionLabel(it) : String(it))
    ]);

    setSortedOptions(sortedOptions);
  }, [disableDefaultOptionsSorting, getOptionLabel, groupBy, options]);

  const renderLimitTagsText = React.useCallback(
    (more: number) => (
      <>
        {"... "}
        <Chip
          color="primary"
          style={{
            cursor: "pointer"
          }}
          label={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "space-between",
                marginRight: -5
              }}
            >
              <span>
                {t("common:more", {
                  count: more
                })}
              </span>
              <ArrowDropDown />
            </div>
          }
        />
      </>
    ),
    [t]
  );

  const listBoxProps = useMemo(
    () => ({
      isLoading,
      onScrollEndReached
    }),
    [isLoading, onScrollEndReached]
  );

  return (
    <Tooltip title={tooltipText || ""}>
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
        id={id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ListboxComponent={ListboxComponent}
        ListboxProps={listBoxProps}
        limitTags={limitTags}
        getLimitTagsText={renderLimitTagsText}
        className={"multi-autocomplete"}
        data-testid="mui-autocomplete"
        freeSolo={freeSolo}
        disabled={disabled}
        groupBy={groupBy}
        multiple={hasMultiSelect}
        fullWidth={fullWidth}
        size={size}
        value={selected}
        inputValue={inputValue}
        onInputChange={onInputChange}
        options={sortedOptions}
        getOptionDisabled={isOptionDisabled}
        noOptionsText={t("dpia_four_four_page:no_options")}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        disableCloseOnSelect={true}
        disableClearable={disableClearable}
        blurOnSelect={blurOnSelect}
        filterOptions={filterOptions}
        renderTags={renderTags}
        renderOption={renderOption || renderOptionCallback}
        renderInput={renderInput}
        onChange={autoCompleteOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        openText={t("common:open")}
        closeText={t("common:close")}
      />
    </Tooltip>
  );
};

MultiAutocomplete.defaultProps = {
  disableClearable: true,
  disabled: false,
  fullWidth: false,
  disableDefaultOptionsSorting: false,
  newOptionEntryAlreadyExists: () => false
};

export default MultiAutocomplete;
