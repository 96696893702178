import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
export type TaskStatus = "OPEN" | "TODO" | "DONE" | "DELETED";
export type RecurrenceType = "DAILY" | "WEEKLY" | "MONTHLY" | "QUARTERLY" | "HALFYEARLY" | "YEARLY" | "BIYEARLY";
export type DayOfWeek = "SUNDAY" | "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY";

export type detailType = "DEFAULT" | "RECURRING" | "GROUP";

export type TaskDTO = Omit<Task, "deleted">;

export interface TasksDTO {
  readonly tasks: TaskDTO[];
}
export interface Task {
  readonly id: string;
  readonly createdAt: Date;
  readonly tenantId: string;
  readonly title: string | null;
  readonly description: string | null;
  readonly documentId: string | null;
  readonly updatedAt: Date;
  readonly dueAt: Date | null;
  readonly creatorUID: string;
  readonly assigneeUID: string | null;
  readonly status: TaskStatus;
  readonly commentId: string | null;
  readonly pageId: string | null;
  readonly priority: string | null;
  readonly collection: string | null;
  readonly questionId: string | null;
  readonly type: string | null;
  readonly message: string | null;
  readonly participants: string[];
  readonly labels: string[];
  readonly notes: string | null;
  readonly section: string | null;
  readonly seen: boolean | null;
  readonly groupIds: string[];
  readonly recurringTaskId?: string | null;
  readonly recurringTaskReference?: string;
  readonly assignedToType?: string | null;
}

export interface NewTask {
  readonly id?: string;
  readonly title: string;
  readonly status: string | null;
  readonly type?: string;
  readonly description?: string | null;
  readonly documentId?: string | null;
  readonly dueAt?: string | null;
  readonly updatedAt?: string | null;
  readonly assigneeUID?: string | null;
  readonly commentId?: string | null;
  readonly pageId?: string | null;
  readonly priority?: string | null;
  readonly collection?: string | null;
  readonly questionId?: string | null;
  readonly message?: string | null;
  readonly notes?: string | null;
  readonly participants?: string[];
  readonly labels?: string[];
  readonly section?: string | null;
  readonly seen?: boolean | null;
  readonly recurringTaskId?: string;
  readonly recurringTaskReference?: string;
  readonly groupIds?: string[];
  readonly assignedToType?: string | null;
}

export interface UpdateTask {
  readonly title?: string | null;
  readonly status?: string | null;
  readonly description?: string | null;
  readonly documentId?: string | null;
  readonly dueAt?: string | null;
  readonly updatedAt?: string | null;
  readonly assigneeUID?: string | null;
  readonly commentId?: string | null;
  readonly pageId?: string | null;
  readonly priority?: string | null;
  readonly collection?: string | null;
  readonly questionId?: string | null;
  readonly message?: string | null;
  readonly notes?: string | null;
  readonly participants?: string[];
  readonly labels?: string[];
  readonly type?: string | null;
  readonly section?: string | null;
  readonly seen?: boolean | null;
  readonly groupIds?: string[];
  readonly assignedToType?: string | null;
}

export interface TaskDetailsDTO
  extends Omit<Task, "tenantId" | "deleted" | "notes" | "section" | "recurringTaskReference"> {
  readonly recurrenceType?: RecurrenceType;
}

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.taskUrl}/api/v1/tasks`
});

export async function getTasksApi(param: { collectionId: string }) {
  const response = await axiosInstance.get("/", {
    params: { param }
  });
  return response.data.tasks;
}

export async function getActiveTasksOfUserApi({ includeDone } = { includeDone: false }) {
  const response = await axiosInstance.get("/activeTasks", {
    params: { includeDone }
  });
  return response.data.tasks;
}

export async function getNumberUnseenTasksApi(
  httpOptions: {
    abortController?: AbortController;
  } = {}
) {
  const response = await axiosInstance.get("/stats", { signal: httpOptions.abortController?.signal });
  return response.data.unseenCount;
}

export async function getNumberOfTasksPerCollectionApi(collectionId: string) {
  const response = await axiosInstance.get("/stats/collections/" + collectionId);
  return response.data;
}

export async function getDocumentTasksApi(documentId: string, documentIdPrefix?: string) {
  const response = await axiosInstance.get("/documents/" + documentId, {
    params: { documentIdPrefix }
  });
  return response.data.tasks;
}

export async function createTaskApi({
  id,
  title,
  description,
  documentId,
  dueAt,
  assigneeUID,
  commentId,
  pageId,
  priority,
  collection,
  questionId,
  type,
  message,
  participants,
  groupIds,
  labels,
  notes,
  section,
  seen,
  assignedToType
}: NewTask) {
  const response = await axiosInstance.post(`/`, {
    id,
    title,
    description,
    documentId,
    dueAt,
    assigneeUID,
    commentId,
    pageId,
    priority,
    collection,
    questionId,
    type,
    message,
    participants,
    groupIds,
    labels,
    notes,
    section,
    seen,
    assignedToType
  });
  return response.headers["x-resource-id"] || "";
}

export async function updateTaskApi(
  id: string,
  {
    title,
    description,
    documentId,
    updatedAt,
    dueAt,
    assigneeUID,
    commentId,
    pageId,
    priority,
    collection,
    questionId,
    type,
    message,
    participants,
    groupIds,
    labels,
    notes,
    section,
    seen,
    status,
    assignedToType
  }: UpdateTask = {}
) {
  const response = await axiosInstance.patch(`/` + id, {
    title,
    description,
    documentId,
    updatedAt,
    dueAt,
    assigneeUID,
    commentId,
    pageId,
    priority,
    collection,
    questionId,
    type,
    message,
    participants,
    groupIds,
    labels,
    notes,
    section,
    seen,
    status,
    assignedToType
  });
  return response.data;
}

export async function getTaskByIdApi(id: string) {
  const response = await axiosInstance.get("/" + id);
  return response ? response.data : null;
}

export async function deleteSingleTaskApi(id: string) {
  return axiosInstance.delete("/" + id);
}
