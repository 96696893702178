import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMetaView } from "app/contexts/meta-view-context";
import Overview from "components/Overview/Overview";
import DocMetaView from "components/DocMetaView/DocMetaView";
import MetaView from "components/MetaView/MetaView";
import ClassIcon from "@material-ui/icons/Class";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { OVERVIEW_ACTIONS, useOverviewDispatch, useOverviewState } from "app/contexts/overview-context";
import { Box, SvgIcon } from "@material-ui/core";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useResources } from "app/contexts/resource-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { resourcesWritePermissions } from "app/handlers/permissionHandler";
import { OverviewRowActionProps } from "components/Overview/controls/OverviewRowAction";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import {
  PersonGroupsMergeDataProps,
  PersonGroupsOverviewMergeModal
} from "../../modals/person-groups/PersonGroupsOverviewMergeModal";
import PersonGroupsClassificationModal, {
  ClassificationDataProps
} from "../../modals/person-groups/PersonGroupsOverviewClassificationModal";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { FEATURES } from "app/features";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { DataAssetType } from "app/api/generated/asset-service";
import DataCategotyOverviewMoveModal, {
  DataCategotyMoveModalDataProps
} from "../../modals/person-groups/DataCategotyOverviewMoveModal";
import { dataAssetClient, useDataTypeTreeManager } from "app/api/dataAssetApi";
import ProcessesInMetaView from "../../metaview/ProcessesInMetaView";
import DataTypeOverviewMoveModal, {
  DataTypeMoveModalMoveModalDataProps
} from "../../modals/person-groups/DataTypeOverviewMoveModal";
import DriveFileMoveIconDarkBlue from "assets/images/icons/driveFileMoveDarkBlue.svg";
import DriveFileMoveIconBlue from "assets/images/icons/driveFileMoveBlue.svg";
import DriveFileMoveIcon from "assets/images/icons/driveFileMove.svg";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Add, Edit } from "@mui/icons-material";
import MultilingualEditorModal, { NEW_TRANSLATION_KEY } from "components/Multilingual/MultilingualModal";

const MoveIcon = ({ disabled }: { readonly disabled?: boolean }) => {
  const [overOn, setHoverOn] = useState<boolean>(false);
  const onHoverOnCallback = useCallback(() => {
    setHoverOn(true);
  }, []);
  const onHoverOutCallback = useCallback(() => {
    setHoverOn(false);
  }, []);
  return (
    <SvgIcon onMouseOver={onHoverOnCallback} onMouseOut={onHoverOutCallback} opacity={disabled ? 0.5 : 1}>
      {overOn && <DriveFileMoveIconBlue />}
      {!overOn && <DriveFileMoveIcon />}
    </SvgIcon>
  );
};

const ToolbarMoveIcon = ({ disabled }: { readonly disabled?: boolean }) => {
  const [overOn, setHoverOn] = useState<boolean>(false);
  const onHoverOnCallback = useCallback(() => {
    setHoverOn(true);
  }, []);
  const onHoverOutCallback = useCallback(() => {
    setHoverOn(false);
  }, []);
  return (
    <Box style={{ opacity: disabled ? 0.5 : 1 }}>
      <SvgIcon onMouseOver={onHoverOnCallback} onMouseOut={onHoverOutCallback}>
        {!disabled && !overOn && <DriveFileMoveIconBlue />}
        {!disabled && overOn && <DriveFileMoveIconDarkBlue />}
        {disabled && <DriveFileMoveIcon />}
      </SvgIcon>
    </Box>
  );
};

const PersonGroupsOverview = () => {
  const resourceType = RESOURCE_TYPES.DATA_ASSETS;
  const { t } = useTranslation("resources_lists_data_types_categories_person_groups");
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();
  const { selectedId } = useOverviewState()[COLLECTIONS.PERSON_GROUPS];
  const { resourcesLoaded } = useResources();
  const { auth } = useAuthentication();
  const isDataClassificationEnabled = useIsFeaturePresent(FEATURES.DATA_CLASSIFICATION);
  const isMultilingualEnabled = !!useIsFeaturePresent(FEATURES.MULTILINGUAL);
  const { actions, mutate } = useDataTypeTreeManager(true);

  const [disableMergeAction, setDisableMergeAction] = useState<boolean>(true);
  const [disableMoveAction, setDisableMoveAction] = useState<boolean>(true);

  const reload = useCallback(
    (input?: { readonly selectedId?: string; readonly checkedIds?: string[] }) => {
      const reload = {
        shadowLoading: true,
        selectedId: input?.selectedId || undefined,
        checkedIds: input?.checkedIds || undefined,
        reloadOverview: Date.now(),
        reloadMetaview: Date.now()
      };
      dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.PERSON_GROUPS, reload });
    },
    [dispatch]
  );

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t(`enteringInfoCardTitle`),
        text: t(`enteringInfoCardText`)
      },
      creating: {
        title: t(`creating_info_card_title`),
        text: t(`creating_info_card_text`)
      },
      merging: {
        title: t(`mergingInfoCardTitle`),
        text: t(`mergingInfoCardText`)
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const showEnterInfo = useCallback(() => {
    setInfo(infoCard?.entering);
  }, [infoCard, setInfo]);

  const [rename, setRename] = useState<{
    translationKey?: string;
    parentId?: string;
    assetType?: DataAssetType;
  }>({});
  const handleAddWithTranslations = useCallback((parentId?: string, row?: { assetType: string }) => {
    if (row?.assetType === DataAssetType.DataType) {
      return;
    }
    setRename({
      translationKey: NEW_TRANSLATION_KEY,
      parentId,
      assetType:
        row?.assetType === DataAssetType.PersonGroup
          ? DataAssetType.DataCategory
          : row?.assetType === DataAssetType.DataCategory
            ? DataAssetType.DataType
            : DataAssetType.PersonGroup
    });
  }, []);
  const handleStartRename = useCallback((translationKey: string, row?: { assetType: string }) => {
    setRename({ translationKey, assetType: row?.assetType as DataAssetType });
  }, []);
  const completeRename = useCallback(() => {
    setRename({});
    reload();
    mutate();
  }, [reload, mutate]);
  const handleAdd = useCallback(
    async (mainTranslation: string) => {
      const resp = await dataAssetClient.createDataAsset(
        {
          assetType: rename.assetType ?? DataAssetType.PersonGroup,
          name: mainTranslation,
          parentDataAssetId: rename.parentId ?? null
        },
        "true"
      );
      const id = resp.headers["x-resource-id"] as string;
      return id;
    },
    [rename]
  );

  /* MERGE */
  const [mergeActionTooltip, setMergeActionTooltip] = useState<string>(t("common:merge"));
  const [mergeData, setMergeData] = useState<PersonGroupsMergeDataProps | null>(null);
  const onCloseMergeCallback = useCallback(() => {
    setMergeData(current => (current ? { ...current, open: false } : current));
  }, []);
  const onMergeOpen = useCallback(() => {
    setMergeData(current =>
      current
        ? {
            ...current,
            open: true
          }
        : current
    );
  }, []);
  const onMergeDone = useCallback(async () => {
    reload({
      checkedIds: []
    });
    mutate();
    setMergeData(null);
  }, [reload, mutate]);

  /* CLASSIFY */
  const [classificationData, setClassificationData] = useState<ClassificationDataProps | null>(null);
  const onClassifyOpen = useCallback(async (id: string, item?: OverviewItem) => {
    if (item) {
      setClassificationData({ open: true, item });
    }
  }, []);
  const onCloseClassificationCallback = useCallback(() => {
    setClassificationData(null);
  }, []);
  const onConfirmClassificationCallback = useCallback(() => {
    setClassificationData(null);
    reload();
  }, [reload]);

  /* MOVE */
  const [moveActionTooltip, setMoveActionTooltip] = useState<string>(t("documentCenter:move"));
  const [dataCategoryMoveData, setDataCategoryMoveData] = useState<DataCategotyMoveModalDataProps | null>(null);
  const [dataTypeMoveData, setDataTypeMoveData] = useState<DataTypeMoveModalMoveModalDataProps | null>(null);
  const onToolbalMoveOpen = useCallback(() => {
    if (dataCategoryMoveData) {
      setDataCategoryMoveData(current => (current ? { ...current, open: true } : current));
    } else if (dataTypeMoveData) {
      setDataTypeMoveData(current => (current ? { ...current, open: true } : current));
    }
  }, [dataCategoryMoveData, dataTypeMoveData]);
  const onMoveOpen = useCallback((id: string, item?: OverviewItem | undefined) => {
    if (item && item?.assetType === DataAssetType.DataCategory) {
      setDataCategoryMoveData({ open: true, dataCategoryIds: [item.id], personGroupId: item.parentId });
    } else if (item && item?.assetType === DataAssetType.DataType) {
      setDataTypeMoveData({ open: true, dataCategoryId: item.parentId, dataTypeIds: [item.id] });
    }
  }, []);
  const onCloseDataCategoryMoveCallback = useCallback(() => {
    setDataCategoryMoveData(current => (current ? { ...current, open: false } : current));
  }, []);
  const onConfirmDataCategoryMoveCallback = useCallback(() => {
    setDataCategoryMoveData(null);
    reload({
      checkedIds: []
    });
  }, [reload]);

  const onCloseDataTypeMoveCallback = useCallback(() => {
    setDataTypeMoveData(current => (current ? { ...current, open: false } : current));
  }, []);
  const onConfirmDataTypeMoveCallback = useCallback(() => {
    setDataTypeMoveData(null);
    reload({
      checkedIds: []
    });
  }, [reload]);

  /* DUPLICATE */
  const onDuplicateSingleItem = useCallback(
    async (id: string, item?: OverviewItem) => {
      if (!id || !item) {
        return;
      }
      await actions.duplicate(item.id);
      reload();
    },
    [actions, reload]
  );

  const toolbarActions = useMemo(
    () => [
      { action: "sort" },
      { action: "add" },
      {
        action: "mark-all-as-read"
      }
    ],
    []
  );
  const addActions = useMemo(
    () => [
      isMultilingualEnabled
        ? {
            action: OVERVIEW_ADD_TYPE.CUSTOM_SINGLE,
            placeholder: t("personGroup:overviewAddPlaceholder"),
            onHandle: handleAddWithTranslations
          }
        : { action: OVERVIEW_ADD_TYPE.SINGLE, placeholder: t("personGroup:overviewAddPlaceholder") }
    ],
    [t, isMultilingualEnabled, handleAddWithTranslations]
  );

  useEffect(() => {
    if (!resourcesLoaded) {
      return;
    }
  }, [resourcesLoaded]);

  const selectionActions = useMemo(
    () => [
      {
        action: "merge",
        onHandle: onMergeOpen,
        title: mergeActionTooltip,
        icon: <MergeTypeIcon />,
        disabled: disableMergeAction
      },
      {
        action: "move",
        onHandle: onToolbalMoveOpen,
        title: moveActionTooltip,
        icon: <ToolbarMoveIcon disabled={disableMoveAction} />,
        disabled: disableMoveAction
      },
      { action: "remove" }
    ],
    [disableMergeAction, disableMoveAction, mergeActionTooltip, moveActionTooltip, onMergeOpen, onToolbalMoveOpen]
  );

  const rowActions = useMemo(() => {
    const defaultResourceActions: OverviewRowActionProps[] = [
      // @ts-expect-error - onHandle thought there is no assetType, but is actually there
      isMultilingualEnabled
        ? {
            action: "add-with-translations",
            titles: [t("dataCategory:addButtonTooltip"), t("dataType:addButtonTooltip")],
            placeholders: [t("dataCategory:overviewAddPlaceholder"), t("dataType:overviewAddPlaceholder")],
            onHandle: handleAddWithTranslations,
            icon: <Add />
          }
        : {
            action: "add",
            titles: [t("dataCategory:addButtonTooltip"), t("dataType:addButtonTooltip")],
            placeholders: [t("dataCategory:overviewAddPlaceholder"), t("dataType:overviewAddPlaceholder")]
          },
      // @ts-expect-error - onHandle thought there is no assetType, but is actually there
      isMultilingualEnabled
        ? { action: "rename", title: t("common:edit"), icon: <Edit />, onHandle: handleStartRename }
        : { action: "edit" },
      {
        action: "copy",
        title: t("common:duplicate"),
        onHandle: onDuplicateSingleItem,
        icon: <FileCopyIcon />
      },
      {
        action: "classify",
        onHandle: onClassifyOpen,
        title: t("resources_lists_data_types_categories_person_groups:classifyToolTipText"),
        icon: <ClassIcon />
      },
      {
        action: "merge",
        title: mergeActionTooltip,
        icon: <MergeTypeIcon />,
        onHandle: onMergeOpen,
        disabled: disableMergeAction
      },
      {
        action: "move",
        title: t("documentCenter:move"),
        icon: <MoveIcon />,
        onHandle: onMoveOpen
      },
      { action: "remove" }
    ];

    return defaultResourceActions.filter(({ action }) => action !== "classify" || isDataClassificationEnabled);
  }, [
    t,
    onDuplicateSingleItem,
    onClassifyOpen,
    mergeActionTooltip,
    onMergeOpen,
    disableMergeAction,
    onMoveOpen,
    isDataClassificationEnabled,
    isMultilingualEnabled,
    handleStartRename,
    handleAddWithTranslations
  ]);

  const rowClick = useCallback(
    async item => {
      if (item.unseen === true && item.parentId) {
        const { data: dataCategory } = await dataAssetClient.getDataAsset(item.parentId);
        if (!dataCategory || dataCategory.assetType !== DataAssetType.DataCategory || !dataCategory.parentDataAssetId) {
          return;
        }
        const { data: personGroup } = await dataAssetClient.getDataAsset(dataCategory.parentDataAssetId);
        if (personGroup && personGroup.id && personGroup.assetType === DataAssetType.PersonGroup) {
          await actions.updateDataType({
            personGroupId: personGroup.id,
            dataCategoryId: item.parentId,
            dataTypeId: item.id,
            updates: { unseen: false }
          });
          const reload = {
            shadowLoading: true,
            selectedId: item.id,
            reloadOverview: Date.now(),
            reloadMetaview: Date.now()
          };
          dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.PERSON_GROUPS, reload });
        }
      }
    },
    [actions, dispatch]
  );

  const handleMergeAction = useCallback(
    (items: OverviewItem[]) => {
      const groupedByType: Record<DataAssetType, OverviewItem[]> = {
        PERSON_GROUP: [],
        DATA_CATEGORY: [],
        DATA_TYPE: []
      };
      items.forEach(item => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        groupedByType[item.assetType].push(item);
      });

      const disableMerge =
        groupedByType.PERSON_GROUP.length < 1 &&
        groupedByType.DATA_CATEGORY.length < 1 &&
        groupedByType.DATA_TYPE.length < 1;
      setDisableMergeAction(disableMerge);

      if (groupedByType.PERSON_GROUP.length > 0) {
        setMergeData({
          open: false,
          selectedItems: groupedByType.PERSON_GROUP,
          assetType: DataAssetType.PersonGroup
        });
      } else if (groupedByType.DATA_CATEGORY.length > 0) {
        setMergeData({
          open: false,
          selectedItems: groupedByType.DATA_CATEGORY,
          assetType: DataAssetType.DataCategory
        });
      } else if (groupedByType.DATA_TYPE.length > 0) {
        setMergeData({ open: false, selectedItems: groupedByType.DATA_TYPE, assetType: DataAssetType.DataType });
      } else {
        setMergeData(null);
      }

      if (items.length) {
        setInfo(infoCard.merging);
      }

      // set tooltip
      if (!disableMerge) {
        setMergeActionTooltip(t("common:merge"));
      } else {
        setMergeActionTooltip(t("resources_lists_data_types_categories_person_groups:mergeDisableActionTooltip"));
      }
    },
    [infoCard.merging, setInfo, t]
  );

  const handleMoveAction = useCallback(
    (items: OverviewItem[]) => {
      const dataAssetTypes = [...new Set(items.map(({ assetType }) => assetType))];
      const parentIds = [...new Set(items.filter(({ parentId }) => parentId).map(({ parentId }) => parentId))];
      const disabled =
        dataAssetTypes.length !== 1 || parentIds.length > 1 || dataAssetTypes[0] === DataAssetType.PersonGroup;
      setDisableMoveAction(disabled);

      if (!disabled) {
        const assetType = dataAssetTypes[0];
        const parentId = parentIds[0];
        if (assetType === DataAssetType.DataCategory) {
          setDataCategoryMoveData({
            open: false,
            dataCategoryIds: items.map(({ id }) => id),
            personGroupId: parentId
          });
          setDataTypeMoveData(null);
        } else if (assetType === DataAssetType.DataType) {
          setDataTypeMoveData({ open: false, dataCategoryId: parentId, dataTypeIds: items.map(({ id }) => id) });
          setDataCategoryMoveData(null);
        }
      } else {
        setDataCategoryMoveData(null);
        setDataTypeMoveData(null);
      }

      // set tooltip
      if (disabled) {
        setMoveActionTooltip(t("resources_lists_data_types_categories_person_groups:moveDisableActionTooltip"));
      } else {
        setMoveActionTooltip(t("documentCenter:move"));
      }
    },
    [t]
  );

  const onCheckedItemsCallback = useCallback(
    (items: OverviewItem[]) => {
      handleMergeAction(items);
      handleMoveAction(items);
    },
    [handleMergeAction, handleMoveAction]
  );

  const showAddActions = auth?.permissions.find(permission => resourcesWritePermissions.includes(permission));

  const docViewContent = (
    <>
      <Overview
        shadowLoading={true}
        selectionActions={selectionActions}
        onAddClose={showEnterInfo}
        onRowLeave={showEnterInfo}
        header={t(`resources_overview:personGroups`)}
        collection={COLLECTIONS.PERSON_GROUPS}
        rowActions={rowActions}
        toolbarActions={toolbarActions}
        addActions={showAddActions ? addActions : undefined}
        checkable={true}
        selectable={true}
        onRowClick={rowClick}
        onCheckedItems={onCheckedItemsCallback}
        translationDomainName={`resources_${resourceType}`}
        isMultilingual={isMultilingualEnabled}
        dnd={true}
        toolbarMode={"tabs"}
      />
      <PersonGroupsOverviewMergeModal mergeData={mergeData} onCancel={onCloseMergeCallback} onMerge={onMergeDone} />
      <PersonGroupsClassificationModal
        classificationData={classificationData}
        onCancel={onCloseClassificationCallback}
        onConfirm={onConfirmClassificationCallback}
      />
      <DataCategotyOverviewMoveModal
        dataCategoryMoveModalData={dataCategoryMoveData}
        onCancel={onCloseDataCategoryMoveCallback}
        onConfirm={onConfirmDataCategoryMoveCallback}
      />
      <DataTypeOverviewMoveModal
        dataTypeMoveModalData={dataTypeMoveData}
        onCancel={onCloseDataTypeMoveCallback}
        onConfirm={onConfirmDataTypeMoveCallback}
      />
      {!!rename.translationKey && (
        <MultilingualEditorModal
          title={t(
            `${
              rename.assetType === DataAssetType.DataCategory
                ? "dataCategory"
                : rename.assetType === DataAssetType.DataType
                  ? "dataType"
                  : "personGroup"
            }:inputLabel`
          )}
          onAdd={handleAdd}
          translationKey={rename.translationKey}
          onClose={completeRename}
        />
      )}
    </>
  );
  return (
    <DocMetaView
      metaViewContent={
        selectedId ? (
          <ProcessesInMetaView selectedNode={selectedId} />
        ) : (
          <MetaView translationKey={"resources_lists_data_types_categories_person_groups"} />
        )
      }
    >
      {docViewContent}
    </DocMetaView>
  );
};

export default PersonGroupsOverview;
