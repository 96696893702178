import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProcessorApi, useUpdateProcessorApi } from "../../../api/processorPAApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import { useProcessorPAEditConfirmation } from "../ProcessorPAEditConfirmation";
import { useMetaView } from "../../../contexts/meta-view-context";

export const ProcessorThirdCountryTransfer = ({ readonly }: { readonly readonly?: boolean }) => {
  const { id } = useParams();
  const { t } = useTranslation("processor_pa_page");
  const { data } = useGetProcessorApi({ documentId: id || "" });
  const { updateProcessor } = useUpdateProcessorApi({ documentId: id || "" });

  const { beforeProcessUpdate } = useProcessorPAEditConfirmation();

  const [thirdWorldDataStorageIds, setThirdWorldDataStorageIds] = useState<string[]>([]);
  const [thirdWorldExternalRecipientIds, setThirdWorldExternalRecipientIds] = useState<string[]>([]);
  const [thirdWorldOrgUnitIds, setThirdWorldOrgUnitIds] = useState<string[]>([]);
  useEffect(() => {
    if (data?.processorPA) {
      setThirdWorldDataStorageIds(data.processorPA.thirdWorldDataStorageIds || []);
      setThirdWorldExternalRecipientIds(data.processorPA.thirdWorldExternalRecipientIds || []);
      setThirdWorldOrgUnitIds(data.processorPA.thirdWorldOrgUnitIds || []);
    }
  }, [data?.processorPA]);

  const onThirdWorldDataStorageIdsChange = useCallback(
    (thirdWorldDataStorageIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setThirdWorldDataStorageIds(thirdWorldDataStorageIds);
        updateProcessor({ thirdWorldDataStorageIds });
      });
    },
    [beforeProcessUpdate, updateProcessor]
  );

  const onThirdWorldExternalRecipientIdsChange = useCallback(
    (thirdWorldExternalRecipientIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setThirdWorldExternalRecipientIds(thirdWorldExternalRecipientIds);
        updateProcessor({ thirdWorldExternalRecipientIds });
      });
    },
    [beforeProcessUpdate, updateProcessor]
  );

  const onThirdWorldOrgUnitIdsChange = useCallback(
    (thirdWorldOrgUnitIds: string[]) => {
      return beforeProcessUpdate(async () => {
        setThirdWorldOrgUnitIds(thirdWorldOrgUnitIds);
        updateProcessor({ thirdWorldOrgUnitIds });
      });
    },
    [beforeProcessUpdate, updateProcessor]
  );

  const infoCard = useMemo(
    () => ({
      enteringPA: {
        title: t("enteringInfoCardTitle", ""),
        text: t("enteringInfoCardText", "")
      },
      thirdWorldDataStorageIds: {
        title: t("thirdWorldDataStorageIds-title", ""),
        text: t("thirdWorldDataStorageIds-info", "")
      },
      thirdWorldExternalRecipientIds: {
        title: t("thirdWorldExternalRecipientIds-title", ""),
        text: t("thirdWorldExternalRecipientIds-info", "")
      },
      thirdWorldOrgUnitIds: {
        title: t("thirdWorldOrgUnitIds-title", ""),
        text: t("thirdWorldOrgUnitIds-info", "")
      }
    }),
    [t]
  );

  const { setInfo } = useMetaView();
  useEffect(() => {
    setInfo(infoCard.enteringPA);
  }, [setInfo, infoCard.enteringPA]);

  return (
    <Box>
      <Box>
        <QuestionnaireSubHeader text={t("pa_recipients:page_title")} />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.DATA_STORAGE}
          questionId={"thirdWorldDataStorageIds"}
          value={thirdWorldDataStorageIds}
          questionName={t("data_subject_requests_data_page:datalocation")}
          info={infoCard.thirdWorldDataStorageIds}
          onChange={onThirdWorldDataStorageIdsChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.EXTERNAL_DATA_RECIPIENTS}
          questionId={"thirdWorldExternalRecipientIds"}
          questionName={t("thirdWorldExternalRecipientIds")}
          value={thirdWorldExternalRecipientIds}
          info={infoCard.thirdWorldExternalRecipientIds}
          onChange={onThirdWorldExternalRecipientIdsChange}
          disabled={readonly}
        />
      </Box>
      <Box>
        <Question
          qType={QUESTION_TYPE.ORG_UNITS_PICKER}
          questionId={"thirdWorldOrgUnitIds"}
          questionName={t("thirdWorldOrgUnitIds")}
          value={thirdWorldOrgUnitIds}
          info={infoCard.thirdWorldOrgUnitIds}
          onChange={onThirdWorldOrgUnitIdsChange}
          disabled={readonly}
        />
      </Box>
    </Box>
  );
};
