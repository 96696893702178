import { useErrorSnackbar } from "../../../../hook/errorSnackbar";
import React, { useCallback, useMemo } from "react";
import { useRisk } from "../../../contexts/risk-context";
import Question from "../../../../components/Question/Question";
import { QUESTION_TYPE } from "../../../../components/Question/QuestionTypes";
import useSWR from "swr";
import { getRiskTOMsApi, TOMOptionDTO } from "../../../api/riskApi";
import { MeasuresQuestionAdditionalData } from "../../../../components/Question/types/resources/MeasuresQuestion";
import CustomAlert from "../../../../components/CustomAlert/CustomAlert";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const RiskImplementedMeasureIDs = () => {
  const { t } = useTranslation("risk_first_assessment_page");
  const { risk, updateBasicInfoHook, riskId } = useRisk();
  const { catchAsSnackbar } = useErrorSnackbar();

  const { data, mutate } = useSWR(riskId ? ["risks", riskId, "toms"] : null, () => getRiskTOMsApi(riskId));

  const updateImplementedMeasureIds = useCallback(
    measureIds => {
      return updateBasicInfoHook({ implementedMeasureIds: measureIds })
        .then(() => mutate())
        .catch(catchAsSnackbar("failed to update measures"));
    },
    [mutate, updateBasicInfoHook, catchAsSnackbar]
  );

  const additionalData = useMemo(() => {
    return {
      protectionObjectiveIds: risk?.protectionObjectiveIds || []
    } satisfies MeasuresQuestionAdditionalData;
  }, [risk?.protectionObjectiveIds]);

  const emptyProtectionObjectiveIds = !risk?.protectionObjectiveIds.length;

  return (
    <>
      {emptyProtectionObjectiveIds ? (
        <Box mt={2}>
          <CustomAlert severity="info">{t("risk_first_assessment_page:selectProtectionObjectives")}</CustomAlert>
        </Box>
      ) : (
        <></>
      )}
      <Question
        qType={QUESTION_TYPE.MEASURE}
        value={risk.implementedMeasureIds}
        onChange={updateImplementedMeasureIds}
        options={data?.toms || emptyToms}
        disabled={risk.permission !== "write" || emptyProtectionObjectiveIds}
        allowAdd={true}
        nonStandardAdditionalData={additionalData}
      />
    </>
  );
};

const emptyToms: TOMOptionDTO[] = [];
