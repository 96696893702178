import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { QuestionnaireAutoNavigateWhenAvailableStepMissing, QuestionnairePageButtons } from "./QuestionnaireSubmission";
import DocumentNotFoundPage from "../shared/DocumentNotFound/DocumentNotFound";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";
import { QuestionnaireStaticPages } from "./QuestionnaireStatic";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import { COLLECTIONS } from "../../collections";
import { META_VIEW_TABS } from "components/MetaView/MetaView";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { USER_FEATURE_IDS } from "app/features";
import { pages } from "./paPages";
import { useRedirectOnLegacyPage } from "./useRedirectOnLegacyPage";
import { usePAPermission } from "./usePAPermission";
import GeneralPageStepper, { PageStep } from "../../../components/Pagination/GeneralPageStepper";
import { QuestionnaireStepModel, useQuestionnairePaginationSteps } from "./useQuestionnairePaginationSteps";
import { usePathName } from "../../router/router-custom-hooks";
import { changeLastUrlPath } from "../../../components/Pagination/url-handler";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

export default function Questionnaire() {
  const { id: processId, page: pageParam } = useParams();
  useRedirectOnLegacyPage();
  const { setQuestionId, setInfo, setInfoId } = useMetaView();
  const isOwlitActivated = useIsFeaturePresent(USER_FEATURE_IDS.OWLIT_USER);
  const [deleted, setDeleted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const { paPermission, mutate, isLoading: paPermissionLoading } = usePAPermission(processId || "");

  /* useEffect */
  useEffect(() => {
    if (paPermissionLoading) {
      return;
    }

    setDeleted(!paPermission);
  }, [paPermissionLoading, paPermission]);

  const writeAccess = paPermission?.permission === "write";
  const explicitNoPermission = paPermission?.permission === "none";

  useEffect(() => {
    if (paPermissionLoading) {
      return;
    }

    if (!paPermission) {
      setDocumentNotFound(true);
      return;
    }

    if (paPermissionLoading) {
      return;
    }

    if (!paPermission || explicitNoPermission) {
      setDocumentNotFound(true);
      setDeleted(true);
      return;
    }

    setPageLoaded(true);
    setDocumentNotFound(false);
  }, [paPermission, paPermissionLoading, explicitNoPermission]);

  // pagination contents
  const onSubmission = useCallback(
    async (input?: { readonly skipSuccessScreen?: boolean }) => {
      await mutate();
      if (input?.skipSuccessScreen) {
        return;
      }
      setSubmitted(true);
    },
    [mutate]
  );

  const onStepChange = useCallback(() => {
    setQuestionId(null);
    setInfo(null);
    setInfoId(null);
  }, [setInfo, setInfoId, setQuestionId]);

  const metaViewTabIds = useMemo(
    () =>
      [
        META_VIEW_TABS.ASSISTANT,
        META_VIEW_TABS.TODOS,
        META_VIEW_TABS.COMMENTS,
        META_VIEW_TABS.HISTORY,
        isOwlitActivated ? META_VIEW_TABS.OWLIT : null
      ].flatMap(notNull => (notNull ? [notNull] : [])),
    [isOwlitActivated]
  );

  if (documentNotFound) {
    return <DocumentNotFoundPage collection={COLLECTIONS.PROCESSES} />;
  }

  const displayForm = !deleted && !submitted;
  if (!displayForm) {
    return <QuestionnaireStaticPages status={paPermission?.status || ""} />;
  }

  const CurrentPage = (pages[pageParam || ""] || Object.values(pages).find(p => p.name === pageParam))?.element || (
    <></>
  );

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          tabs={metaViewTabIds}
          docName={pageLoaded ? paPermission?.name : ""}
          pageId={pageParam}
          docId={processId}
          collection={COLLECTIONS.PROCESSES}
          editable={true}
        />
      }
    >
      <DocView
        header={paPermission?.name || ""}
        pagination={<QuestionnairePageStepper onStepChange={onStepChange} />}
        icons={<QuestionnaireNavigationIcons />}
      >
        {pageLoaded ? (
          <>
            <CurrentPage readonly={!writeAccess} />
            <QuestionnairePageButtons onSubmission={onSubmission} disabled={!writeAccess} />
            <QuestionnaireAutoNavigateWhenAvailableStepMissing />
          </>
        ) : (
          <Box mt={10} textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </DocView>
    </DocMetaView>
  );
}

function QuestionnairePageStepper({ onStepChange }: { readonly onStepChange: (step: PageStep) => void }) {
  const { currentDisplayedSteps } = useQuestionnairePaginationSteps();
  return <GeneralPageStepper steps={currentDisplayedSteps} onStepChange={onStepChange} />;
}

const QuestionnaireNavigationIcons = () => {
  const navigate = useNavigate();
  const path = usePathName();
  const { stepsChunks, currentStep } = useQuestionnairePaginationSteps();
  const navigationButtonsVisible = stepsChunks.length > 1;
  const processSteps = stepsChunks[0] || emptyArray;
  const dpiaSteps = stepsChunks[1] || emptyArray;
  const showingDPIAPages = navigationButtonsVisible && dpiaSteps.some(step => step.path === currentStep?.path);

  const jumpToProcessPages = useCallback(() => {
    const firstStepOfBasicSteps = processSteps[0];
    navigate(changeLastUrlPath(path, firstStepOfBasicSteps.path));
  }, [navigate, processSteps, path]);

  const jumpToDPIAPages = useCallback(() => {
    const firstStepOfExpertSteps = dpiaSteps[0];
    navigate(changeLastUrlPath(path, firstStepOfExpertSteps.path));
  }, [navigate, dpiaSteps, path]);

  if (!navigationButtonsVisible) {
    return <></>;
  }

  return (
    <Box style={pointer}>
      <InsertDriveFileIcon color={showingDPIAPages ? "disabled" : "primary"} onClick={jumpToProcessPages} />
      <VerifiedUserIcon color={showingDPIAPages ? "primary" : "disabled"} onClick={jumpToDPIAPages} />
    </Box>
  );
};

const emptyArray: QuestionnaireStepModel[] = [];

const pointer: CSSProperties = {
  cursor: "pointer"
};
