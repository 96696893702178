import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import QuestionnaireSubHeader from "../../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { isEmpty } from "lodash-es";
import { useMetaView } from "app/contexts/meta-view-context";
import { PAFollowUpTask } from "../PAFollowUpTask";
import { useDpia } from "app/api/process/dpiaPageApi";
import { useParams } from "react-router-dom";
import { useProcessing } from "app/api/process/processingPageApi";
import { DpiaPageDTO } from "app/api/generated/process-service";
import RadioButtonQuestionList from "./RadioButtonQuestionList";
import ResultSection from "./ResultSection";
import ConfirmationDialog from "./ConfirmationDialog";
import useReasonTextEditing from "hook/useReasonTextEditing";
import { usePAPermission } from "../usePAPermission";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { ProcessorPAsOfPA } from "../../processor-pas/pa/ProcessorPAsOfPA";
import { useEnteringInfoCard } from "hook/useEnteringInfoCard";
import { AIActPreCheck } from "./AIActPreCheck";
import { useAiPrecheck } from "../../../api/process/processingAiPrecheckApi";

const dpiaDecisionKeys = [
  "blackOrWhitelist",
  "profiling",
  "automaticDecisionMaking",
  "systematicMonitoring",
  "specialCategories",
  "largeQuantities",
  "setComparison",
  "vulnerablePersons",
  "innovativeUse",
  "preventionOfRights"
];

/**
 * This component has the purpose of identifying whether or not a DPIA is necessary and to provide a reason for this decision.
 * Based on the yes/no ratio of the first question block an automated answer to whether or not a DPIA is necessary is generated. A reason is provided for the automated decision.
 * The user still has the chance to overwrite the decision and update the reason.
 */
export default function DocViewForAssessmentPage({ readonly }: { readonly readonly?: boolean }) {
  const { id } = useParams();
  const { t } = useTranslation("pa_assessment");
  const { setInfoId } = useMetaView();
  const dpia = useDpia({
    documentId: id || ""
  });
  const processing = useProcessing({ documentId: id || "" });

  const { paPermission } = usePAPermission(id || "");
  const isExpert = !!paPermission?.isExpert;

  const isProcessorPAEnabled = useIsFeaturePresent(FEATURES.PROCESSOR_PA_FEATURE);

  useEnteringInfoCard({
    pathName: `/processes/${id}/pa-assessment`,
    infoId:
      dpia.data?.processPage?.dpiaRequiredDecision === "yes" ? "infocard.pa.page6.reason" : "infocard.pa.page6.default"
  });

  const [dataToConfirm, setDataToConfirm] = useState<Record<string, string | number | boolean | undefined> | null>(
    null
  );
  const pageLoaded = !dpia.isLoading && !processing.isLoading;

  useEffect(() => {
    if (dpia.data?.processPage?.dpiaRequiredDecision === "yes") {
      setInfoId("infocard.pa.page6.reason");
    } else {
      setInfoId("infocard.pa.page6.default");
    }
  }, [dpia.data?.processPage?.dpiaRequiredDecision, setInfoId]);

  const preSelectVulnerablePersons = useMemo(() => {
    return !!dpia.data?.processPage?.vulnerablePersonsAutomaticDecision;
  }, [dpia.data?.processPage]);

  const setMetaForQuestion = useCallback(
    (key: string) => {
      setInfoId(`infocard.pa.page6.${key}`);
    },
    [setInfoId]
  );

  const onClickDpiaNecessary = useCallback(
    function (isRequired: string) {
      const dpiaNecessary = isRequired;
      if (!dpia.data?.processPage) {
        return;
      }
      const { dpiaRequiredUsersChoice } = dpia.data.processPage;
      const dpiaRequiredStandardAnswerIndex = dpia.data.processPage.dpiaRequiredStandardAnswerIndex;
      const payload = {
        dpiaRequiredDecision: dpiaNecessary === "yes" ? "yes" : "no",
        dpiaRequiredUsersChoice: true
      };
      if (!dpiaRequiredUsersChoice && dpiaRequiredStandardAnswerIndex !== 0) {
        setDataToConfirm(payload);
      } else {
        dpia.actions.updateDpia(payload);
      }
    },
    [dpia.actions, dpia.data?.processPage]
  );

  const {
    handleMouseEnter,
    handleMouseLeave,
    isEditing,
    isReasonHovered,
    onEditReasonCallback,
    reasonText,
    setIsEditing,
    setReasonText,
    updateReasonText
  } = useReasonTextEditing({
    dpiaActions: dpia.actions,
    processPage: dpia.data?.processPage as any,
    setDataToConfirm
  });

  const dpiaRequiredDecision = dpia.data?.processPage?.dpiaRequiredDecision;

  const overwrittenResultEl = useMemo(() => {
    return (
      !isEmpty(dpiaRequiredDecision) &&
      (dpia.data?.processPage?.dpiaRequiredUsersChoice ||
        dpia.data?.processPage?.dpiaRequiredStandardAnswerIndex === 0) && (
        <Box mt={-1}>
          <Typography color="textSecondary">{t("edited")}</Typography>
        </Box>
      )
    );
  }, [
    dpia.data?.processPage?.dpiaRequiredStandardAnswerIndex,
    dpia.data?.processPage?.dpiaRequiredUsersChoice,
    dpiaRequiredDecision,
    t
  ]);

  const overwrittenReasonEl = useMemo(() => {
    return (
      reasonText !== "" &&
      dpia.data?.processPage?.dpiaRequiredStandardAnswerExplanation?.startsWith("{") && (
        <Box mt={-1}>
          <Typography color="textSecondary">{t("edited")}</Typography>
        </Box>
      )
    );
  }, [dpia.data?.processPage, reasonText, t]);

  const isReasonSingleWord = useMemo(() => {
    const parsedText = reasonText && reasonText.startsWith("{") && JSON.parse(reasonText);
    const text = Array.isArray(parsedText?.blocks) ? parsedText?.blocks[0]?.text?.trim() : "";
    return text && text.split(" ").length === 1;
  }, [reasonText]);

  const {
    actions: { mutate: mutateAIPreCheck }
  } = useAiPrecheck({ documentId: id || "" });

  /* ON RADIO BUTTONS CHANGE */
  const onRadioButtonQuestionChangeCallback = useCallback(
    async (key: string, newValue: string | number | boolean | undefined) => {
      setMetaForQuestion(key);

      await dpia.actions.updateDpia({
        [key as keyof DpiaPageDTO]: newValue
      });

      const keysRelevantForAIPreCheck = ["automaticDecisionMaking"];
      if (keysRelevantForAIPreCheck.includes(key)) {
        mutateAIPreCheck();
      }
    },
    [dpia.actions, mutateAIPreCheck, setMetaForQuestion]
  );

  const onDpiaNecessaryYesCallback = useCallback(() => {
    setInfoId("infocard.pa.page6.reason");
    onClickDpiaNecessary("yes");
  }, [onClickDpiaNecessary, setInfoId]);

  const onDpiaNecessaryNoCallback = useCallback(() => {
    setInfoId("infocard.pa.page6.default");
    onClickDpiaNecessary("no");
  }, [onClickDpiaNecessary, setInfoId]);

  /* CONFIRM DIALOG */
  const onUserConfirmCallback = useCallback(async () => {
    if (dataToConfirm) {
      await dpia.actions.updateDpia(dataToConfirm);
    }
    setIsEditing(true);
    setDataToConfirm(null);
  }, [dataToConfirm, dpia.actions, setIsEditing]);

  const onUserCancelCallback = useCallback(() => {
    setDataToConfirm(null);
  }, []);

  if (!pageLoaded) {
    return <></>;
  }

  return (
    <>
      <QuestionnaireSubHeader text={t("header")} />
      <RadioButtonQuestionList
        dpiaDecisionKeys={dpiaDecisionKeys}
        dpiaData={dpia.data}
        readonly={!!readonly}
        preSelectVulnerablePersons={preSelectVulnerablePersons}
        setMetaForQuestion={setMetaForQuestion}
        onRadioButtonQuestionChange={onRadioButtonQuestionChangeCallback}
      />
      {isExpert && (
        <>
          <Box mb={8} mt={5}>
            <ResultSection
              dpiaRequiredDecision={dpiaRequiredDecision}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              isEditing={isEditing}
              isReasonHovered={isReasonHovered}
              isReasonSingleWord={isReasonSingleWord}
              onDpiaNecessaryNoCallback={onDpiaNecessaryNoCallback}
              onDpiaNecessaryYesCallback={onDpiaNecessaryYesCallback}
              onEditReasonCallback={onEditReasonCallback}
              overwrittenReasonEl={overwrittenReasonEl}
              overwrittenResultEl={overwrittenResultEl}
              readonly={!!readonly}
              reasonText={reasonText}
              setReasonText={setReasonText}
              updateReasonText={updateReasonText}
            />

            <AIActPreCheck />

            <PAFollowUpTask disabled={readonly} />
          </Box>
          {isProcessorPAEnabled && (
            <Box mb={8} mt={5}>
              <ProcessorPAsOfPA paId={id || ""} readonly={!!readonly} />
            </Box>
          )}
        </>
      )}
      <ConfirmationDialog
        open={Boolean(dataToConfirm)}
        onCancel={onUserCancelCallback}
        onConfirm={onUserConfirmCallback}
        title={t("pa_assessment:confirmation_required")}
        text={t("pa_assessment:confirmation_text")}
      />
    </>
  );
}
