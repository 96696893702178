import React, { ReactNode, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardTitleComment from "../CardTitleComment/CardTitleComment";
import Avatar from "@material-ui/core/Avatar";
import { Box, Button, Grid } from "@material-ui/core";
import EllipsisMenu from "../EllipsisMenu/EllipsisMenu";
import { convertToRaw, EditorState, RawDraftContentState } from "draft-js";
import "@draft-js-plugins/mention/lib/plugin.css";
import "./mentionEditorStyles.css";
import { arrayBoldString, EditorWithMention } from "./EditorWithMention";
import { CommentModelDB, CommentModelDTO } from "app/api/generated/comment-service";
import { UserDTO } from "app/api/user/userApi";
import { useComments } from "app/api/commentApi";
import { useTranslation } from "react-i18next";
import { convertContentStateFromRawObject } from "../../app/pages/questionnaires/utils/textEditorConverter";
import { useAuthentication } from "../../app/handlers/authentication/authentication-context";

const useStyles = makeStyles(theme => ({
  card: {
    overflowWrap: "break-word",
    overflowY: "auto"
  },
  cardGrid: {
    width: "100%",
    marginLeft: -theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& .MuiCardContent-root:last-child": {
      paddingBottom: 0
    }
  },
  cardHeader: {
    textWrap: "nowrap",
    alignItems: "flex-start",
    padding: theme.spacing(1),
    "& .MuiCardHeader-avatar": {
      marginRight: theme.spacing(1),
      zIndex: 1
    },
    "& .MuiTypography-h4": {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px"
    },
    "& .MuiTypography-body2": {
      fontSize: "12px",
      lineHeight: "24px"
    },
    "& .MuiCardHeader-content": {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      "& .MuiCardHeader-title": {
        marginRight: theme.spacing(1)
      }
    },
    "& .MuiCardHeader-action": {
      marginRight: -theme.spacing(2)
    }
  },
  infoCardContent: {
    padding: 0,
    paddingBottom: 0,
    marginTop: -theme.spacing(2),
    "& .MuiCardHeader-avatar": {
      marginRight: theme.spacing(1)
    },
    "& .MuiTypography-h4": {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px"
    },
    "& .MuiTypography-body2": {
      fontSize: "12px",
      lineHeight: "24px"
    },
    "& .MuiCardHeader-content": {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      "& .MuiCardHeader-title": {
        marginRight: theme.spacing(1)
      }
    }
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 36,
    height: 36,
    fontFamily: "SuisseIntl"
  },
  replyAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: 24,
    height: 24,
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 24,
    textAlign: "center",
    fontFamily: "SuisseIntl"
  },
  marginTopButtons: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      width: "fit-content"
    },
    fontFamily: "SuisseIntl",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 16,
    letterSpacing: 1.25,
    textAlign: "left"
  },
  buttonLeft: {
    width: "48%",
    marginRight: "2%"
  },
  buttonRight: {
    width: "48%",
    marginLeft: "2%"
  },
  checkBoxMargin: {
    marginLeft: 8
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 10
  },
  ellipsisMenu: {
    display: "flex",
    alignItems: "center"
  },
  replyFormContainer: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(0.9)
  },
  replyFormAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: 24,
    height: 24,
    fontWeight: 600,
    fontSize: "10px",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: -12
  },
  replyForm: {
    width: "100%",
    caretColor: theme.palette.primary.main
  },
  replyButton: {
    fontFamily: "SuisseIntl",
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    marginTop: theme.spacing(0.5),
    marginLeft: 10,
    maxWidth: "fit-content"
  },
  repliesCardHeader: {
    padding: theme.spacing(1),
    marginLeft: 5,
    textWrap: "nowrap",
    "& .MuiTypography-h4": {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px"
    },
    "& .MuiCardHeader-action": {
      marginRight: -theme.spacing(2)
    }
  },
  replyContentContainer: {
    marginTop: theme.spacing(0.5),
    marginLeft: -theme.spacing(4),
    flexDirection: "column",
    display: "flex"
  },
  commentContent: {
    whiteSpace: "pre-wrap",
    marginLeft: theme.spacing(2.5),
    overflowX: "hidden"
  },
  replyContent: {
    whiteSpace: "pre-wrap",
    marginLeft: theme.spacing(6.5),
    marginTop: -theme.spacing(1)
  },
  mentionTextEditor: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    maxHeight: "150px",
    overflow: "auto",
    borderRadius: "4px",
    border: "1px solid rgba(193, 194, 195, 1)",
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    zIndex: 1,
    "&:hover": {
      borderColor: theme.palette.primary.main
    },
    "& .public-DraftStyleDefault-block": {
      marginTop: 0
    }
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(4),
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: theme.spacing(8.5),
      left: -theme.spacing(1),
      width: "2px",
      backgroundColor: "#A9ACAD",
      zIndex: -2
    }
  },
  commentWrapperNoReplies: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(4),
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: "auto",
      left: -theme.spacing(1),
      width: "2px",
      backgroundColor: "#A9ACAD",
      zIndex: -2
    }
  },
  lastReplyOverlay: {
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      top: theme.spacing(2),
      left: theme.spacing(3),
      width: "2px",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      zIndex: -1
    }
  },
  highlightedComment: {
    animation: "$highlightFadeOut 3s forwards",
    paddingRight: theme.spacing(2),
    marginRight: -theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: -theme.spacing(1),
    borderRadius: theme.spacing(0.5)
  },
  "@keyframes highlightFadeOut": {
    "0%": {
      backgroundColor: "rgba(235, 241, 255, 1)"
    },
    "100%": {
      backgroundColor: "transparent"
    }
  }
}));

type CommentProps = {
  isNew: boolean;
  isEdited?: number[];
  setIsEdited?: (path: number[]) => void;
  commentIndex?: number;
  onSubmit: (questionId: string, commentText: EditorState, commentIndex: number) => void;
  onCancel: () => void;
  onEdit?: (questionId: string, commentText: EditorState) => void;
  questionId: string;
  avatar: string;
  header: ReactNode;
  comment?: CommentModelDB;
  subHeader: ReactNode;
  buttonTextReply: string;
  buttonTextCancel: string;
  buttonTextSave: string;
  ellipsisMenuData?: { icon: string; title: string; callBackFunction: (...args: any[]) => any }[];
  onReplySubmit?: (
    replyText: EditorState,
    parentComment: CommentModelDB,
    addComment: (comment: CommentModelDTO) => Promise<string>
  ) => void;
  createAvatar?: (userId: string) => ReactNode;
  replySubHeader?: (created: Date) => ReactNode;
  replyHeader: (userId: string) => ReactNode;
  suggestions: (UserDTO & { name: string })[];
  setSuggestionsOnSearchChange: (search: string) => void;
  selectMentions: (entries: UserDTO[]) => void;
  readOnly?: boolean;
  newlyAddedCommentId?: string;
};

function Comment({
  isNew,
  isEdited,
  setIsEdited,
  commentIndex,
  onSubmit,
  onCancel,
  onEdit,
  questionId,
  avatar,
  header,
  comment,
  subHeader,
  buttonTextReply,
  buttonTextCancel,
  buttonTextSave,
  ellipsisMenuData,
  onReplySubmit,
  createAvatar,
  replySubHeader,
  replyHeader,
  suggestions,
  setSuggestionsOnSearchChange,
  selectMentions,
  readOnly,
  newlyAddedCommentId
}: CommentProps) {
  const classes = useStyles();
  const primaryColour = useTheme().palette.primary.main;
  const { t } = useTranslation("service_providers_overview");
  const { auth } = useAuthentication();

  const [commentText, setCommentText] = useState(EditorState.createEmpty());
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState(EditorState.createEmpty());
  const [editText, setEditText] = useState(EditorState.createEmpty());
  const replyIsEdited = isEdited && isEdited.length === 2 && isEdited[0] === commentIndex;
  const commentIsEdited = isEdited?.length && !replyIsEdited;
  const [currentMentions, setCurrentMentions] = useState<UserDTO[]>([]);

  const onSearchChange = React.useCallback(
    ({ value }: { value: string }) => {
      setSuggestionsOnSearchChange(value);
    },
    [setSuggestionsOnSearchChange]
  );

  const onAddMention = React.useCallback(
    (newEntry: UserDTO) => {
      // get the mention object selected
      const temp = [...currentMentions, newEntry];
      setCurrentMentions(temp);
      selectMentions(temp);
    },
    [currentMentions, selectMentions, setCurrentMentions]
  );

  const replies = useComments({ commentId: comment?.id || "" });

  useEffect(() => {
    if (isEdited && comment) {
      const commentTextContentState = convertContentStateFromRawObject(comment.contentState, comment.value);
      setCommentText(lastState => EditorState.push(lastState, commentTextContentState, "remove-range"));
      if (replyIsEdited) {
        const reply = replies.data?.commentModels?.[isEdited[1]];
        if (!reply) return;
        const replyTextContentState = convertContentStateFromRawObject(reply.contentState, comment.value);
        setEditText(lastState => EditorState.push(lastState, replyTextContentState, "remove-range"));
      }
    }
  }, [isEdited, comment, replies.data, replyIsEdited]);

  const displayableComment = React.useCallback(
    function (contentState: RawDraftContentState, value: string) {
      let temp: string | React.ReactElement = value;
      const listName: string[] = [];
      if (contentState.entityMap && value !== "") {
        Object.entries(contentState.entityMap).forEach((item, index) => {
          const entity = item[1];
          if (entity.data && entity.data.mention && entity.data.mention.name) {
            const name = entity.data.mention.name;
            listName.push(name);
          }
        });
        temp = arrayBoldString(temp, listName, primaryColour, true);
      }
      return temp;
    },
    [primaryColour]
  );

  const cancelComment = React.useCallback(() => {
    // reset comment id of comment being edited
    if (isEdited && setIsEdited) setIsEdited([]);
    if (onCancel) onCancel();
    setCommentText(EditorState.createEmpty());
  }, [onCancel, setIsEdited, isEdited]);

  const saveComment = React.useCallback(
    (questionId: string, commentText: EditorState) => {
      if (isEdited?.length && onEdit) {
        onEdit(questionId, commentText);
        return;
      }
      onSubmit(questionId, commentText, commentIndex || 0);
      setCommentText(EditorState.createEmpty());
    },
    [commentIndex, isEdited, onEdit, onSubmit]
  );

  const deleteReply = React.useCallback(
    async (commentIdx: number) => {
      const originalIndex = (replies.data?.commentModels?.length || 0) - 1 - commentIdx;
      const commentId = replies.data?.commentModels?.[originalIndex]?.id;
      if (!commentId) return;
      await replies.deleteComment(commentId);
    },
    [replies]
  );

  const saveReply = React.useCallback(() => {
    if (!comment || !onReplySubmit) return;
    setShowReplyForm(!showReplyForm);
    onReplySubmit(replyText, comment, (comment: CommentModelDTO) => {
      return replies.addComment(comment);
    });
    setCurrentMentions([]);
    setReplyText(EditorState.createEmpty());
  }, [comment, replies, onReplySubmit, setShowReplyForm, showReplyForm, replyText]);

  const editReply = React.useCallback(async () => {
    const replyIndex = isEdited?.[1];
    if (typeof replyIndex !== "number" || !comment || !onReplySubmit) return;
    const replyId = replies.data?.commentModels?.[replyIndex]?.id;
    if (!replyId) return;
    const contentState = convertToRaw(editText.getCurrentContent());
    const mentionedUsers = Object.values(contentState.entityMap)
      .map(element => {
        if (!element.data.mention) return null;
        return element.data.mention.id;
      })
      .filter((id): id is string => !!id);
    await replies.updateComment(replyId, {
      contentState,
      parentCommentId: comment.id,
      value: editText.getCurrentContent().getPlainText(),
      mentionedUsers
    });
    setIsEdited?.([]);
    setEditText(EditorState.createEmpty());
  }, [comment, replies, onReplySubmit, setIsEdited, isEdited, editText]);

  const onReplyClick = React.useCallback(() => {
    setShowReplyForm(!showReplyForm);
  }, [setShowReplyForm, showReplyForm]);
  // scroll to bottom of replies
  useEffect(() => {
    const objDiv = document.getElementById("reply_div");
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, [showReplyForm]);

  const onCancelReply = React.useCallback(() => {
    setReplyText(EditorState.createEmpty());
    setShowReplyForm(!showReplyForm);
  }, [setShowReplyForm, showReplyForm]);

  const isReplyTextEmpty = React.useMemo(() => {
    const contentState = replyText.getCurrentContent();
    return !contentState.hasText();
  }, [replyText]);

  const renderCommentRepliesForm = () => {
    const showThreadLine = replies?.data?.commentModels?.length || showReplyForm;
    return (
      <div className={showThreadLine ? classes.commentWrapper : classes.commentWrapperNoReplies}>
        {!commentIsEdited && comment && (
          <Box className={classes.commentContent}>
            {displayableComment(comment.contentState as RawDraftContentState, comment.value)}
          </Box>
        )}
        <div className={classes.replyContentContainer} id="reply_div">
          {renderCommentReplies(replies.data?.commentModels || [])}
        </div>
        {!showReplyForm && !replyIsEdited && buttonTextReply && (
          <Button variant="text" color="primary" className={classes.replyButton} onClick={onReplyClick}>
            {buttonTextReply}
          </Button>
        )}
        {showReplyForm && (
          <Box className={classes.replyFormContainer}>
            <Avatar aria-label="headerIcon" className={classes.replyFormAvatar}>
              {avatar}
            </Avatar>
            <div className={showReplyForm && classes.replyForm}>
              <div className={classes.mentionTextEditor}>
                <EditorWithMention
                  text={replyText}
                  setText={setReplyText}
                  readOnly={!!readOnly}
                  onSearchChange={onSearchChange}
                  suggestions={suggestions}
                  onAddMention={onAddMention}
                  placeholder={t("common:addReply")}
                />
              </div>
              <div className={classes.marginTopButtons}>
                <Button
                  disabled={isReplyTextEmpty}
                  variant="contained"
                  color="primary"
                  className={classes.buttonLeft}
                  onClick={saveReply}
                >
                  {buttonTextReply}
                </Button>
                <Button variant="outlined" color="primary" className={classes.buttonRight} onClick={onCancelReply}>
                  {buttonTextCancel}
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>
    );
  };

  const onCancelEditReply = React.useCallback(() => {
    setIsEdited?.([]);
    setEditText(EditorState.createEmpty());
  }, [setIsEdited]);

  const isEditTextEmpty = React.useMemo(() => {
    const contentState = editText.getCurrentContent();
    return !contentState.hasText();
  }, [editText]);

  const renderCommentReplies = (comments: CommentModelDB[]) => {
    return comments
      .slice()
      .reverse()
      .map((reply, index) => {
        const replyAvatar = createAvatar?.(reply.creatorUID);
        const repliesEllipsisMenuData = [
          auth?.uid === reply.creatorUID && {
            icon: "EditIcon",
            title: t("editComment"),
            callBackFunction: (replyIndex: number) => {
              if (!setIsEdited || typeof commentIndex !== "number") return;
              setIsEdited?.([commentIndex, replyIndex]);
            }
          },
          (auth?.uid === reply.creatorUID || auth?.permissions.some(it => it.startsWith("pa_approve"))) && {
            icon: "DeleteIcon",
            title: t("deleteComment"),
            callBackFunction: deleteReply
          }
        ].flatMap(it => (it ? [it] : []));
        const isLastReply = !showReplyForm && index === comments.length - 1;
        return (
          <div
            key={index}
            className={`${newlyAddedCommentId === reply.id ? classes.highlightedComment : ""} ${
              isLastReply ? classes.lastReplyOverlay : ""
            }`}
          >
            <CardHeader
              className={classes.repliesCardHeader}
              avatar={
                <Avatar aria-label="headerIcon" className={classes.replyAvatar}>
                  {replyAvatar}
                </Avatar>
              }
              action={
                !isNew ? (
                  <div className={classes.actionWrapper}>
                    {!!repliesEllipsisMenuData?.length && (
                      <EllipsisMenu
                        passedClasses={classes}
                        iconSize={"small"}
                        data={repliesEllipsisMenuData}
                        elementName={questionId}
                        elementId={index}
                        orientationIcons="vertical"
                      />
                    )}
                  </div>
                ) : (
                  ""
                )
              }
              title={<CardTitleComment text={replyHeader(reply.creatorUID)} />}
              subheader={replySubHeader?.(new Date(reply.createdAt))}
            />
            {(!replyIsEdited || isEdited[1] !== index) && (
              <div className={classes.replyContent}>
                {displayableComment(reply.contentState as RawDraftContentState, reply.value)}
              </div>
            )}
            {replyIsEdited && isEdited[1] === index && (
              <div className={showReplyForm ? classes.replyForm : undefined}>
                <div className={classes.mentionTextEditor}>
                  <EditorWithMention
                    text={editText}
                    setText={setEditText}
                    readOnly={!!readOnly}
                    onSearchChange={onSearchChange}
                    suggestions={suggestions}
                    onAddMention={onAddMention}
                  />
                </div>
                <div className={classes.marginTopButtons}>
                  <Button
                    disabled={isEditTextEmpty}
                    variant="contained"
                    color="primary"
                    className={classes.buttonLeft}
                    onClick={editReply}
                  >
                    {buttonTextSave}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonRight}
                    onClick={onCancelEditReply}
                  >
                    {buttonTextCancel}
                  </Button>
                </div>
              </div>
            )}
          </div>
        );
      });
  };

  const handleSaveComment = React.useCallback(
    () => saveComment(questionId, commentText),
    [saveComment, questionId, commentText]
  );

  const isCommentTextEmpty = React.useMemo(() => {
    const contentState = commentText.getCurrentContent();
    return !contentState.hasText();
  }, [commentText]);

  return (
    <Grid container className={classes.cardGrid}>
      <Grid item xs={12}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar aria-label="headerIcon" className={classes.avatar}>
              {avatar}
            </Avatar>
          }
          action={
            !isNew ? (
              <div className={classes.actionWrapper}>
                {comment && !!ellipsisMenuData?.length && (
                  <EllipsisMenu
                    iconSize={"small"}
                    data={ellipsisMenuData}
                    elementName={questionId}
                    elementId={commentIndex}
                    orientationIcons="vertical"
                    passedClasses={classes.ellipsisMenu}
                  />
                )}
              </div>
            ) : (
              ""
            )
          }
          title={<CardTitleComment text={header} />}
          subheader={subHeader}
        />

        <CardContent className={classes.infoCardContent}>
          {!isNew && !commentIsEdited && renderCommentRepliesForm()}
          <div>
            {(isNew || commentIsEdited) && (
              <>
                <div className={classes.mentionTextEditor}>
                  <EditorWithMention
                    text={commentText}
                    setText={setCommentText}
                    readOnly={!!readOnly}
                    onSearchChange={onSearchChange}
                    suggestions={suggestions}
                    onAddMention={onAddMention}
                    placeholder={t("common:addComment")}
                  />
                </div>
                <div className={classes.marginTopButtons}>
                  <Button
                    disabled={isCommentTextEmpty}
                    variant="contained"
                    color="primary"
                    className={classes.buttonLeft}
                    onClick={handleSaveComment}
                  >
                    {buttonTextSave}
                  </Button>
                  <Button variant="outlined" color="primary" className={classes.buttonRight} onClick={cancelComment}>
                    {buttonTextCancel}
                  </Button>
                </div>
              </>
            )}
          </div>
          {commentIsEdited && renderCommentRepliesForm()}
        </CardContent>
      </Grid>
    </Grid>
  );
}

export default Comment;
