import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocMetaView from "components/DocMetaView/DocMetaView";
import Overview from "components/Overview/Overview";
import { COLLECTIONS } from "app/collections";
import { useTranslation } from "react-i18next";
import {
  OVERVIEW_ACTIONS,
  SingleOverviewContext,
  useOverviewDispatch,
  useOverviewState
} from "app/contexts/overview-context";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { OVERVIEW_ADD_TYPE } from "components/Overview/constants/OverviewConstants";
import { useMetaView } from "app/contexts/meta-view-context";
import DateDisplay from "../../../../components/DateDisplay";
import MetaView from "components/MetaView/MetaView";
import { useNavigate } from "react-router-dom";
import { RESOURCE_TYPES } from "app/handlers/resourceHandler";
import { useResources } from "app/contexts/resource-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import { Box, Button } from "@material-ui/core";
import { ExpiringSCCNotice } from "../../../../components/ExpiringSCCNotice";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import { FEATURES } from "app/features";
import { exportExternalRecipientsExcel } from "app/export/createdExcelExportData";
import { ExternalRecipientsOverviewCollectionParams } from "./ExternalRecipientOverviewController";
import { AutomaticUserDataDisplay } from "components/UserDataDisplay";
import { externalRecipientWritePermissions } from "../../../handlers/permissionHandler";

export const ExternalRecipientOverview = ({
  externalRecipientIds,
  hideTitle,
  hideActions,
  setNotConnectedItemsCount,
  onRowLeave,
  onAddOpen,
  onAddClose
}: {
  readonly externalRecipientIds?: string[];
  readonly hideTitle?: boolean;
  readonly hideActions?: boolean;
  readonly setNotConnectedItemsCount?: (count: number) => void;
  readonly onRowLeave?: () => void;
  readonly onAddOpen?: () => void;
  readonly onAddClose?: () => void;
}) => {
  const { t } = useTranslation("service_providers_overview");
  const dispatch = useOverviewDispatch();
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();
  const { setMeta, setInsight } = useMetaView();
  const navigate = useNavigate();

  const { resources } = useResources();
  const { auth } = useAuthentication();

  const onRowOver = useCallback(
    item => {
      const contractTypesTranslated = (item.contractTypes || []).map((contractTypeId: string) => {
        const contractType = (resources[RESOURCE_TYPES.EXTERNAL_RECIPIENT_CONTRACT_TYPE] || []).find(
          (contractItem: { id: string }) => contractItem.id === contractTypeId
        );
        return t(`resources_external-recipient-contract-type:${contractType?.nameKey}`, contractType?.nameKey);
      });

      const dpLocationsTranslated = item.dpLocations.map((location: string) => t("countries:" + location));

      const meta: any = {
        department: item.subTitle,
        created: <DateDisplay timestamp={new Date(item.createdAt)} displaySeconds={undefined} />,
        createdBy: <AutomaticUserDataDisplay uid={item.createdBy} />,
        updated: item.updatedAt ? <DateDisplay timestamp={new Date(item.updatedAt)} displaySeconds={undefined} /> : "-",
        updatedBy: item.updatedBy ? <AutomaticUserDataDisplay uid={item.updatedBy} separator={undefined} /> : "-",
        status: item.approved ? t("filter_criteria:status_approved") : t("filter_criteria:status_edit"),
        // eslint-disable-next-line camelcase
        approved_on: <DateDisplay timestamp={new Date(item.approvedAt)} displaySeconds={undefined} />,
        approvedBy: <AutomaticUserDataDisplay uid={item.approvedBy} separator={undefined} />,
        contractType: contractTypesTranslated,
        countryOfRegistration: item.country ? (
          t("countries:" + item.country)
        ) : (
          <span className="faded">{t("common:not_available")}</span>
        ),
        locationOfProcessing: dpLocationsTranslated,
        processingActivities: item.numberOfProcesses || 0
      };
      if (!item.approved) {
        delete meta.approved_on;
        delete meta.approvedBy;
      }

      setMeta(meta);
    },
    [t, setMeta, resources]
  );

  /* ACTION */
  const onRowClick = useCallback(
    async externalRecipient => {
      if (externalRecipient.id) {
        setMeta(null);
        navigate("/external-recipients/general/" + externalRecipient.id);
        setInsight(null);
        if (externalRecipient.seen === false) {
          await addToSeenItemsOfUserHook(COLLECTIONS.EXTERNAL_RECIPIENTS, externalRecipient.id);
          dispatch({
            type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
            collection: COLLECTIONS.EXTERNAL_RECIPIENTS,
            reloadOverview: Date.now()
          });
        }
      }
    },
    [addToSeenItemsOfUserHook, dispatch, navigate, setInsight, setMeta]
  );

  const toolbarActions = useMemo(
    () => [{ action: "sort" }, { action: "filter" }, { action: "export-xlsx" }, { action: "mark-all-as-read" }],
    []
  );

  const erTemplatesEnabled = useIsFeaturePresent(FEATURES.EDT_TEMPLATES);
  const addActions = useMemo(
    () => [
      { action: OVERVIEW_ADD_TYPE.SINGLE },
      { action: OVERVIEW_ADD_TYPE.MULTIPLE },
      ...(erTemplatesEnabled
        ? [
            {
              action: OVERVIEW_ADD_TYPE.TEMPLATE
            }
          ]
        : [])
    ],
    [erTemplatesEnabled]
  );

  const rowActions = useMemo(() => [{ action: "edit" }, { action: "remove" }].filter(i => i), []);

  const selectionActions = useMemo(() => [{ action: "export-xlsx" }, { action: "remove" }], []);

  const exportToXLSX = useCallback(
    async ids => {
      if (auth?.tenantId) {
        return await exportExternalRecipientsExcel(auth?.tenantId, auth?.uid, t, ids);
      }
    },
    [auth?.tenantId, auth?.uid, t]
  );

  const collectionParams = useMemo<ExternalRecipientsOverviewCollectionParams>(
    () => ({
      setNotConnectedItemsCount,
      exportToXLSX,
      filter: {
        externalRecipientIds
      }
    }),
    [exportToXLSX, setNotConnectedItemsCount, externalRecipientIds]
  );

  const showAddActions = hideActions
    ? false
    : auth?.permissions.find(permission => externalRecipientWritePermissions.includes(permission));

  return (
    <Overview
      selectionActions={hideActions ? emptyArray : selectionActions}
      collectionParams={collectionParams}
      onRowClick={onRowClick}
      rowActions={hideActions ? emptyArray : rowActions}
      toolbarActions={hideActions ? emptyArray : toolbarActions}
      addActions={showAddActions ? addActions : undefined}
      header={hideTitle ? undefined : t("list_service_providers")}
      subHeader={
        hideActions ? undefined : (
          <ExpiringSCCNotice
            expiringSCCKey={"scc"}
            warningText={t("expiringSCC:scc")}
            toCheckExternalRecipientIds={undefined}
          />
        )
      }
      dnd={false}
      collection={COLLECTIONS.EXTERNAL_RECIPIENTS}
      paginated={true}
      onRowOver={onRowOver}
      onRowLeave={onRowLeave}
      onAddOpen={onAddOpen}
      onAddClose={onAddClose}
      checkable={!hideActions}
      toolbarMode={"tabs"}
      showSelectAll={hideActions ? false : true}
    />
  );
};

const emptyArray: any[] = [];

export const ExternalRecipientOverviewPage = () => {
  const { t } = useTranslation("service_providers_overview");
  const dispatch = useOverviewDispatch();
  const { setInfo, setMeta, setInsight, meta } = useMetaView();
  const [notConnectedItemsCount, setNotConnectedItemsCount] = useState<number>(0);

  const overviewSetup: SingleOverviewContext = useOverviewState()[COLLECTIONS.EXTERNAL_RECIPIENTS];
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const filter = overviewSetup.filter?.selectedInProcess;

  const erInsightsDataEnabled = useIsFeaturePresent(FEATURES.EXTERNAL_RECIPIENTS_INSIGHT_DATA);

  useEffect(() => {
    if (erInsightsDataEnabled && filter && filter[0] === "false") {
      setButtonDisabled(true);
    }
    if (erInsightsDataEnabled && !filter) {
      setButtonDisabled(false);
    }
  }, [filter, erInsightsDataEnabled, overviewSetup]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("service_providers_overview:titleInformation"),
        text: t("service_providers_overview:textInformation")
      },
      creating: {
        title: t("service_providers_overview:titleNewSP"),
        text: t("service_providers_overview:textNewSP")
      }
    }),
    [t]
  );

  const insightCard = useMemo(
    () => ({
      title: t("service_providers_overview:insight_title"),
      text: t("service_providers_overview:insight_description", {
        number: notConnectedItemsCount
      })
    }),
    [notConnectedItemsCount, t]
  );

  useEffect(() => {
    if (erInsightsDataEnabled && notConnectedItemsCount > 0) {
      setInsight(insightCard);
      // clean up if the page is unmounted
      return () => setInsight(null);
    } else {
      setInsight(null);
    }
  }, [insightCard, erInsightsDataEnabled, notConnectedItemsCount, setInsight]);

  const toggleFilterPANotSelected = useCallback(() => {
    const currentSelectedInProcessFilter = overviewSetup.filter.selectedInProcess;
    const filter = currentSelectedInProcessFilter && currentSelectedInProcessFilter.includes("false") ? [] : ["false"];
    dispatch({
      type: OVERVIEW_ACTIONS.SET_FILTER,
      collection: COLLECTIONS.EXTERNAL_RECIPIENTS,
      filter: { selectedInProcess: filter }
    });
    dispatch({
      type: OVERVIEW_ACTIONS.RELOAD_OVERVIEW,
      collection: COLLECTIONS.EXTERNAL_RECIPIENTS,
      reloadOverview: Date.now()
    });
  }, [dispatch, overviewSetup.filter.selectedInProcess]);

  useEffect(() => {
    setMeta(null);
    setInfo(infoCard.entering);
  }, [infoCard.entering, insightCard, setInfo, setInsight, setMeta]);

  const showEnterInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard.entering);
  }, [infoCard.entering, setInfo, setMeta]);
  const showCreateInfo = useCallback(() => {
    setMeta(null);
    setInfo(infoCard?.creating);
  }, [infoCard?.creating, setInfo, setMeta]);

  return (
    <DocMetaView
      metaViewContent={
        <MetaView translationKey={"service_providers_overview"}>
          {erInsightsDataEnabled && !meta && notConnectedItemsCount > 0 && (
            <Box mt={3} mx={3} display="flex" justifyContent="center" alignItems="center">
              <Button variant="outlined" onClick={toggleFilterPANotSelected} disabled={buttonDisabled}>
                {t("meta_button_filter")}
              </Button>
            </Box>
          )}
        </MetaView>
      }
    >
      <ExternalRecipientOverview
        setNotConnectedItemsCount={setNotConnectedItemsCount}
        onRowLeave={showEnterInfo}
        onAddClose={showCreateInfo}
        onAddOpen={showEnterInfo}
      />
    </DocMetaView>
  );
};
