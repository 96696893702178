import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocView from "../../../components/DocView/DocView";
import DocMetaView from "../../../components/DocMetaView/DocMetaView";
import MetaView, { META_VIEW_TABS } from "../../../components/MetaView/MetaView";
import { useMetaView } from "../../contexts/meta-view-context";
import QuestionnaireSubHeader from "../../../components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { RiskPageButtons, RiskPageStepper } from "./RiskPagination";
import { useRisk } from "../../contexts/risk-context";
import { useErrorSnackbar } from "../../../hook/errorSnackbar";
import { RISK_TREATMENT_TYPES } from "../../handlers/risksHandler";
import { AttachmentsOverviewOBS } from "../shared/Attachments/AttachmentsOverviewOBS";
import TextEditor from "../questionnaires/utils/TextEditor";
import { debounce } from "lodash-es";
import Question from "components/Question/Question";
import { COLLECTIONS } from "../../collections";
import { QUESTION_TYPE } from "../../../components/Question/QuestionTypes";
import useSWR from "swr";
import { getRiskTOMsApi, TOMOptionDTO } from "../../api/riskApi";
import { MeasuresQuestionAdditionalData } from "../../../components/Question/types/resources/MeasuresQuestion";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";

const metaViewTabIds = [META_VIEW_TABS.ASSISTANT, META_VIEW_TABS.TODOS, META_VIEW_TABS.COMMENTS];

export const RiskTreatmentPage = () => {
  const { t } = useTranslation("risk_treatment_page");
  const {
    risk: { title: riskTitle },
    riskId
  } = useRisk();

  return (
    <DocMetaView
      metaViewContent={
        <MetaView
          translationKey={"risk_treatment_page"}
          docName={riskTitle}
          docId={riskId}
          collection={COLLECTIONS.RISK}
          tabs={metaViewTabIds}
        />
      }
    >
      <DocView header={riskTitle} pagination={<RiskPageStepper />}>
        <Box mt={6}>
          <QuestionnaireSubHeader text={t("subTitle")} />
        </Box>
        <RiskTreatment />
        <RiskPageButtons />
      </DocView>
    </DocMetaView>
  );
};

export const RiskTreatment = () => {
  const { t } = useTranslation("risk_treatment_page");

  const { initialized: riskInitialized, risk, updateTreatmentHook, riskId } = useRisk();
  const { setInfo, setMeta } = useMetaView();
  const { catchAsSnackbar } = useErrorSnackbar();

  const [loaded, setLoaded] = useState(false);
  const [focusedElement, setFocusedElement] = useState("");
  const [treatmentType, setTreatmentType] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!riskInitialized || loaded) {
      // do not update from remote after initial update
      return;
    }
    setTreatmentType(risk?.treatment?.type || "");
    setDescription(risk?.treatment?.description || "");

    setLoaded(true);
  }, [riskInitialized, risk?.treatment?.type, risk?.treatment?.description, risk?.treatment?.measureIds, loaded]);

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("risk_treatment_page:enteringInfoCardTitle"),
        text: t("risk_treatment_page:enteringInfoCardText")
      },
      measures: {
        title: t("risk_treatment_page:measuresTitle"),
        text: t("risk_treatment_page:measuresCard")
      },
      transfer: {
        title: t("risk_treatment_page:transferTitle"),
        text: t("risk_treatment_page:transferCard")
      },
      prevention: {
        title: t("risk_treatment_page:preventionTitle"),
        text: t("risk_treatment_page:preventionCard")
      },
      accept: {
        title: t("risk_treatment_page:acceptTitle"),
        text: t("risk_treatment_page:acceptCard")
      }
    }),
    [t]
  );

  useEffect(() => {
    return setInfo((infoCard as any)[treatmentType || "entering"]);
  }, [setInfo, infoCard, treatmentType]);

  const debouncedUpdateTreatmentTypeHook = useMemo(() => {
    return debounce(type => {
      return updateTreatmentHook({ type: type }).catch(catchAsSnackbar("failed to update treatment type"));
    }, 600);
  }, [updateTreatmentHook, catchAsSnackbar]);

  const onTreatmentTypeChange = useCallback(
    event => {
      const newValue = event.target.value;
      setTreatmentType(newValue);
      debouncedUpdateTreatmentTypeHook(newValue);
    },
    [debouncedUpdateTreatmentTypeHook]
  );

  const [shouldShowDescription, setShouldShowDescription] = useState(false);
  useEffect(() => {
    switch (treatmentType) {
      case RISK_TREATMENT_TYPES.measures:
        setShouldShowDescription(false);
        break;
      default:
        setShouldShowDescription(true);
    }
  }, [treatmentType]);

  const [shouldShowMeasures, setShouldShowMeasures] = useState(false);
  useEffect(() => {
    switch (treatmentType) {
      case RISK_TREATMENT_TYPES.measures:
        setShouldShowMeasures(true);
        break;
      default:
        setShouldShowMeasures(false);
    }
  }, [treatmentType]);

  const onDescriptionBlur = useCallback(() => {
    updateTreatmentHook({ description }).catch(catchAsSnackbar("failed to update description"));
  }, [updateTreatmentHook, description, catchAsSnackbar]);

  const onDescriptionFocus = useCallback(() => {
    setFocusedElement("description");
  }, []);

  const disabled = useMemo(() => risk?.permission !== "write", [risk?.permission]);

  return (
    <Box mt={3}>
      <RadioGroup name="treatmentType" value={treatmentType} onChange={onTreatmentTypeChange}>
        {Object.values(RISK_TREATMENT_TYPES).map(treatmentType => (
          <FormControlLabel
            key={treatmentType}
            label={t(treatmentType)}
            value={treatmentType}
            disabled={disabled}
            control={<Radio color="primary" />}
          />
        ))}
      </RadioGroup>

      {shouldShowMeasures && <TreatmentMeasureIDs />}
      {shouldShowDescription &&
        loaded && ( // text edit input value is not controlled, have to prevent render before description have value
          <Box mt={2}>
            <Question
              translatable={description}
              translationId={"description"}
              title={t("description")}
              questionId={"description"}
              questionName={t("description")}
            >
              <TextEditor
                onFocus={onDescriptionFocus}
                onBlur={onDescriptionBlur}
                onChange={setDescription}
                inputValue={description}
                toolbarHidden={focusedElement !== "description"}
                disabled={disabled}
              />
            </Question>
          </Box>
        )}
      <QuestionnaireSubHeader text={t("common:attachments")} />
      <AttachmentsOverviewOBS docId={`${riskId}-treatment`} category={"risks"} setMeta={setMeta} disabled={disabled} />
    </Box>
  );
};

const TreatmentMeasureIDs = () => {
  const { t } = useTranslation("risk_first_assessment_page");

  const { risk, updateTreatmentHook, riskId } = useRisk();
  const { catchAsSnackbar } = useErrorSnackbar();

  const { data, mutate } = useSWR(riskId ? ["risks", riskId, "toms"] : null, () => getRiskTOMsApi(riskId));

  const updateTreatmentMeasureIds = useCallback(
    measureIds => {
      return updateTreatmentHook({ measureIds })
        .then(() => mutate())
        .catch(catchAsSnackbar("failed to update measures"));
    },
    [updateTreatmentHook, catchAsSnackbar, mutate]
  );

  const additionalData = useMemo(() => {
    return {
      protectionObjectiveIds: risk?.protectionObjectiveIds || [],
      implementedTOMIds: risk?.implementedMeasureIds || []
    } satisfies MeasuresQuestionAdditionalData;
  }, [risk?.implementedMeasureIds, risk?.protectionObjectiveIds]);

  const disabled = useMemo(() => risk?.permission !== "write", [risk?.permission]);
  const emptyProtectionObjectiveIds = !risk?.protectionObjectiveIds.length;

  return (
    <>
      {emptyProtectionObjectiveIds ? (
        <Box mt={2}>
          <CustomAlert severity="info">{t("risk_first_assessment_page:selectProtectionObjectives")}</CustomAlert>
        </Box>
      ) : (
        <></>
      )}
      <Question
        qType={QUESTION_TYPE.MEASURE}
        value={risk.treatment?.measureIds}
        onChange={updateTreatmentMeasureIds}
        options={data?.toms || emptyToms}
        nonStandardAdditionalData={additionalData}
        disabled={disabled || emptyProtectionObjectiveIds}
        allowAdd={true}
      />
    </>
  );
};
const emptyToms: TOMOptionDTO[] = [];
