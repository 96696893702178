import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAuthentication } from "../handlers/authentication/authentication-context";
import { useTranslation } from "react-i18next";
import {
  createNewResource,
  getAllResources,
  RESOURCE_TYPE,
  translateResource,
  translateResourceById,
  traverseResourceById
} from "../handlers/resourceHandler";
import { ResourceDTO } from "app/api/resourceApi";
import { useUserAndTenantData } from "../handlers/userAndTenant/user-tenant-context";
import useSWR from "swr";
import { SWR_KEYS } from "app/swrKeys";

export interface ResourceContextValue {
  readonly resourcesLoaded: boolean;
  readonly resources: Record<string, ResourceDTO[]>;
  readonly resourcesWithMergedAndDisabled: Record<string, ResourceDTO[]>;
  readonly translate: (resourceType: RESOURCE_TYPE, nameKey: string) => string;
  readonly translateById: (resourceType: RESOURCE_TYPE, id: string) => string;
  readonly traverseMergedId: (resourceType: RESOURCE_TYPE, id: string) => string | null;
  readonly create: (resourceType: RESOURCE_TYPE, nameKey: string) => Promise<string>;
  readonly isDefaultResourceOfNameKey: (resourceType: RESOURCE_TYPE, id: string, expectedNameKey: string) => boolean;
  readonly refreshResources: () => Promise<void>;
}

export const ResourcesContext = createContext<ResourceContextValue>({
  resourcesLoaded: false,
  resources: {},
  resourcesWithMergedAndDisabled: {},
  translate: () => "",
  translateById: () => "",
  traverseMergedId: () => "",
  create: () => Promise.reject(),
  isDefaultResourceOfNameKey: () => false,
  refreshResources: () => Promise.reject()
});

export const ResourceProvider = ({ children }: { readonly children?: React.ReactNode }) => {
  const { auth } = useAuthentication();
  const { t } = useTranslation("");
  const { tenantData } = useUserAndTenantData();

  const [resources, setResources] = useState<Record<string, ResourceDTO[]>>({});
  const [resourcesWithMergedAndDisabled, setResourcesWithMergedAndDisabled] = useState<Record<string, ResourceDTO[]>>(
    {}
  );
  const [effectiveResourceAccess, setEffectiveResourceAccess] = useState<Map<string, Map<string, ResourceDTO>>>(
    new Map()
  );

  const { data, isValidating, isLoading, mutate } = useSWR(SWR_KEYS.ResourceProvider, () =>
    getAllResources({ showMerged: true, showAllSetsIgnoringTenantFeatures: true })
  );

  useEffect(() => {
    if (!(auth && data && !isValidating && !isLoading)) {
      return;
    }

    const resourcesWithoutMergedAndDisabled: Record<string, ResourceDTO[]> = {};
    const effectiveResourceAccess = new Map<string, Map<string, ResourceDTO>>();
    for (const [resourceType, resources] of Object.entries(data)) {
      const existingResourceTypeMap: Map<string, ResourceDTO> =
        effectiveResourceAccess.get(resourceType) || new Map<string, ResourceDTO>();
      for (const resource of resources) {
        existingResourceTypeMap.set(resource.id, resource);
        if (!resource.mergedIntoId) {
          resourcesWithoutMergedAndDisabled[resourceType] = resourcesWithoutMergedAndDisabled[resourceType] || [];
          resourcesWithoutMergedAndDisabled[resourceType].push(resource);
        }
      }
      effectiveResourceAccess.set(resourceType, existingResourceTypeMap);
    }

    setResourcesWithMergedAndDisabled(data);
    setResources(resourcesWithoutMergedAndDisabled);
    setEffectiveResourceAccess(effectiveResourceAccess);
  }, [auth, data, isLoading, isValidating, tenantData?.features]);

  const { loadSeenItemsOfUserHook } = useUserAndTenantData();

  const translateCallback = useCallback(
    (resourceType: RESOURCE_TYPE, nameKey: string) => {
      return translateResource({ t, resourceType, nameKey });
    },
    [t]
  );

  const translateByIdCallback = useCallback(
    (resourceType: RESOURCE_TYPE, resourceId: string) => {
      return translateResourceById({ t, resourceType, resourceId, resources: effectiveResourceAccess });
    },
    [effectiveResourceAccess, t]
  );

  const createCallback = useCallback(
    async (resourceType: RESOURCE_TYPE, nameKey: string) => {
      const createdResourceId = await createNewResource({ resourceType, nameKey });
      await loadSeenItemsOfUserHook();
      await mutate();
      return createdResourceId;
    },
    [loadSeenItemsOfUserHook, mutate]
  );

  const traverseMergedIdCallback = useCallback(
    (resourceType: RESOURCE_TYPE, id: string): string | null => {
      return (
        traverseResourceById({
          resourceType,
          resourceId: id,
          resourcesWithMerged: effectiveResourceAccess
        })?.id || null
      );
    },
    [effectiveResourceAccess]
  );

  const isDefaultResourceOfNameKey = useCallback(
    (resourceType: RESOURCE_TYPE, id: string, expectedNameKey: string): boolean => {
      const resource = effectiveResourceAccess.get(resourceType)?.get(id);
      return !!(resource && resource.defaultResource && resource.nameKey === expectedNameKey);
    },
    [effectiveResourceAccess]
  );

  const refreshResources = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return (
    <ResourcesContext.Provider
      value={{
        resourcesLoaded: !isLoading && !isValidating,
        resources,
        resourcesWithMergedAndDisabled,
        translate: translateCallback,
        translateById: translateByIdCallback,
        traverseMergedId: traverseMergedIdCallback,
        create: createCallback,
        isDefaultResourceOfNameKey,
        refreshResources
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};
export const useResources = () => useContext(ResourcesContext);
