import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AuditDetailDTO, getAuditDetail } from "app/api/auditApi";
import { useTranslation } from "react-i18next";
import { useMetaView } from "app/contexts/meta-view-context";
import { AUDIT_METHODOLOGY, AUDIT_METHODOLOGY_TYPES } from "../../audit/AuditTypes";
import { CircularProgress } from "@material-ui/core";
import { COLLECTIONS } from "../../../../collections";
import { AssessmentAnswerSet, postAnswerSetApi, postAnswerSetExternalUserAPi } from "app/api/assessmentApi";
import { OVERVIEW_ACTIONS, useOverviewDispatch } from "app/contexts/overview-context";
import AssessmentResponsesOverview from "../overview/AssessmentResponsesOverview";
import { Box } from "@mui/material";
import ParticipantViewer from "../viewer/ParticipantViewer";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { AddAnswerSetDialog } from "../dialog/AddAnswerSetDialog";
import { ExternalUserDTO } from "app/api/user/userApi";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { isAxiosErrorWithCode } from "app/api/axios/axiosErrorHandler";
import { sendNotificationApi } from "app/api/userNotificationApi";

const sx = {
  root: {}
};

interface AssessmentResponsesProps {
  readonly auditId: string;
  readonly auditMethodology?: AUDIT_METHODOLOGY_TYPES;
  readonly onClickNext: () => void;
}
export const AssessmentResponses = ({ auditId, auditMethodology, onClickNext }: AssessmentResponsesProps) => {
  const { t } = useTranslation("audit_details");
  const { setInfo } = useMetaView();
  const dispatch = useOverviewDispatch();
  const { reloadTenantUsers } = useUserAndTenantData();

  const [auditData, setAuditData] = useState<Partial<AuditDetailDTO> | null>(null);
  const [openAddAnswerSetDialog, setOpenAddAnswerDialog] = useState<boolean>(false);
  const [openParticipantModalDialog, setOpenParticipantModalDialog] = useState<boolean>(false);
  const [clickedAnswerSetId, setClickedAnswerSetId] = useState<string>("");
  const [allAssessmentResponseIds, setAllAssessmentResponseIds] = useState<string[]>([]);
  const [addExternalUserErrors, setAddExternalUserErrors] = useState<Record<string, string>>({});

  const reloadOverview = useCallback(async () => {
    const reload = {
      shadowLoading: true,
      reloadOverview: Date.now(),
      reloadMetaview: Date.now()
    };
    dispatch({ type: OVERVIEW_ACTIONS.RELOAD, collection: COLLECTIONS.ASSESSMENT_RESPONSE, reload });
  }, [dispatch]);

  useEffect(() => {
    getAuditDetail({ id: auditId }).then(data => {
      setAuditData(data);
    });
  }, [auditId]);

  /* TODO: SHOULD BE 
    auditData?.pagesWithWrite?.includes?.("response")
  */
  const writePermission = useMemo(
    () => auditData?.pagesWithWrite?.includes?.("questionnaire"),
    [auditData?.pagesWithWrite]
  );

  const infoCard: Record<string, { readonly title: string; readonly text: string }> = useMemo(
    () => ({
      default: {
        title: t("enteringInfoCardTitle"),
        text: t(`auditScopeInfoCardContent`)
      },
      [AUDIT_METHODOLOGY.INTERVIEW]: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringQuestionnareInterviewInfoCardContent")
      },
      [AUDIT_METHODOLOGY.SELF_ASSESSMENT]: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringQuestionnareSelfAssessmentInfoCardContent")
      }
    }),
    [t]
  );

  useEffect(() => {
    setInfo(infoCard[auditData?.methodology || "default"]);
  }, [auditData?.methodology, infoCard, setInfo]);

  /* ADD ANSWER SET */
  const onConfirmAddInternalAnswerSet = useCallback(
    async (payload: AssessmentAnswerSet) => {
      setAddExternalUserErrors({});
      try {
        const createdAnswerSetId = await postAnswerSetApi({ auditId: auditData?.id || "", payload });
        if (auditData?.methodology !== AUDIT_METHODOLOGY.INTERVIEW) {
          const notificationObj = {
            title: "audit_assignment",
            receivers: [payload.assigneeUID],
            pageId: "general",
            collection: COLLECTIONS.ASSESSMENT_RESPONSE,
            docId: `${auditData?.id}/answerset/${createdAnswerSetId}`, // this for generic link notification email
            docName: auditData?.title || "",
            message: ""
          };
          await sendNotificationApi(notificationObj);
        }
        setOpenAddAnswerDialog(false);
        reloadOverview();
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [auditData, reloadOverview, t]
  );

  const onConfirmAddExternalAnswerSet = useCallback(
    async (payload: ExternalUserDTO) => {
      setAddExternalUserErrors({});
      try {
        await postAnswerSetExternalUserAPi({ auditId, payload });
        await reloadTenantUsers();
        setOpenAddAnswerDialog(false);
        reloadOverview();
      } catch (e) {
        if (isAxiosErrorWithCode(e, 409)) {
          setAddExternalUserErrors(current => ({ ...current, email: t("common:duplicateEmail") }));
        }
      }
    },
    [auditId, reloadOverview, reloadTenantUsers, t]
  );

  /* ADD ANSWER SET */
  const onShowParticipantAddDialog = useCallback(() => {
    setOpenAddAnswerDialog(true);
  }, []);
  const onCloseParticipantAddDialog = useCallback(() => {
    setOpenAddAnswerDialog(false);
    setAddExternalUserErrors({});
  }, []);

  const onShowParticipantModal = useCallback((item: OverviewItem, _allAssessmentResponseIds: string[]) => {
    setOpenParticipantModalDialog(true);
    setClickedAnswerSetId(item.id);
    setAllAssessmentResponseIds(_allAssessmentResponseIds);
  }, []);
  const onCloseParticipantModal = useCallback(() => {
    setOpenParticipantModalDialog(false);
    reloadOverview();
  }, [reloadOverview]);

  const onSubmitInterview = useCallback(() => {
    setOpenParticipantModalDialog(false);
    reloadOverview();
  }, [reloadOverview]);

  if (!auditData) {
    return <CircularProgress />;
  }

  return (
    <Box sx={sx.root}>
      <AssessmentResponsesOverview
        auditId={auditId}
        writePermission={Boolean(writePermission)}
        enterInfo={infoCard[auditData?.methodology || "default"]}
        onNext={onClickNext}
        onRowClick={onShowParticipantModal}
        onAddAnswerSet={onShowParticipantAddDialog}
      />
      <AddAnswerSetDialog
        auditMethodology={auditMethodology}
        open={openAddAnswerSetDialog}
        auditOrgUnitIds={[auditData?.mainOrgUnitId || "", ...(auditData?.associatedOrgUnitIds || [])]}
        errors={addExternalUserErrors}
        onCancel={onCloseParticipantAddDialog}
        onConfirmInternal={onConfirmAddInternalAnswerSet}
        onConfirmExternal={onConfirmAddExternalAnswerSet}
      />
      <ParticipantViewer
        open={openParticipantModalDialog}
        auditTitle={auditData?.title || ""}
        auditId={auditData?.id || ""}
        auditMethodology={auditData.methodology as AUDIT_METHODOLOGY_TYPES}
        currentParticipantId={clickedAnswerSetId}
        currentParticipantIds={allAssessmentResponseIds}
        onClose={onCloseParticipantModal}
        onSubmit={onSubmitInterview}
        writePermission={Boolean(writePermission)}
      />
    </Box>
  );
};
