import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuestionProps } from "../../Question";
import { useDataLocations } from "hook/useDataLocations";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { getDataLocationWithExternalRecipient } from "app/utils/getDataLocationWithExternalRecipient";
import { useTranslation } from "react-i18next";
import { removeAddText } from "app/pages/questionnaires/utils/helper-functions";
import { useSnackbar } from "notistack";
import { createDataLocationApi } from "app/api/externalRecipientApi";
import { useAuthentication } from "../../../../app/handlers/authentication/authentication-context";

const DataSourceDataStorageQuestion = ({
  value,
  disabled = false,
  questionName,
  error,
  helperText,
  onFocus,
  onBlur,
  onChange
}: QuestionProps) => {
  const { t } = useTranslation("questionnaires");
  const { t: tErrorMessages } = useTranslation("error_messages");
  const { enqueueSnackbar } = useSnackbar();
  const { dataLocations, dataLocationsLoaded, dataLocationsReload } = useDataLocations();
  const dataLocationsMap = useMemo(
    () => new Map(dataLocations.map(dataLocation => [dataLocation.id, dataLocation])),
    [dataLocations]
  );
  const optionIds = useMemo(() => dataLocations.map(it => it.id), [dataLocations]);

  const [currentValue, setCurrentValue] = useState<string[]>([]);

  useEffect(() => {
    const initialValue: string[] = (Array.isArray(value) ? value : [value]) as string[];
    return setCurrentValue(initialValue);
  }, [value]);

  const onChangeCallback = useCallback(
    ids => {
      onChange?.(ids);
    },
    [onChange]
  );
  const onAddCallback = useCallback(
    async options => {
      // data sources and data storages lists are identical
      const allExistDataLocationNames = dataLocations.map(({ nameKey }: { nameKey: string }) => nameKey.trim());

      // the last option is the newly added option
      const newDataLocationName = removeAddText(options[options.length - 1], t("add_pg")).trim();

      if (allExistDataLocationNames.includes(newDataLocationName)) {
        enqueueSnackbar(tErrorMessages("data_location_already_exists"), { variant: "error" });
        return;
      }

      const id = await createDataLocationApi({ nameKey: newDataLocationName }, { params: { wait: true } });
      if (id) {
        await dataLocationsReload();
        onChange?.([...currentValue, id]);
      }
    },
    [dataLocations, t, enqueueSnackbar, tErrorMessages, dataLocationsReload, onChange, currentValue]
  );
  const getOptionLabelCallback = useCallback(
    optionId => {
      return getDataLocationWithExternalRecipient(optionId, dataLocationsMap, t);
    },
    [dataLocationsMap, t]
  );

  const newOptionEntryAlreadyExistsCallback = useCallback(
    (newName: string) => {
      return Boolean(dataLocations.find(dataLocation => dataLocation.nameKey === newName));
    },
    [dataLocations]
  );

  const { auth } = useAuthentication();
  const userHasExternalRecipientWritePermission = auth?.permissions?.some(
    permission => permission === "er_write_org" || permission === "er_write_all"
  );

  return (
    <MultiAutocomplete<string, true, false, false>
      onFocus={onFocus}
      onBlur={onBlur}
      options={optionIds}
      selected={currentValue}
      updateSelected={onChangeCallback}
      updateOptions={onAddCallback}
      getOptionLabel={getOptionLabelCallback}
      newOptionEntryAlreadyExists={newOptionEntryAlreadyExistsCallback}
      hasMultiSelect={true}
      addText={userHasExternalRecipientWritePermission ? t("add_pg") : undefined}
      placeholder={t("dataCategory:search")}
      label={questionName || t("pa_recipients:inputLabel")}
      disableClearable={false}
      disabled={disabled}
      error={error}
      helperText={helperText}
    />
  );
};

export default DataSourceDataStorageQuestion;
