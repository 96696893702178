import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Question from "components/Question/Question";
import { QUESTION_TYPE } from "components/Question/QuestionTypes";
import HouseIcon from "@mui/icons-material/House";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { ANSWERSET_TYPE, AssessmentAnswerSet } from "app/api/assessmentApi";
import { ExternalUserDTO } from "app/api/user/userApi";
import { CircularProgress, useTheme } from "@mui/material";
import { isEmailInCorrectFormat } from "app/utils/emailFormatter";
import { AUDIT_METHODOLOGY, AUDIT_METHODOLOGY_TYPES } from "../../audit/AuditTypes";

export interface AddAnswerSetDialogProps {
  readonly auditMethodology?: AUDIT_METHODOLOGY_TYPES;
  readonly open: boolean;
  readonly auditOrgUnitIds: string[];
  readonly errors?: Record<string, string>;
  readonly onCancel?: () => void;
  readonly onConfirmInternal: (answerSet: AssessmentAnswerSet) => Promise<void>;
  readonly onConfirmExternal: (externalUser: ExternalUserDTO) => Promise<void>;
}

export const AddAnswerSetDialog = ({
  auditMethodology,
  open,
  auditOrgUnitIds,
  errors,
  onCancel,
  onConfirmInternal,
  onConfirmExternal
}: AddAnswerSetDialogProps) => {
  const theme = useTheme();
  const sx = {
    loading: {
      color: theme.palette.grey[500],
      marginRight: 1,
      width: "14px",
      height: "14px"
    }
  };

  const { t } = useTranslation("add_participant");

  const { getUserNameHook } = useUserAndTenantData();

  const [externalUser, setExternalUser] = useState<ExternalUserDTO>({
    firstName: "",
    lastName: "",
    email: "",
    inviteMessage: "",
    companyName: ""
  });
  const [answerSet, setAnswerSet] = useState<AssessmentAnswerSet>({
    type: ANSWERSET_TYPE.INTERNAL,
    title: "",
    assigneeUID: ""
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const clearCallback = useCallback(() => {
    setAnswerSet({ type: ANSWERSET_TYPE.INTERNAL, title: "", assigneeUID: "" });
    setExternalUser({
      firstName: "",
      lastName: "",
      email: "",
      inviteMessage: "",
      companyName: ""
    });
    setIsValid(false);
  }, []);

  useEffect(() => {
    if (answerSet.type === ANSWERSET_TYPE.INTERNAL) {
      setIsValid(!!answerSet.assigneeUID);
    } else if (answerSet.type === ANSWERSET_TYPE.EXTERNAl) {
      setIsValid(
        externalUser.firstName?.trim() !== "" &&
          externalUser.lastName?.trim() !== "" &&
          externalUser.email?.trim() !== "" &&
          !!isEmailInCorrectFormat(externalUser.email) &&
          externalUser.companyName?.trim() !== ""
      );
    } else {
      setIsValid(false);
    }
  }, [
    answerSet.assigneeUID,
    answerSet.orgUnitId,
    answerSet.type,
    externalUser.companyName,
    externalUser.email,
    externalUser.firstName,
    externalUser.lastName
  ]);

  useEffect(() => {
    if (open) {
      clearCallback();
      setInProgress(false);
    }
  }, [clearCallback, open]);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setInProgress(false);
    }
  }, [errors]);

  const onUpdateAnswerSetCallback = useCallback((value, valueName: string | undefined) => {
    if (valueName !== undefined) {
      setAnswerSet(current =>
        current ? { ...current, [valueName]: Array.isArray(value) ? value[0] : value } : current
      );
    }
  }, []);

  const onUpdateAnswerSetOrgUnitCallback = useCallback((value, valueName: string | undefined) => {
    if (valueName !== undefined) {
      setAnswerSet(current =>
        current ? { ...current, [valueName]: Array.isArray(value) ? value[0] : value, ["assigneeUID"]: "" } : current
      );
    }
  }, []);

  const onUpdateExternalUserCallback = useCallback((value, valueName: string | undefined) => {
    if (valueName !== undefined) {
      setExternalUser(current =>
        current ? { ...current, [valueName]: Array.isArray(value) ? value[0] : value } : current
      );
    }
  }, []);

  const onConfirmCallback = useCallback(() => {
    if (!answerSet) {
      clearCallback();
      return;
    }

    // for External anwers set we have to create a new user
    if (answerSet?.type === ANSWERSET_TYPE.EXTERNAl) {
      setInProgress(true);
      onConfirmExternal(externalUser);
    } else {
      onConfirmInternal({
        ...answerSet,
        title: getUserNameHook(answerSet.assigneeUID),
        participantsUIDs: [],
        status: "pending"
      });
    }
  }, [answerSet, clearCallback, externalUser, getUserNameHook, onConfirmExternal, onConfirmInternal]);

  const onCancelCallback = useCallback(() => {
    clearCallback();
    onCancel?.();
  }, [clearCallback, onCancel]);

  /* INTERNAL ANSWERSET */
  const internalAnswerSetEl = useMemo(() => {
    if (answerSet?.type === ANSWERSET_TYPE.INTERNAL) {
      const { orgUnitId, assigneeUID } = answerSet;
      return (
        <>
          <Question
            qType={QUESTION_TYPE.AFFECTED_ORG_UNITS}
            value={orgUnitId}
            valueName={"orgUnitId"}
            onChange={onUpdateAnswerSetOrgUnitCallback}
            questionName={t("internal_participant_org_unit")}
            textFieldIcon={<HouseIcon color="primary" />}
            orgUnitIds={auditOrgUnitIds}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.ASSIGNED_TO_USER}
            value={assigneeUID || ""}
            valueName={"assigneeUID"}
            onChange={onUpdateAnswerSetCallback}
            questionName={t("internal_participant_user")}
            textFieldIcon={<AssignmentIndIcon color="primary" />}
            orgUnitIds={orgUnitId ? [orgUnitId] : [auditOrgUnitIds[0]]}
            userDepartmentBound={true}
            error={Boolean((errors && errors["email"]) || false)}
            helperText={(errors && errors["email"]) || ""}
            pb={0}
          />
        </>
      );
    }
    return <></>;
  }, [answerSet, onUpdateAnswerSetCallback, t, auditOrgUnitIds, errors, onUpdateAnswerSetOrgUnitCallback]);

  /* EXTERNAL USER */
  const externalUserEl = useMemo(() => {
    if (answerSet?.type === ANSWERSET_TYPE.EXTERNAl) {
      return (
        <>
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.firstName}
            valueName={"firstName"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:first_name")}
            error={externalUser?.firstName?.trim() === ""}
            helperText={externalUser?.firstName?.trim() === "" ? t("fields:required") : ""}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.lastName}
            valueName={"lastName"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:last_name")}
            error={externalUser?.lastName?.trim() === ""}
            helperText={externalUser?.lastName?.trim() === "" ? t("fields:required") : ""}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.EMAIL}
            value={externalUser?.email}
            valueName={"email"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("fields:email")}
            error={Boolean((errors && errors["email"]) || externalUser?.email?.trim() === "")}
            helperText={(errors && errors["email"]) || externalUser?.email?.trim() === "" ? t("fields:required") : ""}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.companyName}
            valueName={"companyName"}
            onChange={onUpdateExternalUserCallback}
            questionName={t("common:companyName")}
            error={externalUser?.companyName?.trim() === ""}
            helperText={externalUser?.companyName?.trim() === "" ? t("fields:required") : ""}
            pt={3}
            pb={0}
          />
          <Question
            qType={QUESTION_TYPE.TEXT_AREA}
            value={externalUser?.inviteMessage || ""}
            valueName={"inviteMessage"}
            onChange={onUpdateExternalUserCallback}
            questionName={"Invite message"}
            pt={6}
            pb={0}
          />
        </>
      );
    }
    return <></>;
  }, [
    answerSet?.type,
    errors,
    externalUser?.companyName,
    externalUser?.email,
    externalUser?.firstName,
    externalUser?.inviteMessage,
    externalUser?.lastName,
    onUpdateExternalUserCallback,
    t
  ]);

  return (
    <Dialog open={open} onClose={onCancelCallback} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle variant="h4">
        <Typography variant="h4">{t("add_participant:title")}</Typography>
        <Box mt={2}>
          <Typography variant="body2">{t("audit_details:addParticipantInfo")}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <Question
            qType={QUESTION_TYPE.PARTICIPIANT_TYPE}
            value={answerSet?.type || ANSWERSET_TYPE.INTERNAL}
            valueName={"type"}
            multiSelectHiddenIds={auditMethodology === AUDIT_METHODOLOGY.SELF_ASSESSMENT ? undefined : ["external"]}
            onChange={onUpdateAnswerSetCallback}
            pb={0}
            pt={0}
          />
          {internalAnswerSetEl}
          {externalUserEl}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelCallback} color="primary" variant="outlined">
          {t("cancel_text")}
        </Button>
        <Button onClick={onConfirmCallback} color="primary" disabled={!isValid || inProgress} variant="contained">
          {inProgress && <CircularProgress sx={sx.loading} size={14} />}
          {t("task_details:add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
