import { useTranslation } from "react-i18next";
import Question from "../../../../components/Question/Question";
import MultiAutocomplete from "../../../../components/MultiAutocomplete/MultiAutocomplete";
import {
  tDataCategoryClassification,
  tDataTypeClassification,
  tDataTypeClassificationCategory,
  tDeletedEntry,
  tSimpleTranslate
} from "../../../handlers/dataTypeTranslatorHandler";
import AccordionMultiField from "../../../../components/AccordionMultiField/AccordionMultiField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { getPersonGroupCategoryTupleStrings, toLegacyCategoryTypeTuple } from "../utils/helper-functions";
import { RESOURCE_TYPES } from "../../../handlers/resourceHandler";
import { useDataTypeTreeManager } from "app/api/dataAssetApi";
import { useResources } from "../../../contexts/resource-context";
import { useMetaView } from "../../../contexts/meta-view-context";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import { useIsFeaturePresent } from "../../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../features";
import { CategoryTypeTupleModel } from "../CategoryTypeTupleModel";
import ArrayDisplay from "components/ArrayDisplay";
import { PersonGroup } from "../../../api/generated/asset-service";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete/Autocomplete";
import { isEqual } from "lodash-es";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";

export interface PersonGroupAccordionData {
  readonly id: string;
  readonly categoryTypeTuples: CategoryTypeTupleModel[];
  readonly dataOfMinors: "yes" | "no" | "unsure" | "";
  readonly personGroup: string;
  readonly potentialAmountOfDataSubjects: string;
}

type PersonGroupAccordionProps = {
  readonly personGroup: PersonGroupAccordionData;
  readonly onDelete: (id: string) => void;
  readonly onSave: (val: PersonGroupAccordionData) => Promise<void>;
  readonly isCreatedNew?: boolean;
  readonly disabled?: boolean;
};
const PersonGroupAccordion = ({ personGroup, onDelete, onSave, isCreatedNew, disabled }: PersonGroupAccordionProps) => {
  const { t } = useTranslation("questionnaires");
  const { resources } = useResources();
  const { setInfo } = useMetaView();
  const [dirty, setDirty] = useState<boolean>(false);
  const { auth } = useAuthentication();
  const userHasAssetWritePermission = auth?.permissions?.some(
    permission => permission === "asset_write_all" || permission === "asset_write_org"
  );

  const [currentPersonGroup, setCurrentPersonGroup] = useState<PersonGroupAccordionData>(personGroup || {});
  useEffect(() => {
    if (personGroup) {
      setCurrentPersonGroup(personGroup);
    }
  }, [personGroup]);

  useEffect(() => {
    const diff = Object.keys(currentPersonGroup).some(
      key =>
        !isEqual(
          currentPersonGroup[key as keyof PersonGroupAccordionData],
          personGroup[key as keyof PersonGroupAccordionData]
        )
    );
    setDirty(diff);
  }, [currentPersonGroup, personGroup]);

  const newPersonalData = useMemo(
    () => ({
      title: t("newPersonalDataTitle"),
      text: t("newPersonalDataText")
    }),
    [t]
  );

  const dataTypeTree = useDataTypeTreeManager(true);
  const dataTypeCategoryPersonGroupList = dataTypeTree.data;

  /*  Person Group Selector El */
  const updatePersonGroupId = useCallback(
    (personGroupId: string) => {
      const newPersonGroup = { ...currentPersonGroup, personGroup: personGroupId, categoryTypeTuples: [] };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );
  const pgGetOptionSelected = useCallback((optionItem: PersonGroup, currentCheckedItem: PersonGroup) => {
    if (!currentCheckedItem) {
      return false;
    }
    return optionItem.id === currentCheckedItem.id;
  }, []);
  const pgUpdateOptions = useCallback(
    async (personGroups: (PersonGroup | string)[]) => {
      const personGroupToAdd = personGroups[personGroups.length - 1];
      if (typeof personGroupToAdd !== "string") {
        return;
      }
      const createdId = await dataTypeTree.actions.addPersonGroup(personGroupToAdd, { populateLocalCache: true });
      updatePersonGroupId(createdId);
    },
    [dataTypeTree.actions, updatePersonGroupId]
  );
  const pgUpdateSelected = useCallback(
    (personGroup: PersonGroup | null) => {
      if (personGroup?.id) {
        updatePersonGroupId(personGroup.id);
      }
    },
    [updatePersonGroupId]
  );

  const pgGetOptionLabel = useCallback(
    (option: PersonGroup | string) => {
      if (typeof option === "string" && option.includes(t("personGroup:addText"))) {
        return option;
      }
      if (typeof option !== "string" && option.id === "deleted") {
        return tDeletedEntry({ t });
      }
      return tSimpleTranslate({
        t,
        typeCategoryOrPersonGroup: typeof option === "string" ? option : option.personGroupKey
      });
    },
    [t]
  );

  const selectedPersonGroup = useMemo(
    () =>
      currentPersonGroup.categoryTypeTuples.length > 0 &&
      toLegacyCategoryTypeTuple(currentPersonGroup, dataTypeTree.dataById)?.personGroupDeleted // if deleted
        ? {
            id: "deleted",
            personGroupKey: "",
            dataCategories: []
          }
        : dataTypeTree?.dataById?.personGroups[currentPersonGroup.personGroup],
    [currentPersonGroup, dataTypeTree.dataById]
  );

  const personGroupInfo = useMemo(
    () => ({
      title: t("personGroupInfoTitle"),
      text: t("personGroupInfoText")
    }),
    [t]
  );
  const pgOnFocusSelected = useCallback(() => {
    setInfo(personGroupInfo);
  }, [personGroupInfo, setInfo]);
  const pgOnBlur = useCallback(() => {
    setInfo(newPersonalData);
  }, [newPersonalData, setInfo]);

  const pgNewOptionEntryAlreadyExists = useCallback(
    newTypedOption => {
      const cleanedTypedOption = (newTypedOption || "").trim();
      return !!dataTypeCategoryPersonGroupList
        ?.map(listPersonGroup => listPersonGroup.personGroupKey || "")
        .map(personGroupKey => personGroupKey.trim())
        .find(
          cleanedPersonGroupKey =>
            cleanedPersonGroupKey === cleanedTypedOption ||
            tSimpleTranslate({
              t,
              typeCategoryOrPersonGroup: cleanedPersonGroupKey
            }) === cleanedTypedOption
        );
    },
    [dataTypeCategoryPersonGroupList, t]
  );

  const personGroupEl = (
    <MultiAutocomplete<PersonGroup, false, undefined, undefined>
      hasMultiSelect={false}
      getOptionLabel={pgGetOptionLabel}
      getOptionSelected={pgGetOptionSelected}
      options={dataTypeCategoryPersonGroupList || emptyArray}
      updateOptions={pgUpdateOptions}
      selected={selectedPersonGroup}
      updateSelected={pgUpdateSelected}
      onFocus={pgOnFocusSelected}
      onBlur={pgOnBlur}
      disabled={disabled}
      placeholder={t("personGroup:search")}
      id="personengruppe"
      label={t("personGroup:questionTitle")}
      addText={userHasAssetWritePermission ? t("personGroup:addText") : undefined}
      newOptionEntryAlreadyExists={pgNewOptionEntryAlreadyExists}
      error={!currentPersonGroup.personGroup}
      helperText={!currentPersonGroup.personGroup ? t("questionnaires:mandatoryField") : ""}
    />
  );

  /*  Data of Minors Selector El */
  const minorityOptions = useMemo(() => ["yes", "no", "unsure"], []);
  const updatePersonGroupDataOfMinors = useCallback(
    (dataOfMinors: "yes" | "no" | "unsure" | "") => {
      const newPersonGroup = { ...currentPersonGroup, dataOfMinors: dataOfMinors };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );
  const [minorsInputText, setMinorsInputText] = useState("");
  const minorsUpdateSelected = useCallback(
    value => {
      updatePersonGroupDataOfMinors(value);
      setMinorsInputText(value);
    },
    [updatePersonGroupDataOfMinors]
  );
  const minorsOnInputChange = useCallback((event, value) => {
    setMinorsInputText(value);
  }, []);

  const minorsGetOptionLabel = useCallback(option => t(`minors:${option}`, option), [t]);
  const minorsInfo = useMemo(
    () => ({
      title: t("minorsInfoTitle"),
      text: t("minorsInfoText")
    }),
    [t]
  );
  const minorsOnFocus = useCallback(() => setInfo(minorsInfo), [minorsInfo, setInfo]);
  const minorsOnBlur = useCallback(() => setInfo(newPersonalData), [newPersonalData, setInfo]);
  const minorsEl = currentPersonGroup.personGroup ? (
    <Question questionId={"minors"}>
      <MultiAutocomplete
        hasMultiSelect={false}
        getOptionLabel={minorsGetOptionLabel}
        options={minorityOptions}
        selected={currentPersonGroup.dataOfMinors}
        updateSelected={minorsUpdateSelected}
        onFocus={minorsOnFocus}
        onBlur={minorsOnBlur}
        id={"minors"}
        disabled={disabled}
        label={t("minors:questionTitle")}
        onInputChange={minorsOnInputChange}
        inputValue={t(`minors:${minorsInputText}`, minorsInputText)}
      />
    </Question>
  ) : (
    <></>
  );

  /*  Data Type Selector El */
  const updatePersonGroupCategoryTypeTuples = useCallback(
    categoryTypeTuples => {
      const newPersonGroup = { ...currentPersonGroup, categoryTypeTuples: categoryTypeTuples };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );

  const dataTypeUpdateOptions = useCallback(
    async dataTypes => {
      const addedDataTypeName = dataTypes[dataTypes.length - 1];
      const personGroup = dataTypeTree.dataById?.personGroups[currentPersonGroup.personGroup];
      const uncategorized = personGroup?.dataCategories.find(
        dataCategory => dataCategory.dataCategoryKey === "uncategorized"
      );
      const uncategorizedId =
        uncategorized?.id ||
        (await dataTypeTree.actions.addDataCategory({
          personGroupId: currentPersonGroup.personGroup,
          dataCategory: "uncategorized",
          populateLocalCache: true
        }));
      const createdId = await dataTypeTree.actions.addDataType({
        dataType: addedDataTypeName,
        dataCategoryId: uncategorizedId,
        personGroupId: currentPersonGroup.personGroup,
        unseen: true,
        populateLocalCache: true
      });

      updatePersonGroupCategoryTypeTuples([
        ...(currentPersonGroup.categoryTypeTuples || []),
        {
          id: createdId,
          category: uncategorizedId,
          personGroup: currentPersonGroup.personGroup,
          type: createdId,
          dataClassificationId: ""
        }
      ]);
    },
    [
      currentPersonGroup.categoryTypeTuples,
      currentPersonGroup.personGroup,
      dataTypeTree.actions,
      dataTypeTree.dataById?.personGroups,
      updatePersonGroupCategoryTypeTuples
    ]
  );

  const dataTypeGetOptionSelected = useCallback(
    (optionItem: CategoryTypeTupleModel, currentCheckedItem: CategoryTypeTupleModel) =>
      optionItem.id === currentCheckedItem.id,
    []
  );

  const dataTypeOptions = useMemo(() => {
    const categoriesForPersonGroup = dataTypeCategoryPersonGroupList?.filter(
      x => x.id === currentPersonGroup.personGroup
    );
    if (!categoriesForPersonGroup?.length || categoriesForPersonGroup?.length === 0) {
      return [];
    }
    let options: CategoryTypeTupleModel[] = [];
    categoriesForPersonGroup[0].dataCategories.forEach(category => {
      if (!category?.dataTypes) {
        return;
      }
      const newOptions = category.dataTypes
        .filter(type => !type.mergedIntoId)
        .map(type => {
          return {
            category: category.id || "",
            type: type.id,
            personGroup: currentPersonGroup.personGroup,
            id: type.id,
            dataClassificationId: type.dataClassificationId || null
          } satisfies CategoryTypeTupleModel;
        });
      options = options.concat(newOptions);
    });

    return options;
  }, [currentPersonGroup.personGroup, dataTypeCategoryPersonGroupList]);

  const dataTypeGetOptionLabel = useCallback(
    (categoryTypeTuple: CategoryTypeTupleModel | string, isCategory = false) => {
      if (!categoryTypeTuple) {
        return "";
      }
      // can be string when add new item
      if (typeof categoryTypeTuple === "string") return categoryTypeTuple;
      // disable category display during adding of new option
      if (isCategory && categoryTypeTuple.toString().includes(t("dataType:addText"))) {
        return;
      }
      if (isCategory) {
        const category = dataTypeTree.dataById?.dataCategories[categoryTypeTuple.category];
        return tDataCategoryClassification({
          t,
          dataType: category,
          classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION],
          dataTypeTree: dataTypeTree?.data
        });
      }
      return tDataTypeClassification({
        t,
        dataType: toLegacyCategoryTypeTuple(categoryTypeTuple, dataTypeTree.dataById),
        classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION]
      });
    },
    [dataTypeTree, resources, t]
  );

  const getSelectedLabel = useCallback(
    (selectedValue: CategoryTypeTupleModel) => {
      return tDataTypeClassificationCategory({
        t,
        dataType: toLegacyCategoryTypeTuple(selectedValue, dataTypeTree.dataById),
        classifications: resources[RESOURCE_TYPES.DATA_CLASSIFICATION]
      });
    },
    [dataTypeTree.dataById, resources, t]
  );
  const dataTypeRenderTags = useCallback(
    (tagValue: CategoryTypeTupleModel[], getTagProps: AutocompleteGetTagProps) => {
      return tagValue.map((option, index) => (
        <Chip key={"chip" + index} {...getTagProps({ index })} label={getSelectedLabel(option)} />
      ));
    },
    [getSelectedLabel]
  );

  const dataTypeGroupBy = useCallback(option => dataTypeGetOptionLabel(option, true), [dataTypeGetOptionLabel]);
  const dataTypeInfo = useMemo(
    () => ({
      title: t("dataTypeInfoTitle"),
      text: t("dataTypeInfoText")
    }),
    [t]
  );
  const dataTypeOnFocus = useCallback(() => setInfo(dataTypeInfo), [dataTypeInfo, setInfo]);
  const dataTypeOnBlur = useCallback(() => setInfo(newPersonalData), [newPersonalData, setInfo]);

  const dataTypeEl = currentPersonGroup.personGroup ? (
    <div>
      <MultiAutocomplete<CategoryTypeTupleModel, true, false, undefined>
        disableClearable={false}
        groupBy={dataTypeGroupBy}
        onFocus={dataTypeOnFocus}
        onBlur={dataTypeOnBlur}
        options={dataTypeOptions}
        updateOptions={dataTypeUpdateOptions}
        renderTags={dataTypeRenderTags}
        getOptionLabel={dataTypeGetOptionLabel}
        hasMultiSelect={true}
        disabled={disabled}
        selected={currentPersonGroup.categoryTypeTuples}
        updateSelected={updatePersonGroupCategoryTypeTuples}
        getOptionSelected={dataTypeGetOptionSelected}
        label={t("dataCategoryDataTypeLabel")}
        placeholder={t("personGroup:search")}
        addText={userHasAssetWritePermission ? t("dataType:addText") : undefined}
        error={!currentPersonGroup.categoryTypeTuples.length}
        helperText={!currentPersonGroup.categoryTypeTuples.length ? t("questionnaires:mandatoryField") : ""}
      />
    </div>
  ) : (
    <></>
  );

  /*  Potential Amount data Subjects Selector El */
  const onChangeAmountDataSubjects = useCallback(
    event => {
      const newPersonGroup = { ...currentPersonGroup, potentialAmountOfDataSubjects: event.target.value };
      setCurrentPersonGroup(newPersonGroup);
    },
    [currentPersonGroup]
  );

  const isDataSubjectPotentialAmountPresent = useIsFeaturePresent(FEATURES.DATA_SUBJECT_POTENTIAL_AMOUNT);
  const amountDataSubjectsEl =
    isDataSubjectPotentialAmountPresent && currentPersonGroup.personGroup ? (
      <TextField
        multiline={false}
        id={"potentialAmountOfDataSubjects"}
        fullWidth={true}
        label={t("potentialAmountOfDataSubjectsLabel")}
        type="text"
        variant="outlined"
        value={currentPersonGroup.potentialAmountOfDataSubjects}
        onChange={onChangeAmountDataSubjects}
        disabled={disabled}
      />
    ) : (
      <></>
    );

  const titles = useMemo(
    () =>
      getPersonGroupCategoryTupleStrings(
        currentPersonGroup,
        resources[RESOURCE_TYPES.DATA_CLASSIFICATION],
        dataTypeTree.data,
        t,
        dataTypeTree.dataById
      ),
    [currentPersonGroup, dataTypeTree.data, dataTypeTree.dataById, resources, t]
  );

  const onClickDelete = useCallback(() => onDelete(personGroup.id), [onDelete, personGroup.id]);
  const [isSaving, setIsSaving] = useState(false);
  const onClickSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await onSave(currentPersonGroup);
      setDirty(false);
    } catch (error: unknown) {
      setIsSaving(false);
      throw error;
    }
    setIsSaving(false);
  }, [currentPersonGroup, onSave]);
  const onClickCancel = useCallback(() => {
    setCurrentPersonGroup(personGroup);
  }, [personGroup]);

  return (
    <Question
      key={currentPersonGroup.id}
      questionId={"personGroups " + currentPersonGroup.id}
      questionName={titles.join(", ")}
    >
      <AccordionMultiField
        id={"personGroups " + currentPersonGroup.id}
        key={currentPersonGroup.id}
        field={"personGroups"}
        deleteButtonText={t("delete")}
        deleteMultiField={onClickDelete}
        hasCancelAndSave={true}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
        titleType={"text"}
        isNewMultiFiled={isCreatedNew}
        disableButton={disabled}
        disableOnlySaveButton={!currentPersonGroup.personGroup || currentPersonGroup.categoryTypeTuples.length === 0}
        deleteButtonHint={undefined}
        cancelButtonText={undefined}
        saveButtonText={undefined}
        placeholder={undefined}
        index={undefined}
        onFocus={undefined}
        accordionsExpanded={undefined}
        setAccordionsExpanded={undefined}
        editButtonText={undefined}
        onClickEdit={undefined}
        additionalLeftButton={undefined}
        loading={isSaving}
        title={
          currentPersonGroup.personGroup ? (
            <Box display="flex" alignItems="center">
              <ArrayDisplay elements={titles} />
              {isSaving && <CircularProgress size="1rem" />}
            </Box>
          ) : (
            <>
              <Typography color="textSecondary">
                <i>{t("personGroup:inputPlaceholder")}</i>
              </Typography>
            </>
          )
        }
        dirty={dirty}
      >
        <Box>
          <Box display="flex" alignItems="center" width="100%">
            <Box mr={5} flex={1}>
              <Question>{personGroupEl}</Question>
            </Box>
            <Box flex={1}>{minorsEl}</Box>
          </Box>
          <Box mb={3}>{dataTypeEl}</Box>
          <Box>{amountDataSubjectsEl}</Box>
        </Box>
      </AccordionMultiField>
    </Question>
  );
};

const emptyArray: PersonGroup[] = [];

export default PersonGroupAccordion;
