import React, { useCallback, useEffect, useState } from "react";
import { getDepartmentName } from "../../../utils/getFirstDepartmentName";
import { useTranslation } from "react-i18next";
import { useUserDepartments } from "app/contexts/department-context";
import { useUserProcesses } from "../../../../hook/useUserProcesses";
import { flattenDataTypes } from "../../../utils/flattenDataTypes";
import { ProcessingActivityOverviewDTO } from "../../../api/paApi";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { Box } from "@material-ui/core";
import EditIcon from "assets/images/icons/edit.svg";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";
import MetaViewList, { MetaViewListItemProps } from "components/MetaViewList/MetaViewList";

const paStatusIcons: Record<string, React.ReactNode> = {
  edit: <EditIcon />,
  review: <SearchIcon />,
  approved: <DoneIcon />
};

export default function ProcessesInMetaView({ selectedNode }: { selectedNode?: string }) {
  const { processes, isLoading } = useUserProcesses();
  const dataTypeTree = useDataTypeTree();

  const [dataResourceToPAIds, setDataResourceToPAIds] = useState<Map<string, string[]>>(new Map<string, string[]>());
  useEffect(() => {
    const dataResourceToPAIds = new Map<string, string[]>();
    const flatDataType = flattenDataTypes(dataTypeTree.data || []);
    for (const process of processes) {
      const dataTypes = process.allDataTypeIds
        .flatMap(dataTypeId => {
          const dataType = flatDataType.find((dataType: any) => dataType.id === dataTypeId);
          return dataType ? [dataType] : [];
        })
        .filter(it => !it.mergedInto);
      for (const dataType of dataTypes) {
        const personGroupKey = dataType.personGroupKey;
        const dataCategoryKey = dataType.dataCategoryKey;
        const dataTypeId = dataType.id;
        dataResourceToPAIds.set(dataTypeId, [...(dataResourceToPAIds.get(dataTypeId) || []), process.id]);
        if (dataCategoryKey) {
          dataResourceToPAIds.set(dataCategoryKey, [...(dataResourceToPAIds.get(dataCategoryKey) || []), process.id]);
        }
        if (personGroupKey) {
          dataResourceToPAIds.set(personGroupKey, [...(dataResourceToPAIds.get(personGroupKey) || []), process.id]);
        }
      }
    }
    setDataResourceToPAIds(dataResourceToPAIds);
  }, [processes, dataTypeTree.data]);

  const { departments } = useUserDepartments();

  const { t } = useTranslation("resources_lists_data_types_categories_person_groups");

  const [relatedProcesses, setRelatedProcesses] = useState<ProcessingActivityOverviewDTO[]>([]);
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    if (!selectedNode) {
      return;
    }
    let processIds: string[] = [];
    const [selectedId] = selectedNode.split("_").reverse();
    processIds = dataResourceToPAIds.get(selectedId) || [];
    if (dataTypeTree.dataById?.dataTypes[selectedId]) {
      setSelectedType("dataType");
    } else if (dataTypeTree.dataById?.dataCategories[selectedId]) {
      setSelectedType("category");
    } else if (dataTypeTree.dataById?.personGroups[selectedId]) {
      setSelectedType("personGroup");
    }
    const processesForMetaView = processes.filter(process => processIds.includes(process.id));
    setRelatedProcesses(processesForMetaView);
  }, [selectedNode, processes, dataResourceToPAIds, dataTypeTree.dataById]);

  const [relatedProcessingActivitiesCardItemList, setRelatedProcessingActivitiesCardItemList] = useState<any[]>([]);
  useEffect(() => {
    setRelatedProcessingActivitiesCardItemList(
      relatedProcesses.map(
        process =>
          ({
            id: process.id,
            title: process.title,
            subTitle: process.orgUnitIds?.length ? getDepartmentName(departments, process.orgUnitIds[0]) : "",
            icon: paStatusIcons[process.status] || <></>,
            iconTooltip: t("processes_overview:" + process.status)
          }) satisfies MetaViewListItemProps
      )
    );
  }, [departments, relatedProcesses, t, dataTypeTree.dataById]);

  const onItemClickCallback = useCallback(id => {
    window.open("/processes/" + id + "/general");
  }, []);

  return selectedType ? (
    <Box p={3}>
      <MetaViewList
        header={t("processes_overview:list_processes")}
        items={relatedProcessingActivitiesCardItemList}
        loading={isLoading}
        onItemClick={onItemClickCallback}
        pagination={true}
      />
    </Box>
  ) : (
    <></>
  );
}
