import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OrgUnitTree from "app/pages/shared/OrgUnitPickerModal/OrgUnitTree";
import TextBody2 from "components/TextBody2/TextBody2";
import { useUserDepartments } from "app/contexts/department-context";
import {
  expandOrgUnitIds,
  orgUnitAndChildren,
  orgUnitsToIdMap,
  orgUnitsToParentIdMap
} from "app/handlers/orgUnitHandler";
import { Department } from "app/handlers/departmentHandler";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Box from "@mui/material/Box";

interface OrgUnitPickerModalProps {
  readonly open: boolean;
  readonly selectedDepartmentIds: string[];
  readonly userOrgUnitIds?: string[];
  readonly allowedOrgUnitIds?: string[];
  readonly linkingOrgUnitsModalHeading?: string;
  readonly linkingOrgUnitsModalSubHeading?: string;
  readonly onClose: () => void;
  readonly onConfirm: (departmentIds: string[]) => void;
  readonly required: boolean;
}

export default function OrgUnitPickerModal({
  open,
  selectedDepartmentIds,
  userOrgUnitIds,
  allowedOrgUnitIds,
  linkingOrgUnitsModalHeading,
  linkingOrgUnitsModalSubHeading,
  onClose,
  onConfirm,
  required
}: OrgUnitPickerModalProps) {
  const { t } = useTranslation("organisation");

  const { departments } = useUserDepartments();

  /**
   * Visible departments in Tree
   */
  const userAllowedDepartments = useMemo<Department[]>(() => {
    if (!departments) {
      return [];
    }
    if (!userOrgUnitIds) {
      return departments;
    }
    if (userOrgUnitIds.includes("*")) {
      return departments;
    }

    return (userOrgUnitIds || []).reduce<Department[]>((acc, next) => {
      return [...acc, ...orgUnitAndChildren(next, departments)];
    }, []);
  }, [departments, userOrgUnitIds]);

  const userDepartmentsMap = useMemo<Map<string, Department>>(
    () => orgUnitsToIdMap(userAllowedDepartments),
    [userAllowedDepartments]
  );

  const parentToChildUserDepartments = useMemo<Map<string, Department[]>>(
    () => orgUnitsToParentIdMap(userAllowedDepartments),
    [userAllowedDepartments]
  );

  /**
   * Non disabled departments in Tree
   */
  const availableDepartmentIds = useMemo<Set<string>>(() => {
    if (allowedOrgUnitIds?.includes("*") || !allowedOrgUnitIds) {
      return new Set(userAllowedDepartments.map(department => department.id));
    }

    return expandOrgUnitIds(allowedOrgUnitIds, parentToChildUserDepartments);
  }, [allowedOrgUnitIds, parentToChildUserDepartments, userAllowedDepartments]);

  const filterCheckedByAvailable = useCallback(
    (departmentId: string) => availableDepartmentIds.has("*") || availableDepartmentIds.has(departmentId),
    [availableDepartmentIds]
  );

  const [checkedDepartmentIds, setCheckedDepartmentIds] = useState<Set<string>>(new Set());
  useEffect(() => {
    if (!open) {
      return;
    }

    setCheckedDepartmentIds(
      new Set(
        selectedDepartmentIds.includes("*")
          ? userAllowedDepartments.map(department => department.id)
          : selectedDepartmentIds
      )
    );
  }, [selectedDepartmentIds, userAllowedDepartments, open]);

  const checkedFilteredDepartmentIds = useMemo<string[]>(
    () => [...checkedDepartmentIds].filter(filterCheckedByAvailable),
    [checkedDepartmentIds, filterCheckedByAvailable]
  );

  const confirmSelection = useCallback(() => {
    onConfirm(checkedFilteredDepartmentIds);
    onClose();
  }, [onConfirm, checkedFilteredDepartmentIds, onClose]);

  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onClose
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          disabled: required ? checkedFilteredDepartmentIds.length < 1 : false,
          onClick: confirmSelection
        }
      ] as ConfirmationModalButtonProps[],
    [checkedFilteredDepartmentIds.length, confirmSelection, onClose, t, required]
  );

  const stopEventPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const modalBody = (
    <Box height={400} overflow={"auto"} onClick={stopEventPropagation}>
      <Box mb={2}>
        <TextBody2 text={linkingOrgUnitsModalSubHeading || t("orgunit_picker:generic_description")} />
      </Box>
      <OrgUnitTree
        allDepartments={userDepartmentsMap}
        parentToChildDepartments={parentToChildUserDepartments}
        availableDepartmentIds={availableDepartmentIds}
        checkedDepartmentIds={checkedDepartmentIds}
        onCheckedChanged={setCheckedDepartmentIds}
      />
    </Box>
  );

  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={modalBody}
      modalOpen={open}
      modalTitle={linkingOrgUnitsModalHeading || t("orgunit_picker:generic_title")}
      onClose={onClose}
      buttons={buttons}
      modalText={""}
    />
  );
}
