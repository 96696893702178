import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { Department, getOrgUnits } from "../../../../app/handlers/departmentHandler";
import { orgUnitsToIdMap, orgUnitsToParentIdMap, topToBottomOrgUnits } from "../../../../app/handlers/orgUnitHandler";
import { getLoggedInUser } from "../../../../app/handlers/authentication/authenticationHandler";

export const departmentsDecorator: OverviewResultDecorator<{
  readonly _departments: Department[];
  readonly _departmentFullName: (id: string) => string;
}> = {
  async decorate(
    overviewResult: OverviewResult,
    tenantId: string
  ): Promise<{ readonly _departments: Department[]; readonly _departmentFullName: (id: string) => string }> {
    if (overviewResult._departments) {
      return { _departments: overviewResult._departments, _departmentFullName: overviewResult._departmentFullName };
    }

    const [departments, me] = await Promise.all([getOrgUnits(), getLoggedInUser()]);
    const userTenantInfo = me?.tenants?.find(it => it.tenantId === tenantId);
    if (!userTenantInfo) {
      return { _departments: [], _departmentFullName: input => input };
    }

    const userOrgUnitIds = new Set([userTenantInfo.orgUnitId, ...userTenantInfo.furtherOrgUnitIds]);
    const { fromTopToFoundToLeafDepartments } = topToBottomOrgUnits(
      departments,
      userOrgUnitIds,
      orgUnitsToIdMap(departments),
      orgUnitsToParentIdMap(departments)
    );
    const departmentsMap = new Map<string, Department>(departments.map(it => [it.id, it]));
    return {
      _departments: fromTopToFoundToLeafDepartments,
      _departmentFullName: (id: string) => {
        const department = departmentsMap.get(id);
        if (!department) {
          return id;
        }
        return department.fullName;
      }
    };
  }
};
