import { IconButton } from "@material-ui/core";
import { Box, Typography, Tooltip, useTheme, Button, CircularProgress } from "@mui/material";
import BasicPagination from "components/BasicPagination/BasicPagination";
import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";

export interface MetaViewListItemActionProps {
  readonly action: string;
  readonly icon: React.ReactNode;
  readonly tooltip: string;
}
export interface MetaViewListItemProps {
  readonly id: string;
  readonly title: string;
  readonly subTitle: string;
  readonly icon?: React.ReactNode;
  readonly iconTooltip?: string;
  readonly actions?: MetaViewListItemActionProps[];
}

interface MetaViewListProps {
  readonly header: string;
  readonly headerIcon?: React.ReactNode;
  readonly headerTooltip?: React.ReactNode;
  readonly loading?: boolean;
  readonly items: MetaViewListItemProps[];
  readonly pagination?: boolean;
  readonly itemsPerPage?: number;

  readonly onItemClick?: (id: string) => void;
  readonly onItemActionClick?: (id: string, action: string) => void;
}

const MetaViewListItemAction = ({
  action,
  onClick
}: {
  readonly action: MetaViewListItemActionProps;
  readonly onClick: (action: MetaViewListItemActionProps) => void;
}) => {
  const onClickCallback = useCallback(() => {
    onClick(action);
  }, [action, onClick]);

  return (
    <Tooltip title={action.tooltip}>
      <IconButton onClick={onClickCallback}>{action.icon}</IconButton>
    </Tooltip>
  );
};

const MetaViewListItem = ({
  item,
  isLast,
  onClick,
  onActionClick
}: {
  readonly item: MetaViewListItemProps;
  readonly isLast: boolean;
  readonly onClick?: (id: string) => void;
  readonly onActionClick?: (id: string, action: string) => void;
}) => {
  const theme = useTheme();
  const sx = {
    button: {
      borderRadius: 0,
      width: "100%",
      display: "block",
      padding: "16px 16px 16px 8px",
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    item: {
      width: "100%",
      display: "flex",
      alignItems: "center"
    },
    itemLast: {
      border: "none"
    },
    text: {
      ml: 2,
      flex: 1,
      textAlign: "left",
      overflow: "hidden"
    },
    title: {
      whiteSpace: "nowrap",
      minWidth: 0,
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    caption: {
      color: `${theme.palette.grey[400]} !important`
    },
    actions: {
      px: 1,
      display: "flex",
      alignItems: "center",
      gap: 0.5
    }
  };

  const { id, title, subTitle, icon, iconTooltip, actions } = item;
  const onCLickCallback = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  const onActionClickCallback = useCallback(
    action => {
      onActionClick?.(id, action.action);
    },
    [id, onActionClick]
  );

  const stopClick = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const actionsEl = useMemo(() => {
    if (!actions || !actions.length) {
      return <></>;
    }
    return (
      <Box sx={sx.actions} onClick={stopClick}>
        {actions.map(action => (
          <MetaViewListItemAction key={action.action} action={action} onClick={onActionClickCallback} />
        ))}
      </Box>
    );
  }, [actions, onActionClickCallback, stopClick, sx.actions]);

  return (
    <Button sx={{ ...sx.button, ...(isLast ? sx.itemLast : {}) }} onClick={onCLickCallback}>
      <Box sx={sx.item}>
        <Tooltip title={iconTooltip}>
          <Box>{icon || <></>}</Box>
        </Tooltip>
        <Box sx={sx.text}>
          <Typography sx={sx.title}>{title}</Typography>
          <Typography sx={{ ...sx.title, ...sx.caption }} variant="caption">
            {subTitle}
          </Typography>
        </Box>
        {actionsEl}
      </Box>
    </Button>
  );
};

const MetaViewList = ({
  header,
  headerIcon,
  headerTooltip,
  loading,
  items,
  pagination,
  itemsPerPage = 5,
  onItemClick,
  onItemActionClick
}: MetaViewListProps) => {
  const theme = useTheme();
  const sx = {
    root: {
      background: theme.palette.blue[50],
      paddingBottom: "32px",
      borderRadius: "10px"
    },
    header: {
      p: 3,
      pb: 0
    },
    item: {
      margin: "0 16px",
      padding: "16px 16px 16px 8px",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      cursor: "pointer",
      "&:hover": {
        background: theme.palette.blue[100]
      }
    },
    itemLast: {
      border: "none"
    },
    noEntriesFound: {
      textAlign: "center",
      color: theme.palette.grey[500]
    }
  };

  const [page, setPage] = useState<number>(1);

  /* PAGINATION */
  const currentPageItems = useCallback(
    function (element, index) {
      if (pagination && items.length > itemsPerPage) {
        return index < page * itemsPerPage && index >= (page - 1) * itemsPerPage;
      }
      return true;
    },
    [pagination, items.length, itemsPerPage, page]
  );
  const paginationEl = useMemo(() => {
    if (pagination && items.length > itemsPerPage) {
      return (
        <BasicPagination elementsPerPage={itemsPerPage} page={page} setPage={setPage} numberElements={items.length} />
      );
    }
  }, [items.length, itemsPerPage, page, pagination]);

  /* LIST */
  const listEl = useMemo(
    () => (
      <Box p={2}>
        {items.filter(currentPageItems).map((item, index) => (
          <MetaViewListItem
            key={item.id}
            item={item}
            onClick={onItemClick}
            isLast={index === items.length - 1}
            onActionClick={onItemActionClick}
          />
        ))}
      </Box>
    ),
    [currentPageItems, items, onItemActionClick, onItemClick]
  );

  /* LOADING */
  const loadingEl = useMemo(
    () =>
      loading && (
        <Box textAlign={"center"} mt={1}>
          <CircularProgress />
        </Box>
      ),
    [loading]
  );

  /* NO ENTRIES */
  const noEntriesFoundEl = useMemo(() => {
    if (!loading && items.length === 0) {
      return <Typography sx={sx.noEntriesFound}>{t("overview:no_entries_found")}</Typography>;
    }
  }, [items.length, loading, sx.noEntriesFound]);

  return (
    <Box sx={sx.root}>
      <Box sx={sx.header}>
        <Tooltip title={headerTooltip}>
          <>
            {headerIcon || <></>}
            <Typography variant="h4">{header}</Typography>
          </>
        </Tooltip>
      </Box>
      {listEl}
      {loadingEl}
      {noEntriesFoundEl}
      {paginationEl}
    </Box>
  );
};

export default MetaViewList;
