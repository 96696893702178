import React, { useCallback, useMemo, useState } from "react";
import { Box, Checkbox, IconButton, ListItemIcon, Typography, useTheme } from "@mui/material";
import { styled } from "@material-ui/core/styles";
import { RowDeletePrompt } from "./RowDeletePrompt";
import { AssigneDropdown } from "./AssigneDropdown";
import { StatusDropdown } from "./StatusDropdown";
import colors from "theme/palette/colors";
import stopEvent from "tool/stopEvent";
import { OverviewRowAction, OverviewRowActionProps } from "components/Overview/controls/OverviewRowAction";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { LazySvgIcon } from "../LazySvgIcon/LazySvgIcon";
import OverviewHighlight from "components/Overview/controls/OverviewHighlight";
import { SxProps } from "@mui/system/styleFunctionSx";

export interface RowAction {
  action: string;
  disabled?: boolean;
  dropdownActions?: { title: string; onHandle: (id: string) => void }[];
  icon: React.ReactNode;
  item: OverviewItem;
  title: string;
  onHandle?: (id: string) => void;
}

export type Assignee = {
  id: string;
  is_deleted?: boolean;
  is_group: boolean;
  name: string;
  profile?: string;
};

export type ItemStatus = {
  icon: React.ReactNode;
  status: string;
  label?: string;
};

export const listItemDefaultStatuses: ItemStatus[] = [
  { icon: <LazySvgIcon name="Blocked" />, status: "Blocked" },
  { icon: <LazySvgIcon name="Canceled" />, status: "Canceled" },
  { icon: <LazySvgIcon name="Completed" />, status: "Completed" },
  { icon: <LazySvgIcon name="Draft" />, status: "Draft" },
  { icon: <LazySvgIcon name="Half" />, status: "Half" },
  { icon: <LazySvgIcon name="In_Progress" />, status: "In_Progress" },
  { icon: <LazySvgIcon name="In_Review" />, status: "In_Review" },
  { icon: <LazySvgIcon name="Not_Started" />, status: "Not_Started" },
  { icon: <LazySvgIcon name="On_Halt" />, status: "On_Halt" },
  { icon: <LazySvgIcon name="Pending" />, status: "Pending" },
  { icon: <LazySvgIcon name="Quarter" />, status: "Quarter" }
];

export type ListViewItemProps = {
  allowedStatuses?: ItemStatus[];
  assignables?: Assignee[];
  assignee?: Assignee;
  children?: React.ReactNode;
  deletable?: boolean;
  icon?: React.ReactNode;
  isNew?: boolean;
  leadingIcon?: React.ReactNode;
  reserveCheckboxSpace?: boolean;
  rowActions?: RowAction[] | OverviewRowActionProps[];
  searchText?: string;
  selected?: boolean;
  hasCheckbox?: boolean;
  forceDisplayCheckbox?: boolean;
  status?: string;
  subTitle?: string;
  title: string;
  onAssigneeChange?: (assignee?: Assignee) => void;
  onClick?: () => void;
  onDelete?: () => Promise<void>;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusChange?: (status: string) => Promise<void>;
};

export const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 24 + 9
});

export default function ListViewItem(props: ListViewItemProps) {
  const theme = useTheme();
  const showStatus = props.allowedStatuses;
  const {
    allowedStatuses = listItemDefaultStatuses,
    assignables,
    assignee,
    children,
    deletable,
    forceDisplayCheckbox,
    hasCheckbox = true,
    icon,
    isNew,
    leadingIcon,
    reserveCheckboxSpace,
    rowActions,
    searchText,
    selected,
    status,
    subTitle,
    title,
    onAssigneeChange,
    onClick,
    onDelete,
    onSelect,
    onStatusChange
  } = props;

  const [rowState, setRowState] = useState<"default" | "delete">("default");
  const handleStartDelete = useCallback((event: React.MouseEvent) => {
    setRowState("delete");
    stopEvent(event);
  }, []);

  const handleCancel = useCallback(() => {
    setRowState("default");
  }, []);

  const handleCheckBoxClick = useCallback(
    (event: React.MouseEvent) => {
      onSelect?.(event as any);
      stopEvent(event);
    },
    [onSelect]
  );

  const canDelete = onDelete && rowState === "delete";
  const anyActionExist = rowActions && rowActions?.length > 0;
  const actionEl = anyActionExist && (
    <Box
      className={`rowActions hoverIcon`}
      display="flex"
      justifyContent="flex-end"
      ml={1}
      sx={{
        alignItems: "center",
        backgroundColor: rowState === "delete" ? theme.palette.error.light : "unset",
        gap: theme.spacing(1),
        width: "100%"
      }}
    >
      {rowActions?.map((rowAction: RowAction | OverviewRowActionProps, i) => {
        const { action, disabled, icon, item, title, onHandle } = rowAction;
        return (
          <Box onClick={stopEvent} key={`${item?.id}-${rowAction.action}-${i}`}>
            <OverviewRowAction
              action={action}
              disabled={disabled}
              dropdownActions={rowAction.dropdownActions}
              id={item?.id}
              item={item}
              key={`${item?.id}-${rowAction.action}-${i}`}
              title={title}
              onHandle={onHandle}
            >
              {icon}
            </OverviewRowAction>
          </Box>
        );
      })}
    </Box>
  );

  const containerSx = useMemo<SxProps>(() => {
    return {
      alignItems: "center",
      backgroundColor: canDelete ? theme.palette.error.light : "unset",
      borderTop: !canDelete ? "1px solid" : "none",
      borderTopColor: "#393A321F",
      cursor: !canDelete ? "pointer" : "default",
      display: "flex",
      height: "58px",
      padding: theme.spacing(2),
      paddingLeft: 3,
      "&:hover": {
        backgroundColor: !canDelete ? theme.palette.action.hover : theme.palette.error.light,
        ".hoverIcon": { opacity: 1 },
        ".selected": { opacity: 1 }
      },
      "& .hoverIcon": {
        opacity: 0
      },
      "& .selected": {
        opacity: 1
      }
    };
  }, [canDelete, theme]);

  const checkBoxSx = useMemo<SxProps>(() => {
    return {
      color: selected ? `${theme.palette.primary.main} !important` : colors.grey.grey500,
      visibility: hasCheckbox ? "visible" : "hidden"
    };
  }, [hasCheckbox, selected, theme.palette.primary.main]);

  return (
    <Box onClick={onClick} sx={containerSx}>
      {canDelete ? (
        <RowDeletePrompt onDelete={onDelete} onCancel={handleCancel} />
      ) : (
        <>
          <Box display={"flex"} alignItems={"center"} flexGrow={1} gap={2} maxHeight={"100%"}>
            <Checkbox
              className={forceDisplayCheckbox ? `selected` : `hoverIcon ${selected ? "selected" : ""}`}
              checked={selected}
              onChange={onSelect ?? undefined}
              onClick={handleCheckBoxClick}
              sx={checkBoxSx}
            />
            {leadingIcon && (
              <Box display={"flex"} alignItems={"center"} flexShrink={0}>
                {leadingIcon}
              </Box>
            )}

            {isNew && (
              <Box
                bgcolor={theme.palette.primary.main}
                borderRadius={"50%"}
                flexShrink={0}
                height={"8px"}
                width={"8px"}
              />
            )}
            {showStatus && (
              <StatusDropdown status={status} allowedStatuses={allowedStatuses} onStatusChange={onStatusChange} />
            )}

            <Box display="flex" flexDirection="column">
              <Typography
                variant="body1"
                fontWeight={isNew ? "bold" : "normal"}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace={"nowrap"}
              >
                {searchText ? <OverviewHighlight text={title} searchTerm={searchText} /> : title}
              </Typography>

              {subTitle && (
                <Typography
                  variant="body2"
                  color={colors.grey.grey500}
                  fontSize={"0.8em"}
                  maxWidth={600}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={"nowrap"}
                  width="100%"
                >
                  {subTitle}
                </Typography>
              )}
            </Box>

            {children}
            {actionEl}
          </Box>
          <Box onClick={stopEvent} display={"flex"} alignItems={"center"} gap={theme.spacing(1)}>
            {assignables?.length ? (
              <Box display="flex" alignItems="center">
                <AssigneDropdown assignables={assignables} assignee={assignee} onAssigneeChange={onAssigneeChange} />
              </Box>
            ) : null}

            <Box
              className={`hoverIcon`}
              display="flex"
              alignItems={"center"}
              gap={theme.spacing(1)}
              sx={childrenLeftMargin}
            >
              {icon}
              {deletable && onDelete ? (
                <IconButton onClick={handleStartDelete} disabled={!deletable}>
                  <LazySvgIcon name="Delete" />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

const childrenLeftMargin: SxProps = {
  "& > *": {
    margin: "5px"
  }
};
