import { createNaturalSorter } from "../../../utils/naturalSort";
import { Department } from "../../../handlers/departmentHandler";
import React, { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import { OrgUnitTreeNodeLabel } from "./OrgUnitTreeNodeLabel";
import { alpha, withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import { DepartmentWithDescendantCount } from "../../../handlers/orgUnitHandler";

const StyledTreeItemWithCheckbox = withStyles((theme: any) => ({
  group: {
    marginLeft: -22,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.15)}`
  }
}))(TreeItem);

const naturalSorter = createNaturalSorter();

export const OrgUnitTreeNode = ({
  node,
  parentToChildDepartments,
  availableDepartmentIds,
  checkedDepartmentIds,
  countedCheckedDescendants,
  onChecked,
  searchText,
  cascadeMode
}: {
  readonly node: Department;
  readonly parentToChildDepartments: Map<string, Department[]>;
  readonly availableDepartmentIds?: Set<string>;
  readonly checkedDepartmentIds: Set<string>;
  readonly countedCheckedDescendants: Map<string, DepartmentWithDescendantCount>;
  readonly onChecked: (department: Department, checked: boolean) => void;
  readonly searchText?: string;
  readonly cascadeMode?: boolean;
}) => {
  const disabled = availableDepartmentIds && !availableDepartmentIds.has(node.id);
  const sortedChildren = useMemo(() => {
    const children = parentToChildDepartments.get(node.id) || [];
    return children.sort((a, b) => naturalSorter(a.name, b.name));
  }, [parentToChildDepartments, node.id]);
  const checked = checkedDepartmentIds.has(node.id);
  const handleCheckboxChange = useCallback(() => {
    onChecked(node, !checked);
  }, [node, onChecked, checked]);

  const count = countedCheckedDescendants.get(node.id);
  const shouldShowCount = count && count.checkedDescendants > 0;

  const descendantCountWithoutSelf = count ? count.totalDescendants - 1 : 0;
  const checkedDescendantCountWithoutSelf = count
    ? checked
      ? count.checkedDescendants - 1
      : count.checkedDescendants
    : 0;
  const allDescendantIsChecked = descendantCountWithoutSelf === checkedDescendantCountWithoutSelf;
  const indeterminate = cascadeMode
    ? shouldShowCount && (!allDescendantIsChecked || (allDescendantIsChecked && !checked))
    : undefined;

  if (!node?.id) {
    return <div>An error occurred, no data was found.</div>;
  }

  return (
    <Box display="flex" data-testid={`org-unit-tree-node-${node.id}${disabled ? "-disabled" : ""}`} width="100%">
      <Box>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={disabled}
          indeterminate={indeterminate}
          data-testid={`org-unit-tree-node-${node.id}-checkbox-${checked ? "checked" : "unchecked"}`}
        />
      </Box>
      <Box pt={1.125} flex={1}>
        <StyledTreeItemWithCheckbox
          nodeId={node.id}
          label={
            <OrgUnitTreeNodeLabel
              name={node.name}
              searchText={searchText}
              chipText={shouldShowCount ? `${count.checkedDescendants}/${count.totalDescendants}` : undefined}
            />
          }
        >
          {sortedChildren.map(child => (
            <OrgUnitTreeNode
              key={child.id}
              node={child}
              parentToChildDepartments={parentToChildDepartments}
              availableDepartmentIds={availableDepartmentIds}
              countedCheckedDescendants={countedCheckedDescendants}
              checkedDepartmentIds={checkedDepartmentIds}
              onChecked={onChecked}
              searchText={searchText}
              cascadeMode={cascadeMode}
            />
          ))}
        </StyledTreeItemWithCheckbox>
      </Box>
    </Box>
  );
};
