import { apiEndpoints } from "app/api/apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";
import { AITechDocsApi, UpdateAITechDocDTO } from "./generated/aitechdoc-service";
import useSWR from "swr";
import { useCallback } from "react";

const aiTechDocClient = new AITechDocsApi(undefined, apiEndpoints.aiTechDocUrl, defaultOTCAuthenticatedAxios());
export const useGetAITechDocApi = (args: { documentId: string }) => {
  return useSWR(args.documentId ? ["asset", args.documentId] : null, () => {
    return aiTechDocClient.getAITechDoc(args.documentId).then(resp => resp.data);
  });
};

export const useUpdateAITechDocApi = (args: { documentId: string }) => {
  const { mutate, isValidating } = useGetAITechDocApi({ documentId: args.documentId });

  return {
    updateAITechDoc: useCallback(
      async (payload: UpdateAITechDocDTO) => {
        await mutate(
          async data => {
            await aiTechDocClient.updateAITechDoc(args.documentId, payload);
            return data;
          },
          {
            populateCache: false,
            revalidate: true
          }
        );
      },
      [args.documentId, mutate]
    ),
    isValidating
  };
};

export const getAITechDocOverview = async () => {
  const response = await aiTechDocClient.getOverviewOfAITechDocs();
  return response.data;
};

export const getAITechDocExcelExport = async (ids: string[]) => {
  const response = await aiTechDocClient.exportAITechDocsAsExcel(
    {
      aiTechDocIds: ids
    },
    {
      responseType: "blob",
      timeout: 0
    }
  );
  return new Blob([response.data]);
};
