import { OverviewResult, OverviewResultDecorator } from "../overviewBaseController";
import { getTenantInformation, Tenant } from "../../../../app/handlers/tenantHandler";

export const tenantDecorator: OverviewResultDecorator<{ readonly _tenant: Tenant }> = {
  async decorate(overviewResult: OverviewResult, tenantId: string): Promise<{ readonly _tenant: Tenant }> {
    if (overviewResult._tenant) {
      return { _tenant: overviewResult._tenant };
    }

    const tenant = await getTenantInformation(tenantId);
    return {
      _tenant:
        tenant ||
        ({
          id: tenantId,
          name: tenantId,
          companyLanguage: "en",
          features: [],
          showDev: false,
          showTraining: false
        } satisfies Tenant)
    };
  }
};
