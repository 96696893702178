import React, { useEffect, useState, useMemo } from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { AlertProps } from "@material-ui/lab/Alert/Alert";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStylesInfo = makeStyles((theme: any) => ({
  root: {
    alignItems: "self-start",
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.alert.infoBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.info
    },
    borderRadius: 6
  }
}));

const useStylesSuccess = makeStyles((theme: any) => ({
  root: {
    alignItems: "self-start",
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.alert.successBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.success
    },
    borderRadius: 6
  }
}));

const useStylesWarning = makeStyles((theme: any) => ({
  root: {
    alignItems: "self-start",
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.alert.warningBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.warning
    },
    borderRadius: 6
  }
}));

const useStylesError = makeStyles((theme: any) => ({
  root: {
    alignItems: "self-start",
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.alert.errorBackground,
    "& .MuiAlert-icon": {
      color: theme.palette.alert.error
    },
    paddingRight: "25px",
    paddingBottom: "8px",
    borderRadius: 6
  }
}));

const useStylesFullWidthMessage = makeStyles(() => ({
  message: {
    width: "100%",
    fontWeight: 400
  }
}));

export interface CustomAlertProps extends Pick<AlertProps, "severity" | "onClose" | "icon" | "id" | "style"> {
  readonly children?: React.ReactNode;
  readonly fullWidth?: boolean;
}

export default function CustomAlert({ severity, onClose, icon, id, children, style, fullWidth }: CustomAlertProps) {
  const [classes, setClasses] = useState<AlertProps["classes"]>({});
  const infoClasses = useStylesInfo();
  const successClasses = useStylesSuccess();
  const warningClasses = useStylesWarning();
  const errorClasses = useStylesError();
  const fullWidthClases = useStylesFullWidthMessage();
  useEffect(() => {
    const fullWidthClasses = fullWidth ? fullWidthClases : {};
    switch (severity) {
      case "info":
        setClasses({
          ...fullWidthClasses,
          ...infoClasses
        });
        break;
      case "warning":
        setClasses({
          ...fullWidthClasses,
          ...warningClasses
        });
        break;
      case "success":
        setClasses({
          ...fullWidthClasses,
          ...successClasses
        });
        break;
      case "error":
        setClasses({
          ...fullWidthClasses,
          ...errorClasses
        });
        break;
      default:
        setClasses({
          ...fullWidthClasses,
          ...infoClasses
        });
    }
  }, [severity, infoClasses, successClasses, warningClasses, errorClasses, fullWidth, fullWidthClases]);

  const newIcon = useMemo(() => {
    switch (severity) {
      case "info":
        return <InfoIcon style={{ color: "theme.palette.alert.info", marginTop: "1px" }} />;
      case "warning":
        return <WarningIcon style={{ color: "theme.palette.alert.warning", marginTop: "1px" }} />;
      case "success":
        return <CheckCircleIcon style={{ color: "theme.palette.alert.success", marginTop: "1px" }} />;
      case "error":
        return <ErrorIcon style={{ color: "theme.palette.alert.error", marginTop: "1px" }} />;
      default:
        return <ErrorIcon style={{ color: "theme.palette.alert.error", marginTop: "1px" }} />;
    }
  }, [severity]);

  return (
    <Alert classes={classes} style={style} severity={severity} onClose={onClose} icon={newIcon} id={id}>
      {children}
    </Alert>
  );
}
