import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMetaView } from "../../../contexts/meta-view-context";
import QuestionnaireSubHeader from "components/QuestionnaireSubHeader/QuestionnaireSubHeader";
import { useParams } from "react-router-dom";
import { useAssetRisksOptions, useGetAssetApi, useUpdateAssetApi } from "../../../api/assetApi";
import { useUpdateQueues } from "../../../../hook/useUpdateQueues";
import { UpdateAssetDTO } from "../../../api/generated/asset-service";
import { Box } from "@mui/material";
import { RiskIdsAccordion } from "../../questionnaires/risks-page/RiskIdsAccordion";
import { useUserDepartments } from "../../../contexts/department-context";
import { CircularProgress } from "@material-ui/core";

const AssetRisks = ({ readonly }: { readonly readonly?: boolean }) => {
  const { id } = useParams();
  const { data: asset } = useGetAssetApi({ documentId: id || "" });
  const { updateAsset } = useUpdateAssetApi({ documentId: id || "" });
  const { queueUpdates } = useUpdateQueues<UpdateAssetDTO, UpdateAssetDTO>({
    triggerUpdate: updateAsset
  });
  const { expandDepartmentIds } = useUserDepartments();

  const { t } = useTranslation("asset_details");
  const { setInfo } = useMetaView();

  const infoCard = useMemo(
    () => ({
      entering: {
        title: t("enteringInfoCardTitle"),
        text: t("enteringInfoCardText")
      }
    }),
    [t]
  );
  useEffect(() => {
    setInfo(infoCard.entering);
  }, [infoCard, setInfo]);

  const [riskIds, setRiskIDs] = useState<string[]>([]);

  useEffect(() => {
    if (asset) {
      setRiskIDs(asset.riskIds || []);
    }
  }, [asset]);

  const updateRiskIdsCallback = useCallback(
    async (riskIds: string[]) => {
      setRiskIDs(riskIds);
      return queueUpdates({ riskIds });
    },
    [queueUpdates]
  );

  const expandedOrgUnitIds = useMemo<string[]>(
    () => [
      ...expandDepartmentIds([asset?.mainOrgUnitId, ...(asset?.orgUnitIds || [])].filter((it): it is string => !!it))
    ],
    [asset?.mainOrgUnitId, asset?.orgUnitIds, expandDepartmentIds]
  );

  const { data: riskOptionsData, isLoading: risksDataLoading } = useAssetRisksOptions({ documentId: id || "" });
  const riskOptions = useMemo(() => riskOptionsData?.risks || [], [riskOptionsData]);

  if (!asset) {
    return <></>;
  }

  return (
    <Box mr={6} ml={6}>
      <Box>
        <QuestionnaireSubHeader text={t("dpia_four_four_page:title")} />
      </Box>
      {risksDataLoading ? (
        <CircularProgress size={24} />
      ) : (
        <RiskIdsAccordion
          title={t("title")}
          onRiskIdsChange={updateRiskIdsCallback}
          riskOptions={riskOptions}
          riskIds={riskIds}
          documentId={id || ""}
          type={"asset"}
          disabled={!!readonly}
          expandedOrgUnitIds={expandedOrgUnitIds}
          newRiskOrgUnitId={asset?.mainOrgUnitId || undefined}
        />
      )}
    </Box>
  );
};

export default AssetRisks;
