import { AxiosInstance } from "axios";
import { apiEndpoints } from "app/api/apiEndpoint";
import { isAxiosErrorWithCode, WithAbortController } from "../axios/axiosErrorHandler";
import { defaultOTCAuthenticatedAxios } from "../axios/loggedInAxiosProvider";

export const axiosInstance: AxiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.userUrl}/api/users`
});

export interface ExternalUserDTO {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
  readonly companyName?: string;
  readonly inviteMessage?: string;
}

export interface UserDTO {
  readonly id?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
  readonly userRole?: string;
  readonly orgUnitId?: string;
  readonly furtherOrgUnitIds?: string[];
  readonly exposedOrgUnitIds?: string[];
  readonly featureIds?: string[];
  readonly groupIds?: string[];
  readonly assignableFields?: ASSIGNABLE_USER_FIELD[];
}

export interface UsersDTO {
  readonly users: UserDTO[];
}

export interface SingleTenantNewUser {
  readonly email: string;
  readonly userRole: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly orgUnitId: string;
  readonly furtherOrgUnitIds?: string[];
  readonly exposedOrgUnitIds?: string[];
  readonly featureIds: string[];
  readonly groupIds?: string[];
}

export type SingleTenantUpdateUser = Omit<Partial<SingleTenantNewUser>, "email">;

export type ASSIGNABLE_USER_FIELD = "privacyExpert" | "riskOwner" | "leadAuditor" | "auditor" | "dpo";
export const AllAssignableUserFields: string[] = ["privacyExpert", "dpo", "riskOwner", "leadAuditor", "auditor"];

export const getUsersApi = async (options: WithAbortController = {}): Promise<UserDTO[]> => {
  const response = await axiosInstance.get<UsersDTO>("/", {
    signal: options.abortController?.signal
  });
  return response.data?.users || [];
};

export const getSingleUserApi = async (userId: string, isGroupFeaturesEnabled?: boolean): Promise<UserDTO | null> => {
  try {
    const response = await axiosInstance.get<UserDTO | null>(`/${userId}`, {
      params: {
        includeGroups: isGroupFeaturesEnabled
      }
    });
    return response.data || null;
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404, "NotFound")) {
      return null;
    }
    throw error;
  }
};

export const createUserApi = async (payload: SingleTenantNewUser): Promise<string> => {
  const response = await axiosInstance.post("/", payload);
  return response.headers["x-resource-id"] || "";
};

export const updateUserApi = async (userId: string, payload: SingleTenantUpdateUser): Promise<void> => {
  await axiosInstance.patch(`/${userId}`, payload);
};

export const deleteUserApi = async (userId: string): Promise<void> => {
  await axiosInstance.delete(`/${userId}`);
};

export const inviteExternalUserApi = async (payload: ExternalUserDTO) => {
  const response = await axiosInstance.post("/external", payload);
  return response.headers["x-resource-id"] || "";
};
