export type ASSET_STATUS_TYPES = "PLANNED" | "TESTING" | "ACTIVE" | "INACTIVE";
export const ASSET_STATUS = {
  PLANNED: "PLANNED",
  TESTING: "TESTING",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
} as const satisfies Record<string, ASSET_STATUS_TYPES>;

export type ASSET_TYPE_TYPES = "software" | "hardware" | "service" | "infrastructure" | "ai-system";
export const ASSET_TYPE = {
  SOFTWARE: "software",
  HARDWARE: "hardware",
  SERVICE: "service",
  INFRASTRUCTURE: "infrastructure",
  AI_SYSTEM: "ai-system"
} as const satisfies Record<string, ASSET_TYPE_TYPES>;

export interface AssetDTO {
  readonly id: string;
  readonly assetId: number;
  readonly name: string;
  readonly description: string;
  readonly status: ASSET_STATUS_TYPES;
  readonly resourceAssetTypeId?: string | null;
  readonly assetOwnerId: string | null;
  readonly riskOwnerId: string | null;
  readonly labelIds: string[];
  readonly orgUnitIds: string[];
  readonly dataLocationIds?: string[];
  readonly amount: number;
  readonly externalRecipientIds: string[];
}
export interface AssetShortDTO {
  readonly id: string;
  readonly name: string;
  readonly status: ASSET_STATUS_TYPES;
}

export type AssetUpdateDTO = Partial<AssetDTO>;
export interface AssetCreateDTO extends Omit<AssetUpdateDTO, "name" | "id"> {
  readonly name: string;
}
