import {
  copyRiskApi,
  createRiskApi,
  getRiskApi,
  getRisksApi,
  RiskDTO,
  RiskPayloadDTO,
  updateRiskApi
} from "../api/riskApi";
import { retryUntil } from "./utility/retry";
import { uniq } from "lodash-es";
import { isAxiosErrorWithCode } from "../api/axios/axiosErrorHandler";
import { ProcessingActivityOverviewDTO } from "../api/paApi";
import { TomModelDTO } from "../api/tomApi";
import { AssetOverviewDTO } from "../api/generated/asset-service";

export function getAllRisks() {
  return getRisksApi();
}

export async function getRisk(id: string) {
  try {
    return await getRiskApi(id);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      // 404 is not found, just return null to determine that the data breach does not exists
      return null;
    }
    throw error;
  }
}

export function awaitRiskVersion(
  id: string,
  {
    riskVersion,
    assessmentsVersion,
    treatmentVersion
  }: {
    riskVersion?: number;
    assessmentsVersion?: Record<"first" | "second", Record<string, number>>;
    treatmentVersion?: number;
  } = {}
) {
  return retryUntil(async () => {
    const risk = await getRisk(id);
    if (!risk) {
      throw new Error(`Risk still not exists ${id}`);
    }

    if (riskVersion !== undefined && risk.version < riskVersion) {
      throw new Error(`Existing risk version ${risk.version}, waiting for version ${riskVersion}. Risk ${id}.`);
    }

    if (assessmentsVersion) {
      for (const [phaseId, versions] of Object.entries(assessmentsVersion)) {
        if (phaseId !== "first" && phaseId !== "second") {
          throw new Error(`Invalid phaseId ${phaseId}`);
        }

        const allAssessmentsInThisPhase = [
          ...(risk.assessments[phaseId].individualAssessments || []),
          risk.assessments[phaseId].combinedAssessment
        ].filter(assessment => assessment);

        for (const [protectionObjectiveId, minVersion] of Object.entries(versions)) {
          const assessment = allAssessmentsInThisPhase.find(
            assessment => assessment?.protectionObjectiveId === protectionObjectiveId
          );
          if (assessment === undefined || assessment.version < minVersion) {
            throw new Error(
              `Existing risk ${protectionObjectiveId} assessment version ${assessment?.version}, waiting for version ${minVersion}. Risk ${id}.`
            );
          }
        }
      }
    }

    if (
      treatmentVersion !== undefined &&
      (risk.treatment === undefined || (risk.treatment && risk.treatment?.version < treatmentVersion))
    ) {
      throw new Error(
        `Existing risk treatment version ${risk.treatment?.version}, waiting for version ${treatmentVersion}. Risk ${id}.`
      );
    }

    return risk;
  });
}

export function createRisk(title: string, additionalProperties: Partial<Omit<RiskPayloadDTO, "title">> = {}) {
  return createRiskApi(title, additionalProperties);
}

export async function updateRisk(
  id: string,
  version: number,
  {
    title,
    type,
    orgUnitId,
    furtherAffectedOrgUnitIds,
    description,
    ownerUID,
    privacyRelevant,
    riskSourceIds,
    riskAssetIds,
    dataLocationIds,
    protectionObjectiveIds,
    implementedMeasureIds,
    labelIds
  }: Partial<RiskPayloadDTO> = {}
) {
  try {
    return await updateRiskApi(id, version, {
      title,
      type,
      orgUnitId,
      furtherAffectedOrgUnitIds,
      description,
      ownerUID,
      privacyRelevant,
      riskSourceIds,
      riskAssetIds,
      dataLocationIds,
      protectionObjectiveIds,
      implementedMeasureIds,
      labelIds
    });
  } catch (error) {
    if (isAxiosErrorWithCode(error, 409)) {
      throw new RiskVersionError(id, version);
    }
    throw error;
  }
}

export const RISK_LEVEL = {
  notApplicable: "notApplicable",
  low: "low",
  medium: "medium",
  high: "high",
  veryHigh: "veryHigh"
};

export function riskRatingToLevel(rating?: number) {
  if (rating === undefined || rating === null || !isFinite(rating)) {
    return RISK_LEVEL.notApplicable;
  }

  if (rating < 4) {
    return RISK_LEVEL.low;
  }
  if (rating >= 4 && rating <= 8) {
    return RISK_LEVEL.medium;
  }
  if (rating > 8 && rating <= 12) {
    return RISK_LEVEL.high;
  }
  if (rating > 12) {
    return RISK_LEVEL.veryHigh;
  }
}

export function incrementVersion(existingVersion?: number | null) {
  if (existingVersion === null || existingVersion === undefined || !isFinite(existingVersion)) {
    return 0;
  }

  return existingVersion + 1;
}

export function tenantRiskId(risk: Pick<RiskDTO, "tenantRiskId">) {
  return `RI-${risk.tenantRiskId}`;
}

export class RiskVersionError extends Error {
  constructor(riskId: string, version?: number | null) {
    super(`Risk ${riskId} latest version is not ${version}`);
  }
}

export const RISK_TREATMENT_TYPES = {
  measures: "measures",
  transfer: "transfer",
  prevention: "prevention",
  accept: "accept"
};

export async function copyRiskAsSpecific(riskId: string, type: "processing-activity" | "asset", orgUnitId?: string) {
  const copiedRiskId = await copyRiskApi(riskId);
  await awaitRiskVersion(copiedRiskId, { riskVersion: 0 });
  await updateRisk(copiedRiskId, 0, { type: type, privacyRelevant: true, orgUnitId });
  return await awaitRiskVersion(copiedRiskId, { riskVersion: 1 });
}

export function risksAvailableForPAorAssets(input: {
  type: "general" | "processing-activity" | "asset";
  risks: RiskDTO[];
  processes: ProcessingActivityOverviewDTO[];
  assets: AssetOverviewDTO[];
  currentDocumentId: string;
  expandedOrgUnitIds: null | Set<string>;
}) {
  const { type, risks, processes, assets, currentDocumentId } = input;
  const generalRisks: RiskDTO[] = [];
  const processRisks: RiskDTO[] = [];
  const assetRisks: RiskDTO[] = [];

  for (const risk of risks) {
    if (risk.type === "general") {
      generalRisks.push(risk);
    }
    if (risk.type === "processing-activity") {
      processRisks.push(risk);
    }
    if (risk.type === "asset") {
      assetRisks.push(risk);
    }
  }

  const specificRisksWithoutAssignment =
    type === "processing-activity"
      ? getProcessUnassignedRisks(processRisks, processes, currentDocumentId)
      : type === "asset"
        ? getAssetUnassignedRisks(assetRisks, assets, currentDocumentId)
        : [];

  const combinedRisks = [...generalRisks, ...specificRisksWithoutAssignment];
  if (!input.expandedOrgUnitIds) {
    return combinedRisks;
  }

  const filteredRisks: RiskDTO[] = [];
  for (const risk of combinedRisks) {
    if (!risk.orgUnitId) {
      // if empty, means accessible for all
      filteredRisks.push(risk);
      continue;
    }
    if (input.expandedOrgUnitIds.has(risk.orgUnitId)) {
      filteredRisks.push(risk);
      continue;
    }
    if (risk.furtherAffectedOrgUnitIds.some(orgUnitId => input.expandedOrgUnitIds?.has(orgUnitId))) {
      filteredRisks.push(risk);
    }
  }
  return filteredRisks;
}

const getProcessUnassignedRisks = (
  processRisks: RiskDTO[],
  processes: ProcessingActivityOverviewDTO[],
  processId: string
) => {
  const riskIdsWithProcess = processes.reduce((riskIdsSet, nextProcess) => {
    if (nextProcess.id === processId) {
      return riskIdsSet; // if it's current process, we treat as if the risks are not yet assigned
    }

    for (const riskId of nextProcess.allRiskIds || []) {
      riskIdsSet.add(riskId);
    }
    return riskIdsSet;
  }, new Set());
  const currentProcess = processId ? processes.find(process => process.id === processId) : null;
  const currentProcessRiskIds = currentProcess?.allRiskIds || [];
  return processRisks.filter(
    // only show process risk which is not yet assigned to any process
    // or is assigned to the current process for whatever reason (as we don't want to hide them)
    processRisk => !riskIdsWithProcess.has(processRisk.id) || currentProcessRiskIds.includes(processRisk.id)
  );
};

const getAssetUnassignedRisks = (assetRisks: RiskDTO[], assets: AssetOverviewDTO[], assetId: string) => {
  const riskIdsWithAsset = assets.reduce((riskIdsSet, nextAsset) => {
    if (nextAsset.id === assetId) {
      return riskIdsSet; // if it's current asset, we treat as if the risks are not yet assigned
    }

    for (const riskId of nextAsset.riskIds || []) {
      riskIdsSet.add(riskId);
    }
    return riskIdsSet;
  }, new Set());
  const currentAsset = assetId ? assets.find(asset => asset.id === assetId) : null;
  const currentAssetRiskIds = currentAsset?.riskIds || [];
  return assetRisks.filter(
    // only show process risk which is not yet assigned to any process
    // or is assigned to the current process for whatever reason (as we don't want to hide them)
    assetRisk => !riskIdsWithAsset.has(assetRisk.id) || currentAssetRiskIds.includes(assetRisk.id)
  );
};

export function measuresAvailableForRisks(
  measure: Pick<TomModelDTO, "protectionObjectiveIds">,
  risk: Pick<RiskDTO, "protectionObjectiveIds">
) {
  const riskProtectionObjectiveIds = risk.protectionObjectiveIds || [];
  const measureProtectionObjectiveIds = measure.protectionObjectiveIds || [];
  return riskProtectionObjectiveIds.some(riskProtectionObjectiveId =>
    measureProtectionObjectiveIds.includes(riskProtectionObjectiveId)
  );
}

export const connectedMeasuresByProtectionObjectiveInRisk = <
  T extends {
    readonly id: string;
    readonly protectionObjectiveIds?: string[];
  }
>(
  protectionObjectiveId: string,
  risk: Pick<RiskDTO, "implementedMeasureIds" | "treatment">,
  measures: T[]
): T[] => {
  const allUsedMeasureIDs = uniq([...(risk.implementedMeasureIds || []), ...(risk.treatment?.measureIds || [])]);
  const allUsedMeasures = allUsedMeasureIDs
    .map(measureID => measures.find(measure => measure.id === measureID))
    .flatMap(measure => measure || []);
  return allUsedMeasures.filter(measure => (measure.protectionObjectiveIds || []).includes(protectionObjectiveId));
};

export function getAssessmentWithHighestRating(risk: RiskDTO, phaseId: "first" | "second") {
  for (const assessment of risk?.assessments?.[phaseId]?.individualAssessments || []) {
    if (assessment.rating === risk?.assessments?.[phaseId]?.rating) {
      return assessment;
    }
  }
}
