import { useCallback, useMemo } from "react";
import ListViewItem, { ItemStatus } from "components/ListViewItem/ListViewItem";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { Box } from "@mui/material";
import { useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { SxProps } from "@mui/system/styleFunctionSx";
import { LazySvgIcon } from "../../../LazySvgIcon/LazySvgIcon";
import { useTranslation } from "react-i18next";

export default function ProcessesOverviewReadOnlyRow({ item }: CustomRowComponentProps<OverviewItem>) {
  const overviewSetup = useOverviewState()[COLLECTIONS.PROCESSES];

  const handleClick = useCallback(() => {
    window.open(`/processes/${item.id}/general`, "_blank");
  }, [item.id]);

  const { t } = useTranslation();
  const paStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`process_status:edit`),
        status: "edit"
      },
      {
        icon: <LazySvgIcon name="In_Review" />,
        label: t(`process_status:review`),
        status: "review"
      },
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`process_status:approved`),
        status: "approved"
      }
    ],
    [t]
  );

  return (
    <Box onClick={handleClick} sx={pointer}>
      <ListViewItem
        deletable={false}
        icon={item.icon}
        reserveCheckboxSpace={true}
        searchText={overviewSetup.search}
        hasCheckbox={false}
        allowedStatuses={paStatuses}
        status={item.status}
        subTitle={item.subTitle}
        title={item.title}
      />
    </Box>
  );
}

const pointer: SxProps = {
  cursor: "pointer"
};
