import { ErrorOutline, ExpandMore } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Typography,
  MenuItem,
  Skeleton,
  TextField,
  Stack,
  Dialog,
  Badge,
  useTheme,
  ListItemText,
  ListItemIcon,
  Menu,
  Chip,
  CircularProgress
} from "@mui/material";
import { getTranslationAndSourceLanguage } from "app/api/deepLApi";
import { useMissingTranslations, useMultilingualEditor, useSupportedLanguages } from "app/api/multilingualApi";
import { FEATURES } from "app/features";
import { isAxiosError } from "axios";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { usePromisedCallback } from "components/ListViewItem/usePromisedCallback";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

export type MultilingualEditorModalProps = {
  translationKey: string;
  onClose: () => void;
  onAdd: (mainTranslation: string) => Promise<string>;
  title?: string;
};

export const NEW_TRANSLATION_KEY = "new-translation-key";

export const useTLng = () => {
  const [t] = useTranslation();
  const tLng = (lng: string) => t(`sidebar:${lng}`);
  return tLng;
};

export default function MultilingualEditorModal({
  onClose,
  translationKey,
  onAdd,
  title
}: MultilingualEditorModalProps) {
  const [t, i18n] = useTranslation();
  const supportedLanguages = useSupportedLanguages();
  const isMultilingualAutoTranslateEnabled = useIsFeaturePresent(FEATURES.MULTILINGUAL_AUTO_TRANSLATE);
  const {
    translations,
    updateTranslations,
    isLoading: isTranslationLoading
  } = useMultilingualEditor({ translationKey });
  const [state, setState] = useState({
    translationForm: translations,
    currentLanguage: i18n.language,
    aiGeneratedTranslations: {} as Record<string, string>
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isAiGeneratedHelpText = useMemo(() => {
    const currentTranslation = state.translationForm[state.currentLanguage];
    const aiGeneratedTranslation = state.aiGeneratedTranslations[state.currentLanguage];
    if (!currentTranslation || !aiGeneratedTranslation) return false;
    return currentTranslation === aiGeneratedTranslation;
  }, [state.aiGeneratedTranslations, state.currentLanguage, state.translationForm]);
  const { untranslatedLngs: _untranslatedLngs, reloadMissingTranslations } = useMissingTranslations(translationKey);
  const untranslatedLngs = useMemo(
    () => _untranslatedLngs.filter(lng => state.translationForm[lng] === ""),
    [_untranslatedLngs, state.translationForm]
  );
  const untranslatedLngsSet = useMemo(() => new Set(untranslatedLngs), [untranslatedLngs]);
  const untranslatedCount = untranslatedLngs.length;
  const languageCodes = useMemo(() => Object.keys(translations), [translations]);
  const languagesToTranslate = untranslatedCount > 0 ? untranslatedLngs : languageCodes;
  useEffect(
    function updateTranslations() {
      setState(state => ({ ...state, translationForm: translations }));
    },
    [translations]
  );
  useEffect(
    function setCurrentLanguageIfDefaultEmpty() {
      if (supportedLanguages.data?.mainLanguage && !isTranslationLoading) {
        setState(state => ({
          ...state,
          currentLanguage: state.translationForm[state.currentLanguage]
            ? state.currentLanguage
            : supportedLanguages.data?.mainLanguage || state.currentLanguage
        }));
      }
    },
    [supportedLanguages.data?.mainLanguage, isTranslationLoading]
  );
  const handleLanguageChange = useCallback((langCode: string) => {
    setState(state => ({ ...state, currentLanguage: langCode }));
    setAnchorEl(null);
  }, []);
  const handleLangDropdownClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleLangDropdownOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const isInMainLanguage = state.currentLanguage === supportedLanguages.data?.mainLanguage;
  const handleTranslationChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setState(state => ({
      ...state,
      translationForm: state.currentLanguage
        ? {
            ...state.translationForm,
            [state.currentLanguage]: event.target.value
          }
        : state.translationForm
    }));
  }, []);
  const handleSubmission = usePromisedCallback(
    useCallback(async () => {
      try {
        let id: string | undefined;
        if (translationKey === NEW_TRANSLATION_KEY) {
          id = await onAdd(state.translationForm[supportedLanguages.data?.mainLanguage || ""]);
        }

        await updateTranslations(state.translationForm, id);
        onClose();
      } catch (err) {
        if (
          isAxiosError(err) &&
          (err?.response?.status === 409 || err?.response?.data?.message === "Translation duplicate")
        ) {
          setState(state => ({
            ...state,
            aiGeneratedTranslations: {},
            translationForm: {
              ...state.translationForm,
              ...Object.keys(state.aiGeneratedTranslations).reduce(
                (acc, key) => {
                  acc[key] = "";
                  return acc;
                },
                {} as Record<string, string>
              )
            }
          }));
          throw new Error(
            t("multilingual:translationConflict", {
              title: title ?? ""
            })
          );
        }
        throw err;
      } finally {
        await reloadMissingTranslations();
      }
    }, [
      state.translationForm,
      updateTranslations,
      onClose,
      onAdd,
      translationKey,
      supportedLanguages.data,
      t,
      title,
      reloadMissingTranslations
    ])
  );
  const tLng = useTLng();
  const languagesDropdown = useMemo(() => {
    const lngs: Record<string, string> = {};
    if (supportedLanguages.data?.mainLanguage) {
      lngs[supportedLanguages.data.mainLanguage] =
        `${tLng(supportedLanguages.data.mainLanguage)} (${t("common:default")})`;
    }
    if (supportedLanguages.data?.supportedLanguages) {
      supportedLanguages.data.supportedLanguages.forEach(lng => {
        lngs[lng] = tLng(lng);
      });
    }
    return lngs;
  }, [t, supportedLanguages.data, tLng]);
  const isLoading = supportedLanguages.isLoading || isTranslationLoading;
  const isDefaultLanguageEmpty = !state.translationForm[supportedLanguages.data?.mainLanguage || ""];
  const mainTranslation = state.translationForm[supportedLanguages.data?.mainLanguage || ""];
  const { trigger: triggerAutoTranslate, pending: isAutoTranslationPending } = usePromisedCallback(
    useCallback(async () => {
      const mainLanguage = supportedLanguages?.data?.mainLanguage;
      if (!mainLanguage) {
        return;
      }
      const translations = await Promise.all(
        languagesToTranslate.map(async languageCode => {
          const translation = await getTranslationAndSourceLanguage(languageCode, mainTranslation, mainLanguage)
            .then(response => response?.translations[0]?.text || null)
            .catch(err => {
              if (isAxiosError(err) && err.response?.status === 400) {
                return null;
              }
              throw err;
            });
          return [languageCode, translation] as [string, string | undefined];
        })
      ).then(translations =>
        translations.reduce(
          (acc, [languageCode, translation]) => {
            if (translation) {
              acc[languageCode] = translation;
            }
            return acc;
          },
          {} as Record<string, string>
        )
      );
      setState(state => ({
        ...state,
        translationForm: { ...state.translationForm, ...translations },
        aiGeneratedTranslations: translations
      }));
    }, [mainTranslation, supportedLanguages.data?.mainLanguage, languagesToTranslate])
  );
  const theme = useTheme();
  const currentLanguageTranslation = state.translationForm[state.currentLanguage] || "";
  const showAutoTranslateButton =
    isInMainLanguage && isMultilingualAutoTranslateEnabled && !isDefaultLanguageEmpty && untranslatedCount > 0;
  const disableSaveButton =
    !currentLanguageTranslation || handleSubmission.pending || isDefaultLanguageEmpty || isAutoTranslationPending;
  const showSingleTranslationSuggestion =
    isMultilingualAutoTranslateEnabled &&
    !currentLanguageTranslation &&
    state.currentLanguage !== supportedLanguages.data?.mainLanguage;
  const currentLanguageTranslationSuggestion = useSWR(
    ["suggest-translation", state.currentLanguage, mainTranslation, showSingleTranslationSuggestion],
    async () => {
      if (!mainTranslation || !showSingleTranslationSuggestion || !isMultilingualAutoTranslateEnabled) {
        return null;
      }
      return getTranslationAndSourceLanguage(
        state.currentLanguage,
        mainTranslation,
        supportedLanguages.data?.mainLanguage || ""
      );
    }
  );
  const currentTranslationSuggestionText = currentLanguageTranslationSuggestion.data?.translations[0].text;
  const handleSetSuggestionAsTranslation = useCallback(() => {
    if (currentTranslationSuggestionText) {
      setState(state => ({
        ...state,
        translationForm: {
          ...state.translationForm,
          [state.currentLanguage]: currentTranslationSuggestionText
        }
      }));
    }
  }, [currentTranslationSuggestionText]);
  return (
    <Dialog open={true} onClose={onClose}>
      <Box p={2} width="480px">
        {isLoading ? (
          <Skeleton />
        ) : (
          <div>
            <Stack spacing={2}>
              <Typography variant="h5">
                {t(translationKey === NEW_TRANSLATION_KEY ? "common:new" : "common:modify", {
                  title: title ?? ""
                })}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <LazySvgIcon color="grey.500" name="Globe" />
                <Box>
                  <Chip
                    label={languagesDropdown[state.currentLanguage]}
                    onDelete={handleLangDropdownOpen}
                    onClick={handleLangDropdownOpen}
                    deleteIcon={<ExpandMore sx={{ cursor: "pointer" }} />}
                  />
                  <Menu anchorEl={anchorEl} open={open} onClose={handleLangDropdownClose} sx={{ maxHeight: 400 }}>
                    {Object.entries(languagesDropdown).map(([languageCode, lng]) => (
                      <MenuItem
                        key={languageCode}
                        value={languageCode}
                        selected={state.currentLanguage === languageCode}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => handleLanguageChange(languageCode)}
                      >
                        <ListItemText>{lng}</ListItemText>
                        {untranslatedLngsSet.has(languageCode) ? (
                          <ListItemIcon
                            sx={{
                              justifyContent: "end"
                            }}
                          >
                            <ErrorOutline fontSize="small" />
                          </ListItemIcon>
                        ) : null}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                {untranslatedCount > 0 && !isDefaultLanguageEmpty && (
                  <Badge style={{ width: 12 }} badgeContent={untranslatedCount} color="error" />
                )}
              </Box>
              <Box>
                <TextField
                  label={title}
                  key={state.currentLanguage}
                  value={currentLanguageTranslation}
                  onChange={handleTranslationChange}
                  fullWidth
                  error={handleSubmission.error instanceof Error}
                  helperText={
                    isAiGeneratedHelpText ? t("multilingual:aiGeneratedHelpText") : handleSubmission.error?.message
                  }
                />
                {isDefaultLanguageEmpty && (
                  <Alert severity="warning" sx={{ mt: 2 }}>
                    {t("multilingual:defaultLanguageEmpty", {
                      language: t(`sidebar:${supportedLanguages.data?.mainLanguage}`)
                    })}
                  </Alert>
                )}
              </Box>
              {showAutoTranslateButton && (
                <Box>
                  <Button
                    onClick={triggerAutoTranslate}
                    disabled={isAutoTranslationPending}
                    variant="text"
                    style={{
                      backgroundColor: theme.palette.grey[100],
                      color: theme.palette.text.primary
                    }}
                    sx={{
                      ":disabled": {
                        color: theme.palette.text.disabled + " !important"
                      }
                    }}
                    startIcon={<LazySvgIcon color="gray.400" name="AiAssistant" />}
                  >
                    {isAutoTranslationPending
                      ? t("multilingual:autoTranslatePending")
                      : untranslatedCount > 0
                        ? t("multilingual:autoTranslateMissing", { count: untranslatedCount })
                        : t("multilingual:autoTranslateAll")}
                  </Button>
                </Box>
              )}
              {showSingleTranslationSuggestion && (
                <Stack direction="row" alignItems={"center"} gap={2}>
                  <Typography>{t("multilingual:suggestion")}: </Typography>
                  {currentLanguageTranslationSuggestion.isValidating ? (
                    <CircularProgress size="24px" />
                  ) : (
                    <Button
                      onClick={handleSetSuggestionAsTranslation}
                      disabled={!currentTranslationSuggestionText}
                      variant="text"
                      style={{
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.text.primary
                      }}
                      sx={{
                        ":disabled": {
                          color: theme.palette.text.disabled + " !important"
                        }
                      }}
                    >
                      {currentTranslationSuggestionText ?? "—"}
                    </Button>
                  )}
                </Stack>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2
                }}
              >
                <Button onClick={onClose}>{t("common:cancel")}</Button>
                <Button onClick={handleSubmission.trigger} disabled={disableSaveButton} variant="contained">
                  {t("common:save")}
                </Button>
              </Box>
            </Stack>
          </div>
        )}
      </Box>
    </Dialog>
  );
}
