import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { MultiPicker, MultiPickerProps } from "./MultiPicker";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface MultiPickerDialogProps extends Omit<MultiPickerProps, "onChange"> {
  readonly title: React.ReactNode;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onConfirm: (value: string[]) => void;
}

export const MultiPickerDialog = ({
  title,
  open,
  onClose,
  onConfirm,
  value,
  options,
  groupsOrder,
  onOptionAdd,
  getOptionName,
  getOptionGroup,
  getIsOptionDisabled,
  allowAdd
}: MultiPickerDialogProps) => {
  const { t } = useTranslation("common");
  const [lastValue, setLastValue] = useState<string[]>([]);
  useEffect(() => {
    if (open) {
      setLastValue(value);
    }
  }, [open, value]);
  const onChange = useCallback(value => setLastValue(value), []);
  const onConfirmCallback = useCallback(() => {
    onConfirm(lastValue);
    onClose();
  }, [lastValue, onConfirm, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <MultiPicker
          value={lastValue}
          onChange={onChange}
          options={options}
          groupsOrder={groupsOrder}
          onOptionAdd={onOptionAdd}
          getOptionName={getOptionName}
          getOptionGroup={getOptionGroup}
          getIsOptionDisabled={getIsOptionDisabled}
          allowAdd={allowAdd}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {t("cancel")}
        </Button>
        <Button onClick={onConfirmCallback} variant="contained">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
