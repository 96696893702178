import {
  createResourceApi,
  deleteResourceApi,
  getAllResourcesApi,
  getNumberUnseenResourcesPerResourceTypeApi,
  getResourceApi,
  ResourceDTO,
  ResourcesUnseen,
  updateResourceApi
} from "../api/resourceApi";
import { tDeletedEntry } from "./dataTypeTranslatorHandler";
import { TFunction } from "i18next";
import { isAxiosErrorWithCode } from "../api/axios/axiosErrorHandler";

export type RESOURCE_TYPE =
  | "data-classification"
  | "data-deletion"
  | "doc-type"
  | "deletion-trigger"
  | "external-recipient-contract-type"
  | "dsr-request-type"
  | "asset-type"
  | "internal-recipient"
  | "protection-objective"
  | "encryption"
  | "pseudonymization"
  | "safeguard"
  | "derogation"
  | "databreach-detection"
  | "databreach-cause-type"
  | "databreach-measure-type"
  | "databreach-impact-type"
  | "label"
  | "legal-basis"
  | "legal-retention-period"
  | "processing-category"
  | "data-assets"
  | "data-locations"
  | "ai-role"
  | "ai-training-method";

export const RESOURCE_TYPES = {
  DATA_CLASSIFICATION: "data-classification",
  DATA_DELETION: "data-deletion",
  DOCUMENT_TYPE: "doc-type",
  DELETION_TRIGGER: "deletion-trigger",
  EXTERNAL_RECIPIENT_CONTRACT_TYPE: "external-recipient-contract-type",
  DSR_REQUEST_TYPE: "dsr-request-type",
  ASSET_TYPE: "asset-type",
  INTERNAL_RECIPIENT: "internal-recipient",
  PROTECTION_OBJECTIVE: "protection-objective",
  ENCRYPTION: "encryption",
  PSEUDONYMIZATION: "pseudonymization",
  SAFEGUARD: "safeguard",
  DEROGATION: "derogation",
  DATABREACH_DETECTION: "databreach-detection",
  DATABREACH_CAUSE: "databreach-cause-type",
  DATABREACH_MEASURE: "databreach-measure-type",
  DATABREACH_IMPACT: "databreach-impact-type",
  LABEL: "label",
  LEGAL_BASIS: "legal-basis",
  LEGAL_RETENTION_PERIOD: "legal-retention-period",
  PROCESSING_CATEGORY: "processing-category",
  DATA_ASSETS: "data-assets",
  DATA_LOCATIONS: "data-locations",
  AI_ROLE: "ai-role",
  AI_TRAINING_METHOD: "ai-training-method"
} as const satisfies Record<string, RESOURCE_TYPE>;

export const ALL_RESOURCE_TYPES: RESOURCE_TYPE[] = [...Object.values(RESOURCE_TYPES)];

export const translateResource = ({
  t,
  resourceType,
  nameKey
}: {
  t: TFunction;
  resourceType: RESOURCE_TYPE;
  nameKey: string;
}) => {
  return t(`resources_${resourceType}:${nameKey}`, nameKey);
};

export const translateResourceById = ({
  t,
  resourceType,
  resourceId,
  resources
}: {
  t: TFunction;
  resourceType: RESOURCE_TYPE;
  resourceId: string;
  resources: Map<string, Map<string, ResourceDTO>>;
}): string => {
  if (!resourceId) {
    return "";
  }
  const resource = resources.get(resourceType)?.get(resourceId);
  if (!resource?.nameKey) {
    return tDeletedEntry({ t });
  }
  const translatedName = translateResource({ t, resourceType, nameKey: resource.nameKey });
  if (!resource.mergedIntoId) {
    return translatedName;
  }

  const mergedInto = traverseResourceById({
    resourceType,
    resourceId,
    resourcesWithMerged: resources
  });
  if (!mergedInto) {
    return translatedName;
  }

  return `${translatedName} => ${translateResourceById({
    t,
    resourceType,
    resourceId: mergedInto.id,
    resources
  })}`;
};

export const traverseResourceById = ({
  resourceType,
  resourceId,
  resourcesWithMerged
}: {
  resourceType: RESOURCE_TYPE;
  resourceId: string;
  resourcesWithMerged: Map<string, Map<string, ResourceDTO>>;
}): ResourceDTO | null => {
  if (!resourceId) {
    return null;
  }
  let resource = resourcesWithMerged.get(resourceType)?.get(resourceId);
  while (resource?.mergedIntoId) {
    const currentMergedIntoId = resource.mergedIntoId;
    resource = resourcesWithMerged.get(resourceType)?.get(currentMergedIntoId);
  }
  return resource || null;
};

export const getAllResources = getAllResourcesApi;

export const createNewResource = (payload: {
  readonly resourceType: RESOURCE_TYPE;
  readonly nameKey: string;
}): Promise<string> => {
  return createResourceApi({
    resourceType: payload.resourceType,
    nameKey: payload.nameKey,
    wait: true
  });
};

export const renameResource = (payload: {
  readonly resourceType: RESOURCE_TYPE;
  readonly resourceId: string;
  readonly nameKey: string;
}): Promise<void> => {
  return updateResourceApi(payload.resourceType, payload.resourceId, {
    nameKey: payload.nameKey,
    wait: true
  });
};

export const updateResourceOrgUnits = (payload: {
  readonly resourceType: RESOURCE_TYPE;
  readonly resourceId: string;
  readonly orgUnitIds: string[];
}): Promise<void> => {
  return updateResourceApi(payload.resourceType, payload.resourceId, {
    orgUnitIds: payload.orgUnitIds
  });
};

export const deleteResource = (payload: {
  readonly resourceType: RESOURCE_TYPE;
  readonly resourceId: string;
}): Promise<void> => {
  return deleteResourceApi(payload.resourceType, payload.resourceId, {
    wait: true
  });
};

export const getNumberUnseenResourcesPerResourceType = async (): Promise<ResourcesUnseen[]> => {
  return await getNumberUnseenResourcesPerResourceTypeApi();
};

export const getSingleResource = async (
  resourceType: RESOURCE_TYPE,
  resourceId: string
): Promise<ResourceDTO | null> => {
  try {
    return await getResourceApi(resourceType, resourceId);
  } catch (error) {
    if (isAxiosErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

export const filterOutSetsIfNoFeatures = (resources: ResourceDTO[], tenantFeatures?: string[]) => {
  if (!tenantFeatures) {
    return resources.filter(resource => !resource.featureToggle);
  } else {
    return resources.filter(resource => !resource.featureToggle || tenantFeatures.includes(resource.featureToggle));
  }
};
