import { Box, Divider } from "@material-ui/core";
import TextBody2 from "components/TextBody2/TextBody2";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import { mergeDataLocationApi } from "../../api/externalRecipientApi";
import { DataLocationsPicker } from "./DataLocationsPicker";
import { ExternalRecipientPicker } from "./ExternalRecipientPicker";
import { useUserAndTenantData } from "../../handlers/userAndTenant/user-tenant-context";
import { COLLECTIONS } from "../../collections";
import { DataLocationAffectedProcesses } from "./DataLocationAffectedProcesses";

export interface DataLocationMergeItem {
  readonly id: string;
}
export interface DataLocationMergeModalProps {
  readonly selectedDataLocations: DataLocationMergeItem[];
  readonly onClose: () => void;
  readonly onCancel: () => void;
}
export const DataLocationMergeModal = ({ selectedDataLocations, onClose, onCancel }: DataLocationMergeModalProps) => {
  const { t } = useTranslation("service_providers_overview");
  const { addToSeenItemsOfUserHook } = useUserAndTenantData();

  const [selectedDataLocationIds, setSelectedDataLocationIds] = useState<string[]>([]);
  const [newName, setNewName] = useState("");
  const [externalRecipientId, setExternalRecipientId] = useState<string | null>(null);
  useEffect(() => {
    setSelectedDataLocationIds(selectedDataLocations.map(dl => dl.id));
    setNewName("");
    setExternalRecipientId(null);
  }, [selectedDataLocations]);

  const onNewNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value), []);

  const mergeDataLocations = useCallback(async () => {
    const mergedDataLocationId = await mergeDataLocationApi(
      {
        toMergeIDs: selectedDataLocationIds,
        nameKey: newName,
        externalRecipientId: externalRecipientId || null
      },
      {
        wait: true
      }
    );
    if (mergedDataLocationId) {
      await addToSeenItemsOfUserHook(COLLECTIONS.DATA_LOCATIONS, mergedDataLocationId);
    }
    onClose();
  }, [onClose, newName, selectedDataLocationIds, externalRecipientId, addToSeenItemsOfUserHook]);

  const [buttons, setButtons] = useState<ConfirmationModalButtonProps[]>([]);
  useEffect(
    () =>
      setButtons([
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onCancel
        },
        {
          confirmButton: true,
          title: t("common:merge"),
          variant: "contained",
          color: "primary",
          size: "medium",
          disabled: !newName,
          onClick: mergeDataLocations
        }
      ]),
    [onCancel, onClose, t, newName, mergeDataLocations]
  );

  const modalBody = (
    <Box mt={3} mb={4}>
      <Box>
        <TextBody2 text={t("datalocations_merge_confirmation")} />
        <Box mt={2}>
          <DataLocationsPicker selectedIDs={selectedDataLocationIds} onChange={setSelectedDataLocationIds} />
        </Box>
      </Box>
      <Box mt={3}>
        <TextBody2 text={t("datalocations_merge_name_input_title")} />
        <Box mt={2}>
          <TextField
            id={"new-datatype-name"}
            label={t("name")}
            fullWidth={true}
            variant="outlined"
            value={newName}
            onChange={onNewNameChanged}
            required={true}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <TextBody2 text={t("datalocations_merge_external_recipient_input_title")} />
        <ExternalRecipientPicker externalRecipientId={externalRecipientId} onChange={setExternalRecipientId} />
      </Box>
      <Box>
        <Box>
          <TextBody2 text={t("note")} />
        </Box>
        <Box>
          <TextBody2 text={t("datalocations_merge_name_input_title_note")} />
        </Box>
      </Box>
      {!!selectedDataLocationIds.length && (
        <Box mt={3}>
          <Box mb={1}>
            <TextBody2 text={t("affected_pas")} />
          </Box>
          <Divider />
          <Box>
            <DataLocationAffectedProcesses selectedDataLocationIds={selectedDataLocationIds} />
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );

  return (
    <ConfirmationModal
      modalOpen={!!selectedDataLocations.length}
      onClose={onCancel}
      modalTitle={t("datalocations_merge_title")}
      modalText={t("datalocations_merge_text")}
      buttons={buttons}
      modalBody={modalBody}
    />
  );
};
