import React, { MouseEventHandler, useCallback, useMemo } from "react";
import ListViewItem, { ItemStatus, RowAction } from "components/ListViewItem/ListViewItem";
import { OverviewItem } from "components/Overview/controllers/overviewBaseController";
import { CustomRowComponentProps } from "components/Overview/controls/OverviewRow";
import { Box } from "@mui/material";
import { useOverviewState } from "app/contexts/overview-context";
import { COLLECTIONS } from "app/collections";
import { SxProps } from "@mui/system/styleFunctionSx";
import { LazySvgIcon } from "../../../../components/LazySvgIcon/LazySvgIcon";
import { useTranslation } from "react-i18next";
import { AITechDocDTOStatusEnum } from "../../../api/generated/aitechdoc-service";
import GetApp from "@mui/icons-material/GetApp";
import { exportAITechDocsExcel } from "../../../export/createdExcelExportData";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";

export default function AITechDocsOverviewRow({
  checkable,
  checked,
  item,
  onChecked,
  onDelete,
  onClick,
  forceDisplayCheckbox
}: CustomRowComponentProps<OverviewItem>) {
  const { t } = useTranslation();
  const overviewSetup = useOverviewState()[COLLECTIONS.AI_TECH_DOCS];

  const aiTechDocStatuses: ItemStatus[] = useMemo(
    () => [
      {
        icon: <LazySvgIcon name="In_Progress" />,
        label: t(`aitechdoc_overview:status_${AITechDocDTOStatusEnum.Edit}`),
        status: AITechDocDTOStatusEnum.Edit
      },
      {
        icon: <LazySvgIcon name="In_Review" />,
        label: t(`aitechdoc_overview:status_${AITechDocDTOStatusEnum.Review}`),
        status: AITechDocDTOStatusEnum.Review
      },
      {
        icon: <LazySvgIcon name="Completed" />,
        label: t(`aitechdoc_overview:status_${AITechDocDTOStatusEnum.Approved}`),
        status: AITechDocDTOStatusEnum.Approved
      }
    ],
    [t]
  );

  const deleteItem = useCallback(async () => {
    await onDelete(item.id);
  }, [item.id, onDelete]);

  const deleteDisabled = useMemo(
    () => item.disableActions?.find(({ action }) => action === "remove"),
    [item.disableActions]
  );

  const handleClick = useCallback<MouseEventHandler>(
    event => {
      onClick(item, event);
    },
    [item, onClick]
  );

  const { auth } = useAuthentication();
  const onExportHandler = useCallback(() => {
    if (!auth?.tenantId) {
      return;
    }
    return exportAITechDocsExcel(auth.tenantId, t, [item.id]);
  }, [auth?.tenantId, item.id, t]);

  const rowActions = useMemo<RowAction[]>(() => {
    return [
      {
        action: "export-xlsx",
        title: t("overview:export_xls"),
        icon: <GetApp />,
        onHandle: onExportHandler,
        item
      }
    ] satisfies RowAction[];
  }, [item, onExportHandler, t]);

  return (
    <Box onClick={handleClick} sx={cursorPointer}>
      <ListViewItem
        deletable={!deleteDisabled}
        icon={item.icon}
        isNew={!item.seen}
        onDelete={deleteItem}
        onSelect={checkable ? onChecked : undefined}
        rowActions={rowActions}
        searchText={overviewSetup.search}
        selected={checked}
        allowedStatuses={aiTechDocStatuses}
        status={item.status}
        subTitle={item.subTitle}
        title={item.title}
        forceDisplayCheckbox={forceDisplayCheckbox}
      />
    </Box>
  );
}

const cursorPointer: SxProps = {
  cursor: "pointer"
};
