import React, { useCallback, useEffect, useMemo, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Divider, Switch, TextField, Typography } from "@mui/material";
import { Department } from "../../../handlers/departmentHandler";
import { SearchOutlined } from "@mui/icons-material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { useTranslation } from "react-i18next";
import {
  countCheckedDescendants,
  expandOrgUnitIds,
  orgUnitsToParentIdMap,
  searchOrgUnits
} from "../../../handlers/orgUnitHandler";
import TextBody2 from "../../../../components/TextBody2/TextBody2";
import { OrgUnitTreeNode } from "./OrgUnitTreeNode";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { OrgUnitList } from "./OrgUnitList";

export default function OrgUnitTree({
  allDepartments,
  parentToChildDepartments,
  availableDepartmentIds,
  checkedDepartmentIds,
  onCheckedChanged
}: {
  readonly allDepartments: Map<string, Department>;
  readonly parentToChildDepartments: Map<string, Department[]>;
  readonly availableDepartmentIds: Set<string>;
  readonly checkedDepartmentIds: Set<string>;
  readonly onCheckedChanged: (input: Set<string>) => void;
}) {
  const { t } = useTranslation("");

  const topLevelDepartments = useMemo<Department[]>(() => {
    const rootDepartments = parentToChildDepartments.get("");
    if (rootDepartments) {
      return rootDepartments;
    } else {
      return [...allDepartments.values()].filter(({ parentId }) => !allDepartments.get(parentId || ""));
    }
  }, [parentToChildDepartments, allDepartments]);

  const [searchText, setSearchText] = useState("");
  const onSearchTextChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);
  const [cascadeSelect, setCascadeSelect] = useState(true);
  const [filteredDepartments, setFilteredDepartments] = useState<Department[]>([]);
  const [filteredDepartmentIds, setFilteredDepartmentIds] = useState<Set<string>>(new Set());
  const [defaultExpandedIds, setDefaultExpandedIds] = useState<string[]>([]);
  const [forceReRenderKey, setForceReRenderKey] = useState(0);
  const filteredParentToChildDepartments = useMemo(
    () => orgUnitsToParentIdMap(filteredDepartments),
    [filteredDepartments]
  );

  useEffect(() => {
    if (!searchText) {
      setFilteredDepartments([...allDepartments.values()]);
      const departmentIds = [...allDepartments.values()].map(({ id }) => id);
      setFilteredDepartmentIds(new Set(departmentIds));
      setDefaultExpandedIds(topLevelDepartments.map(({ id }) => id));
      setForceReRenderKey(it => it + 1);
      return;
    }

    const timeoutId = setTimeout(() => {
      const {
        foundDepartmentIds,
        fromTopToFoundDepartmentIds,
        fromTopToFoundToLeafDepartmentIds,
        fromTopToFoundToLeafDepartments
      } = searchOrgUnits(topLevelDepartments, allDepartments, parentToChildDepartments, searchText);
      setFilteredDepartments(fromTopToFoundToLeafDepartments);
      setFilteredDepartmentIds(fromTopToFoundToLeafDepartmentIds);
      const fromTopToFoundExclusive = [...fromTopToFoundDepartmentIds].filter(
        departmentId => !foundDepartmentIds.has(departmentId)
      );
      setDefaultExpandedIds(fromTopToFoundExclusive);
      setForceReRenderKey(it => it + 1);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [allDepartments, parentToChildDepartments, searchText, topLevelDepartments]);

  const [listView, setListView] = useState(false);
  const enableListView = useCallback(() => {
    setListView(true);
  }, []);
  const disableListView = useCallback(() => {
    setListView(false);
  }, []);

  const onChecked = useCallback(
    (department, checked) => {
      // list view changes never cascade
      const shouldCascade = listView ? false : cascadeSelect;
      const impactedDepartmentIDs = shouldCascade
        ? new Set(
            [...expandOrgUnitIds([department.id], parentToChildDepartments)].filter(departmentId =>
              filteredDepartmentIds.has(departmentId)
            )
          )
        : new Set([department.id]);
      if (checked) {
        onCheckedChanged(new Set([...checkedDepartmentIds, ...impactedDepartmentIDs]));
      } else {
        onCheckedChanged(
          new Set([...checkedDepartmentIds].filter(departmentId => !impactedDepartmentIDs.has(departmentId)))
        );
      }
    },
    [listView, cascadeSelect, parentToChildDepartments, filteredDepartmentIds, onCheckedChanged, checkedDepartmentIds]
  );

  const countedCheckedDescendants = useMemo(() => {
    return countCheckedDescendants({
      checkedOrgUnitIds: checkedDepartmentIds,
      parentIdToOrgUnits: parentToChildDepartments,
      orgUnitsMap: allDepartments
    });
  }, [checkedDepartmentIds, parentToChildDepartments, allDepartments]);

  const onCascadeSelectChanged = useCallback((_: React.SyntheticEvent, checked: boolean) => {
    setCascadeSelect(checked);
  }, []);

  return (
    <Box>
      <Box width="100%">
        <TextField
          value={searchText}
          onChange={onSearchTextChanged}
          size="small"
          sx={textFieldSx}
          InputProps={textFieldInputProps}
          placeholder={t("common:search")}
          fullWidth={true}
        />
      </Box>
      <Box width="100%" my={2}>
        <Box>
          <Divider />
        </Box>
        <Box my={1.5} display="flex" alignItems="center">
          <Switch
            color="primary"
            size="small"
            // on list view cascade is always off
            checked={listView ? false : cascadeSelect}
            onChange={onCascadeSelectChanged}
            disabled={listView}
          />
          <Box ml={2}>
            <TextBody2 text={t("orgunit_picker:cascade_select")} />
          </Box>
        </Box>
        <Box>
          <Divider />
        </Box>
      </Box>
      {listView ? (
        <>
          <Box>
            <Button onClick={disableListView} startIcon={<ChevronLeftIcon />}>
              {t("orgunit_picker:show_tree")}
            </Button>
          </Box>
          <Box mb={3}>
            <OrgUnitList
              allDepartments={allDepartments}
              checkedDepartmentIds={checkedDepartmentIds}
              onChecked={onChecked}
              searchText={searchText}
            />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Button disabled={checkedDepartmentIds.size === 0} onClick={enableListView}>
              {t("orgunit_picker:show_lists", { count: checkedDepartmentIds.size })}
            </Button>
          </Box>
          <Box mb={3}>
            {filteredDepartmentIds?.size ? (
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                key={`treeview-${forceReRenderKey}`}
                defaultExpanded={defaultExpandedIds}
              >
                {topLevelDepartments.map(node => (
                  <OrgUnitTreeNode
                    key={node.id}
                    node={node}
                    parentToChildDepartments={filteredParentToChildDepartments}
                    countedCheckedDescendants={countedCheckedDescendants}
                    availableDepartmentIds={availableDepartmentIds}
                    checkedDepartmentIds={checkedDepartmentIds}
                    onChecked={onChecked}
                    searchText={searchText}
                    cascadeMode={cascadeSelect}
                  />
                ))}
              </TreeView>
            ) : (
              <Box ml={1}>
                <Typography variant="body2" color="text.secondary">
                  {t("overview:no_entries_found")}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

const textFieldSx: SxProps = {};

const textFieldInputProps: Partial<OutlinedInputProps> = {
  startAdornment: (
    <SearchOutlined
      sx={{
        color: "#23252980",
        marginRight: "8px"
      }}
    />
  )
};
