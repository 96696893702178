import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./../assets/i18n/en.json";
import de from "./../assets/i18n/de.json";
import da from "./../assets/i18n/da.json";
import fr from "./../assets/i18n/fr.json";
import cz from "./../assets/i18n/cz.json";
import pl from "./../assets/i18n/pl.json";
import nl from "./../assets/i18n/nl.json";
import fi from "./../assets/i18n/fi.json";
import hu from "./../assets/i18n/hu.json";
import it from "./../assets/i18n/it.json";
import ko from "./../assets/i18n/ko.json";
import no from "./../assets/i18n/no.json";
import pt from "./../assets/i18n/pt.json";
import ro from "./../assets/i18n/ro.json";
import sk from "./../assets/i18n/sk.json";
import sl from "./../assets/i18n/sl.json";
import es from "./../assets/i18n/es.json";
import sv from "./../assets/i18n/sv.json";
import dech from "../assets/i18n/de-CH.json";
import ar from "../assets/i18n/ar.json";
import bg from "../assets/i18n/bg.json";
import zh from "../assets/i18n/zh.json";
import et from "../assets/i18n/et.json";
import el from "../assets/i18n/el.json";
import id from "../assets/i18n/id.json";
import ja from "../assets/i18n/ja.json";
import lv from "../assets/i18n/lv.json";
import lt from "../assets/i18n/lt.json";
import nb from "../assets/i18n/nb.json";
import tr from "../assets/i18n/tr.json";
import uk from "../assets/i18n/uk.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // init with resources
    resources: {
      en,
      de,
      fr,
      cz,
      pl,
      nl,
      fi,
      da,
      hu,
      it,
      ko,
      no,
      pt,
      ro,
      sk,
      sl,
      es,
      sv,
      "de-CH": dech,
      ar,
      bg,
      zh,
      et,
      el,
      id,
      ja,
      lv,
      lt,
      nb,
      tr,
      uk
    },
    fallbackLng: code => {
      if (!code) {
        return ["en"];
      }

      if (!code.includes("-")) {
        return [code, "en"];
      }

      const languageCode = code.split("-")[0];
      return [code, languageCode, "en"];
    },
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

export const getI18n = () => i18n;
